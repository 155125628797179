import css from "./InfoList.module.scss";

const InfoList = ({ data, changeLocation, currentIndex }) => {
  const handlerItem = (index) => {
    changeLocation(index);
  };

  return (
    <ul className={css["location__list"]}>
      {data.map((item, index) => (
        <li
          key={index}
          className={index === currentIndex ? css["active"] : ""}
          onClick={() => handlerItem(index)}
        >
          <p>{item.nameLocation}</p>
        </li>
      ))}
    </ul>
  );
};

export default InfoList;
