import React, { useEffect, useState } from "react";
import css from "./News.module.scss";
import Container from "../../components/Container/Container";
import allDataNews from "./dataNews/dataNews";
import SliderCard from "../../components/SliderCard";
import { getNewsList } from "../../axios/news";
import * as dayjs from "dayjs";

const { dataNews } = allDataNews;

const News = () => {
  const [newsList, SetNewsList] = useState([]);
  const [loading, SetLoading] = useState(true);
  const [total, SetTotal] = useState(0);
  const [currentIndex, setCurrentIndex] = useState(0);

  // useEffect(() => {
  //   console.log("currentIndex", currentIndex);
  //   console.log("total", total);
  //   console.log("currentIndex", currentIndex);
  // }, [currentIndex, newsList]);

  useEffect(() => {
    let active = false;
    window.scrollTo({
      top: 800,
      behavior: "smooth",
    });

    getNewsList(SetLoading, 1, 300).then((response) => {
      // console.log("response", response);
      const data = response["hydra:member"];

      // console.log("data", data);

      const filtredData = data.filter((item) => {
        // console.log(item.categories.length === 0);
        return item.categories.find((element) => element.slug === "social") === undefined;
      });
      // console.log(filtredData);
      const newsData = filtredData.map((item) => {
        return {
          id: item.id,
          title: item.name,
          date: dayjs(item.date).format("DD.MM.YYYY"),
          description: item.newsShot,
          imgURL: item.video_img || item.media?.contentUrl,
          active: item.active,
        };
      });
      if (!active) {
        SetNewsList(newsData);
        // console.log("res", response);
        SetTotal(response["hydra:totalItems"]);
      }
    });

    return () => {
      active = true;
    };
  }, [currentIndex]);

  return (
    <div className={css["NewsWrapper"]}>
      <Container>
        <SliderCard
          allArrCard={newsList}
          total={total}
          setCurrentIndex={setCurrentIndex}
          currentIndex={currentIndex}
          type={"news"}
        />
      </Container>
    </div>
  );
};

export default News;
