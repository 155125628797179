import css from "./cardEmployee.module.scss";
import v from "../../media/images/employees/V.png";
import border from "../../media/images/employees/border.png";
import { useTranslation } from 'react-multi-lang'


const CardEmployee = ({ employees }) => {
  // console.log(employees.imgURL);
  const t = useTranslation();

  return (
    <div className={css["cardWrapper"]}>
      <img className={css["decorV"]} src={v} alt="v" />

      <div className={css["photoWrapper"]}>
        <img src={border} alt="border" />

        <img className={css["photo"]} src={employees.imgURL} alt="photo" />
      </div>

      <h3>
        {t(employees.lastName)} <span> {t(employees.firstName)} </span>
      </h3>

      <p> {t(employees.description)}</p>
    </div>
  );
};

export default CardEmployee;
