import React, { Fragment } from 'react'
import CropScheme from '../../components/Crop/CropScheme';
import Title from '../../components/Title/Title';
import "./cropcare.scss";
import { useTranslation } from "react-multi-lang";

const CropCare = () => {

  const translate = useTranslation();

  const schemeData = [
    [{name: translate('cropcare.schemeLabels.betanalexp'), measur: {count: '0.8', type: translate('cropcare.schemeMeasur.lga')}, type: 'bottle', hint: 'text/description for hint'},
     {name: translate('cropcare.schemeLabels.goltics'), measur: {count: '1.5', type: translate('cropcare.schemeMeasur.lga')}, type: 'bottle', hint: 'text/description for hint'},],

    [{name: translate('cropcare.schemeLabels.betanalexp'), measur: {count: '0.8', type: translate('cropcare.schemeMeasur.lga')}, type: 'bottle', hint: 'text/description for hint'},
     {name: translate('cropcare.schemeLabels.goltics'), measur: {count: '1.0', type: translate('cropcare.schemeMeasur.lga')}, type: 'bottle', hint: 'text/description for hint'},
     {name: translate('cropcare.schemeLabels.piramin'), measur: {count: '1.0', type: translate('cropcare.schemeMeasur.lga')}, type: 'bottle', hint: 'text/description for hint'},
    ],
    [{name: translate('cropcare.schemeLabels.betanalpro'), measur: {count: '1.5', type: translate('cropcare.schemeMeasur.lga')}, type: 'bottle', hint: 'text/description for hint'}],
    [{name: translate('cropcare.schemeLabels.betanalpro'), measur: {count: '1.5', type: translate('cropcare.schemeMeasur.lga')}, type: 'bottle', hint: 'text/description for hint'},
     {name: translate('cropcare.schemeLabels.lontrel'), measur: {count: '0.3', type: translate('cropcare.schemeMeasur.lga')}, type: 'bottle', hint: 'text/description for hint'},
     {name: translate('cropcare.schemeLabels.karibu'), measur: {count: '0.020', type: translate('cropcare.schemeMeasur.kgha')}, type: 'glue', hint: 'text/description for hint'},
    ],
  ]

  function residuePos(str) {
    const array = str.split(" ");
    return array.slice(1, array.length).join(" ");
  }

  return (
    <section className={"crop-care"}>
        <div className="container">
            <Title num1={'01'} num2={'01'} row1={translate('cropcare.title1').split(' ')[0]} row2={residuePos(translate('cropcare.title1'))} />
            <div className={"crop-care__descr"} dangerouslySetInnerHTML={{__html: translate('cropcare.firstTxt')}}>
            </div>
            <div className={"crop-care__scheme"} id='table-block1'>
                <h3>{translate('cropcare.scheme')}</h3>
                <a href="#table-block1" className={"table-link"}>{translate('table')} №1
                    <svg xmlns="http://www.w3.org/2000/svg" width="26" height="8" viewBox="0 0 26 8" fill="none">
                    <path d="M0.646447 4.35355C0.451184 4.15829 0.451184 3.84171 0.646447 3.64645L3.82843 0.464466C4.02369 0.269204 4.34027 0.269204 4.53553 0.464466C4.7308 0.659728 4.7308 0.976311 4.53553 1.17157L1.70711 4L4.53553 6.82843C4.7308 7.02369 4.7308 7.34027 4.53553 7.53553C4.34027 7.7308 4.02369 7.7308 3.82843 7.53553L0.646447 4.35355ZM25 3.5C25.2761 3.5 25.5 3.72386 25.5 4C25.5 4.27614 25.2761 4.5 25 4.5V3.5ZM1 3.5L25 3.5V4.5L1 4.5L1 3.5Z" fill="#C2C7CC"/>
                    </svg>
                </a>
                {schemeData.map((data, index)=>{
                    return <div key={index} className={"scheme-block"}>
                            {data.map((item, index) => {
                                return <Fragment key={index} > 
                                            <CropScheme name={item.name} measur={item.measur} type={item.type} hint={item.hint} />
                                            {(index !== data.length - 1) &&
                                            <svg width="66" height="66" viewBox="0 0 66 66" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M33 1L33 65" stroke="#676D73" stroke-width="2" stroke-linecap="round"/>
                                            <path d="M65 33L1 33" stroke="#676D73" stroke-width="2" stroke-linecap="round"/>
                                            </svg>}
                                      </Fragment>
                            })}
                        </div>
                })}
            </div>
            <div className={"crop-care__descr"} dangerouslySetInnerHTML={{__html: translate('cropcare.lastTxt')}}></div>
        </div>
    </section>
  )
}

export default CropCare