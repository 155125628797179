import Container from "../../components/Container/Container";
import Title from "../../components/Title/Title";
import css from "./Sowing.module.scss";
import { Table2 } from "./Table2/Table2";
import { Table3 } from "./Table3/Table3";
import { Table4 } from "./Table4/Table4";
import {  useTranslation } from "react-multi-lang";
// import uk from 'uk.json'
// import en from 'en.json'

export const Sowing = () => {
  // setTranslations({uk, en})
// setDefaultLanguage('uk')
  const t = useTranslation();


  return (
    <>
      <Container>
        <section className={css['title']}>
          <p className={css['title__text-1']}>
            {t('sowing.text1')}
          </p>
          <div className={css['title__title']}>
          {t('sowing.text2')}
          </div>
          <ul className={css['title__list list']}>
            <li className={css['list__item']}>
            <div dangerouslySetInnerHTML={{ __html: t('sowing.text3')}} />
            </li>
            <li className={css['list__item']}>
            <div dangerouslySetInnerHTML={{ __html: t('sowing.text4')}} />
            </li>
            <li className={css['list__item']}>
            <div dangerouslySetInnerHTML={{ __html: t('sowing.text5')}} />
            </li>
          </ul>
        </section>
        <section className={css['deadlines']}>
          <Title num1={'01'} num2={'05'}  row1={<div style={{display: "inline-block"}} dangerouslySetInnerHTML={{ __html: t('sowing.text6')}} />} row2={<div dangerouslySetInnerHTML={{ __html: t('sowing.text7')}} />} className={css['deadlines__title']} />
          <p className={css['subtitle']}>
          <div dangerouslySetInnerHTML={{ __html: t('sowing.text8')}} />
          </p>
          {/* <div className={css['table']}>
            <div className={css['table__title']}>
              Таблиця №1
            </div>

            <div className={css['table__wrapper']}>

              <div className={css['table__col']}>
                <div className={css['table__col--title']}>
                  Проростання насіння
                </div>
                <div className={css['table__col--subtitle']}>
                  Температура <span style={{ color: '#845713', fontWeight: '700' }}>ґрунту</span>
                </div>

                <div className={css['table__row--wrapper1']}>
                  <div className={css['table__row']}>
                    <div className={css['desc__temp']}>
                      <div className={css['desc__temp--numb']}>
                        + 4...5°C
                      </div>
                      <div className={css['desc__temp--text']}>
                        Починається
                        <br />
                        <span style={{ color: '#62C960', fontWeight: '700' }}> проростання</span>
                      </div>
                    </div>
                    <div className={css['desc__deadline']}>
                      <div className={css['desc__deadline--text']}>
                        Сходи отримують через <span style={{ fontWeight: '700' }}>20...25 діб </span>
                      </div>
                      <div className={css['desc__deadline--pag']}>
                        <div className={css['pag__full']}></div>
                        <div className={css['pag__full']}></div>
                        <div className={css['pag__full']}></div>
                        <div className={css['pag__full']}></div>
                        <div className={css['pag__full']}></div>
                        <div className={css['pag__full']}></div>
                        <div className={css['pag__full']}></div>
                        <div className={css['pag__full']}></div>
                        <div className={css['pag__full']}></div>
                        <div className={css['pag__full']}></div>
                        <div className={css['pag__full']}></div>
                        <div className={css['pag__full']}></div>
                        <div className={css['pag__full']}></div>
                        <div className={css['pag__full']}></div>
                        <div className={css['pag__full']}></div>
                        <div className={css['pag__full']}></div>
                        <div className={css['pag__full']}></div>
                        <div className={css['pag__full']}></div>
                        <div className={css['pag__full']}></div>
                        <div className={css['pag__full']}></div>
                        <div className={css['pag__empty']}></div>
                        <div className={css['pag__empty']}></div>
                        <div className={css['pag__empty']}></div>
                        <div className={css['pag__empty']}></div>
                        <div className={css['pag__empty']}></div>
                      </div>
                    </div>
                  </div>
                  <div className={css['table__row']}>
                    <div className={css['desc__temp']}>
                      <div className={css['desc__temp--numb']}>
                        + 10°C
                      </div>
                    </div>
                    <div className={css['desc__deadline']}>
                      <div className={css['desc__deadline--text']}>
                        Сходи отримують через <span style={{ fontWeight: '700' }}>10...14 діб </span>
                      </div>
                      <div className={css['desc__deadline--pag']}>

                        <div className={css['pag__full']}></div>
                        <div className={css['pag__full']}></div>
                        <div className={css['pag__full']}></div>
                        <div className={css['pag__full']}></div>
                        <div className={css['pag__full']}></div>
                        <div className={css['pag__full']}></div>
                        <div className={css['pag__full']}></div>
                        <div className={css['pag__full']}></div>
                        <div className={css['pag__full']}></div>
                        <div className={css['pag__full']}></div>
                        <div className={css['pag__empty']}></div>
                        <div className={css['pag__empty']}></div>
                        <div className={css['pag__empty']}></div>
                        <div className={css['pag__empty']}></div>
                      </div>
                    </div>
                  </div>
                  <div className={css['table__row']}>
                    <div className={css['desc__temp']}>
                      <div className={css['desc__temp--numb']}>
                        + 15°C
                      </div>
                    </div>
                    <div className={css['desc__deadline']}>
                      <div className={css['desc__deadline--text']}>
                        Сходи отримують через <span style={{ fontWeight: '700' }}>8...12 діб</span>
                      </div>
                      <div className={css['desc__deadline--pag']}>
                        <div className={css['pag__full']}></div>
                        <div className={css['pag__full']}></div>
                        <div className={css['pag__full']}></div>
                        <div className={css['pag__full']}></div>
                        <div className={css['pag__full']}></div>
                        <div className={css['pag__full']}></div>
                        <div className={css['pag__full']}></div>
                        <div className={css['pag__full']}></div>
                        <div className={css['pag__empty']}></div>
                        <div className={css['pag__empty']}></div>
                        <div className={css['pag__empty']}></div>
                        <div className={css['pag__empty']}></div>
                      </div>
                    </div>
                  </div>
                  <div className={css['table__row']}>
                    <div className={css['desc__temp']}>
                      <div className={css['desc__temp--numb']}>
                        + 20...25°C
                      </div>
                    </div>
                    <div className={css['desc__deadline']}>
                      <div className={css['desc__deadline--text']}>
                        Сходи отримують через <span style={{ fontWeight: '700' }}>5...7 діб</span>
                      </div>
                      <div className={css['desc__deadline--pag']}>
                        <div className={css['pag__full']}></div>
                        <div className={css['pag__full']}></div>
                        <div className={css['pag__full']}></div>
                        <div className={css['pag__full']}></div>
                        <div className={css['pag__full']}></div>
                        <div className={css['pag__empty']}></div>
                        <div className={css['pag__empty']}></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className={css['table__col']}>
                <div className={css['table__col--title']}>
                  Ріст рослин
                </div>
                <div className={css['table__col--subtitle']}>
                  Температура <span style={{ color: '#5CA6E7', fontWeight: '700' }}>повітря</span>
                </div>


                <div className={css['table__row--wrapper2']}>
                  <div className={css['table__row--flex']}>
                    <div className={css['table__row--col']}>
                      <div className={css['desc__temp']}>
                        <div className={css['desc__temp-2--numb']}>
                          − 1...2°C
                        </div>
                      </div>
                      <div className={css['desc__deadline-2']}>
                        <div className={css['desc__deadline-2--text']}>
                          <span style={{ fontWeight: '700' }}>У фазі вилочки</span>  — рослини <span style={{ color: '#62C960', fontWeight: '700' }}>витримують</span> нічні зниження температур
                        </div>
                      </div>
                    </div>
                    <div className={css['table__row--col']}>
                      <div className={css['desc__temp']}>
                        <div className={css['desc__temp-2--numb']}>
                          − 3°C
                        </div>
                      </div>
                      <div className={css['desc__deadline-2']}>
                        <div className={css['desc__deadline-2--text']}>
                          <span style={{ fontWeight: '700' }}>У фазі вилочки</span>  — рослини <span style={{ color: '#ED1B28', fontWeight: '700' }}>гинуть</span>  при ткіх тепмпературах
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={css['table__row--flex']}>
                    <div className={css['table__row--col']}>
                      <div className={css['desc__temp']}>
                        <div className={css['desc__temp-2--numb']}>
                          − 4...5°C
                        </div>
                      </div>
                      <div className={css['desc__deadline-2']}>
                        <div className={css['desc__deadline-2--text']}>
                          <span style={{ fontWeight: '700' }}>Першої  пари листків</span>  — рослини <span style={{ color: '#62C960', fontWeight: '700' }}>витримують</span> нічні заморозки
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={css['table__row--flex']}>
                    <div className={css['table__row--col']}>
                      <div className={css['desc__temp']}>
                        <div className={css['desc__temp-2--numb']}>
                          − 5...6°C
                        </div>
                      </div>
                      <div className={css['desc__deadline-2']}>
                        <div className={css['desc__deadline-2--text']}>
                          <span style={{ fontWeight: '700' }}>У період збирання</span>  — рослини <span style={{ color: '#62C960', fontWeight: '700' }}>не пошкоджуються</span> нічними  приморозками
                        </div>
                      </div>
                    </div>
                    <div className={css['table__row--col']}>
                      <div className={css['desc__temp']}>
                        <div className={css['desc__temp-2--numb']}>
                          − 2...3°C
                        </div>
                      </div>
                      <div className={css['desc__deadline-2']}>
                        <div className={css['desc__deadline-2--text']}>
                          <span style={{ fontWeight: '700' }}>Викопані і невкриті</span> коренеплоди — <span style={{ color: '#ED1B28', fontWeight: '700' }}>пошкоджуються</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
          <div className={css['title']}>
            <p className={css['title__text-1']}>
            <div dangerouslySetInnerHTML={{ __html: t('sowing.text9')}} />
            </p>
            <p className={css['title__text-2']}>
            <div dangerouslySetInnerHTML={{ __html: t('sowing.text10')}} />
            </p>
          </div>
        </section>
        <section className={css['scheme']}>
          <Title num1={'02'} num2={'05'} row1={<div style={{display: "inline-block"}} dangerouslySetInnerHTML={{ __html: t('sowing.text11')}} />} row2={<div dangerouslySetInnerHTML={{ __html: t('sowing.text12')}} />} />
          <div className={css['title']}>
            <p className={css['title__text-1']}>
            <div dangerouslySetInnerHTML={{ __html: t('sowing.text13')}} />
            </p>
            <p className={css['title__text-2']}>
              <div dangerouslySetInnerHTML={{ __html: t('sowing.text14')}} />
            </p>
          </div>
          <p className={css['scheme__subtitle']}>
          <div dangerouslySetInnerHTML={{ __html: t('sowing.text15')}} />
          </p>
          <Table2 />
          <div className={css['title']}>
            <p className={css['title__text-3']} style={{ margin: '0' }}>
            <div dangerouslySetInnerHTML={{ __html: t('sowing.text16')}} />
            </p>
            <p className={css['title__link-t']}>
            <div dangerouslySetInnerHTML={{ __html: t('sowing.text17')}} />
            </p>
            <p className={css['title__text-4']}>
            <div dangerouslySetInnerHTML={{ __html: t('sowing.text18')}} />
            </p>
          </div>
          <p className={css['subtitle']}>
          <div dangerouslySetInnerHTML={{ __html: t('sowing.text19')}} />
          </p>
          <div className={css['lt']}>
          <div dangerouslySetInnerHTML={{ __html: t('sowing.text20')}} />
          </div>

          {/* <Table3 /> */}

          <div className={css['title']}>
            <p className={css['title__text-5']}>
            <div dangerouslySetInnerHTML={{ __html: t('sowing.text21')}} />
            </p>

          </div>
        </section>
        <section>
          <Title num1={'03'} num2={'05'} row1={<div style={{ display: 'inline-block' }} dangerouslySetInnerHTML={{ __html: t('sowing.text22')}} />} row2={<div dangerouslySetInnerHTML={{ __html: t('sowing.text23')}} />} />
          <div className={css['title']}>
            <p className={css['title__text-1']}>
            <div dangerouslySetInnerHTML={{ __html: t('sowing.text24')}} />
            </p>
            <p className={css['title__text-2']}>
            <div dangerouslySetInnerHTML={{ __html: t('sowing.text25')}} />
            </p>
            <p className={css['title__text-3']}>
            <div dangerouslySetInnerHTML={{ __html: t('sowing.text26')}} />
            </p>
            <p className={css['title__text-4']}>
            <div dangerouslySetInnerHTML={{ __html: t('sowing.text27')}} />
            </p>
          </div>
        </section>
        <section>
          <Title num1={'04'} num2={'05'} row1={<div style={{ display: 'inline-block' }} dangerouslySetInnerHTML={{ __html: t('sowing.text28')}} />} row2={<div dangerouslySetInnerHTML={{ __html: t('sowing.text29')}} />} />
          <div className={css['title']}>
            <p className={css['title__text-1']}>
            <div dangerouslySetInnerHTML={{ __html: t('sowing.text30')}} />
            </p>
          </div>
          <div className={css['control']}>
            <div className={css['control__content']}>
              <div className={css['control__content--left']}>
                <p className={css['control__title-1']}>
                <div dangerouslySetInnerHTML={{ __html: t('sowing.text31')}} />
                </p>
                <ul className={css['control__list-1']}>
                  <li className={css['control__item-1']}>
                    <span>01 </span> <div dangerouslySetInnerHTML={{ __html: t('sowing.text32')}} />
                  </li>
                  <li className={css['control__item-1']}>
                    <span>02</span> <div dangerouslySetInnerHTML={{ __html: t('sowing.text33')}} />
                  </li>
                  <li className={css['control__item-1']}>
                    <span>03</span> <div dangerouslySetInnerHTML={{ __html: t('sowing.text34')}} />
                  </li>
                  <li className={css['control__item-1']}>
                    <span>04</span> <div dangerouslySetInnerHTML={{ __html: t('sowing.text35')}} />
                  </li>
                  <li className={css['control__item-1']}>
                    <span>05</span> <div dangerouslySetInnerHTML={{ __html: t('sowing.text36')}} />
                  </li>
                  <li className={css['control__item-1']}>
                    <span>06</span> <div dangerouslySetInnerHTML={{ __html: t('sowing.text37')}} />
                  </li>
                </ul>
              </div>
              <div className={css['control__content--right']}>
                <p className={css['control__title-1']}>
                <div dangerouslySetInnerHTML={{ __html: t('sowing.text38')}} />
                </p>
                <ul className={css['control__list-1']}>
                  <li className={css['control__item-1']}>
                    <span >01 </span> <div dangerouslySetInnerHTML={{ __html: t('sowing.text39')}} />
                  </li>
                  <li className={css['control__item-1']}>
                    <span>02 </span> <div dangerouslySetInnerHTML={{ __html: t('sowing.text40')}} />
                  </li>
                  <li className={css['control__item-1']}>
                    <span>03 </span> <div dangerouslySetInnerHTML={{ __html: t('sowing.text41')}} />
                  </li>
                  <li className={css['control__item-1']}>
                    <span>04 </span> <div dangerouslySetInnerHTML={{ __html: t('sowing.text42')}} />
                  </li>
                  <li className={css['control__item-1']}>
                    <span>05 </span> <div dangerouslySetInnerHTML={{ __html: t('sowing.text43')}} />
                  </li>
                  <li className={css['control__item-1']}>
                    <span>06 </span> <div dangerouslySetInnerHTML={{ __html: t('sowing.text44')}} />
                  </li>
                  <li className={css['control__item-1']}>
                    <span>07 </span> <div dangerouslySetInnerHTML={{ __html: t('sowing.text45')}} />
                  </li>
                  <li className={css['control__item-1']}>
                    <span>08 </span> <div dangerouslySetInnerHTML={{ __html: t('sowing.text46')}} />
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className={css['title']}>
            <p className={css['title__text-2']}>
            <div dangerouslySetInnerHTML={{ __html: t('sowing.text47')}} />
            </p>
            <p className={css['title__text-3']}>
            <div dangerouslySetInnerHTML={{ __html: t('sowing.text48')}} />
            </p>
            <p className={css['title__text-4']}>
            <div dangerouslySetInnerHTML={{ __html: t('sowing.text49')}} />
            </p>
          </div>
          <p className={css['subtitle']}>
          <div dangerouslySetInnerHTML={{ __html: t('sowing.text50')}} />
          </p>
          <div className={css['lt']}>
          <div dangerouslySetInnerHTML={{ __html: t('sowing.text51')}} />
          </div>
          {/* <Table4 /> */}

          <div className={css['title']}>
            <p className={css['title__text-5']}>
            <div dangerouslySetInnerHTML={{ __html: t('sowing.text52')}} />
            </p>
          </div>
        </section>
        <section>
          <Title num1={'05'} num2={'05'} row1={<div style={{ display: 'inline-block' }} dangerouslySetInnerHTML={{ __html: t('sowing.text53')}} />} row2={<div dangerouslySetInnerHTML={{ __html: t('sowing.text54')}} />} />
          <div className={css['title']}>
            <p className={css['title__text-1']}>
            <div dangerouslySetInnerHTML={{ __html: t('sowing.text55')}} />
            </p>
            <p className={css['title__text-2']}>
              <span style={{ fontWeight: '700' }}>
              <div dangerouslySetInnerHTML={{ __html: t('sowing.text56')}} />
              </span>
              <br />
              <div dangerouslySetInnerHTML={{ __html: t('sowing.text57')}} />
            </p>
            <p className={css['title__text-3']}>
            <div dangerouslySetInnerHTML={{ __html: t('sowing.text58')}} />
            </p>
            <p className={css['title__text-4']}>
            <div dangerouslySetInnerHTML={{ __html: t('sowing.text59')}} />
              <br />
              <div dangerouslySetInnerHTML={{ __html: t('sowing.text60')}} />
            </p>
          </div>
        </section>
      </Container >
    </>
  )
}