import React from 'react'
import { Fragment } from 'react';
import { Link } from 'react-router-dom'
import { EnergyTitle } from '../../energy/EnergyTitle';
import { findInfo } from '../../Store/findInfo';
import css from "./energy.module.scss";

const EnergySupplyBlock = ({data, index}) => {

  const filtred = data.filter(elements =>{
    return elements.key !== 'title1';
  })

  return (
    <div className={css["energy-supply-block"]}>
    <EnergyTitle index={index + 1} text={findInfo(data, 'title1')} />
        <div className={css["energy-button-inner"]}>
            {filtred.map((link, ind) => {
                if(findInfo(filtred, `btnlink${ind}`)) {
                    return  <Link key={ind} to={findInfo(filtred, `btnlink${ind}`)} target="_blank" className={css["energy-button"]}>
                                {findInfo(filtred, `btnTitle${ind}`)}
                                <img src="/media/images/energy/export.svg" alt="export" />
                            </Link>
                }
                else 
                return <Fragment key={ind}></Fragment>;
            })}
        </div>
    </div>  
  )
}

export default EnergySupplyBlock