import React from "react";
import { Home } from "./views/home/Home";
import Logistics from "./views/Logistics/Logistics";
import { Seed } from "./views/seed/Seed";
import { Study } from "./views/study/Study";
import { Vacancies } from "./views/vacancies/Vacancies";
import { Power } from "./views/power/Power";
import News from "./views/news/News";
import NewsPage from "./views/news/NewsPage";
import SocialActivity from "./views/socialActivity/SocialActivity";
// import Contacts from './views/contacts/Contacts';
import { Sowing } from "./views/sowing/Sowing";
import BestEmployees from "./views/bestEmployees/BestEmployees";
import Shipment from "./views/shipment/Shipment";
import LectureSchedule from "./views/lectureSchedule/LectureSchedule";
import LecturesLaboratory from "./views/lecturesLaboratory/LecturesLaboratory";
import Energyprod from "./views/energy/Energyprod";
import Gasprod from "./views/energy/Gasprod";
import CropCare from "./views/crop/CropCare";
import Soil from "./views/soil/Soil";

import History from "./views/history/History";
// import { Question } from './views/Question/Question';
import LectureDetails from "./views/LectureDetails";
import { TechnologyBeet } from "./views/technologyBeet/TechnologyBeet";
import Pages from "./views/pages/Pages";
import { Energy } from "./views/energy/Energy";
import { EnergySupply } from "./views/energy/EnergySupply";
import StudyEvent from "./views/study/StudyEvent";
import { Report } from "./views/reports/Report";

// import Product from './components/CardProduct/Product';

/** ASD ROUTERS */
// const NewsForm = React.lazy(() => import('./views/news/NewsForm'))

const routes = [
  { path: "/", exact: true, name: "routes.main", element: <Home /> },
  { path: "/seed", exact: true, name: "routes.seed", element: <Seed /> },
  { path: "/study", exact: true, name: "routes.study", element: <Study /> },
  { path: "/study/:id", exact: true, name: "Ремонтний період", element: <StudyEvent /> },
  {
    path: "/vacancies",
    exact: true,
    name: "routes.vacancies",
    element: <Vacancies />,
  },
  {
    path: "/logistics",
    exact: true,
    name: "routes.logistics",
    element: <Logistics />,
  },
  { path: "/power", exact: true, name: "routes.power", element: <Power /> },
  { path: "/sowing", exact: true, name: "routes.sowing", element: <Sowing /> },
  {
    path: "/question",
    exact: true,
    name: "routes.question",
    element: <Home />,
  },

  { path: "/news", exact: true, name: "routes.news", element: <News /> },
  {
    path: "/news/:id",
    exact: true,
    name: "routes.news",
    element: <NewsPage />,
  },
  {
    path: "/social-activity",
    exact: true,
    name: "routes.socialActivity",
    element: <SocialActivity />,
  },
  {
    path: "/social-activity/:id",
    exact: true,
    name: "routes.socialActivity",
    element: <NewsPage />,
  },

  {
    path: "/best-employees",
    exact: true,
    name: "routes.bestemployees",
    element: <BestEmployees />,
  },
  {
    path: "/shipment",
    exact: true,
    name: "routes.shipment",
    element: <Shipment />,
  },
  {
    path: "/lecture-schedule",
    exact: true,
    name: "routes.lectureSchedule",
    element: <LectureSchedule />,
  },
  {
    path: "/lecture-schedule/:id",
    exact: true,
    name: "routes.lectureScheduleId",
    element: <LectureDetails />,
  },

  { path: "/clients", exact: false, name: "routes.clients", element: <Home /> },
  { path: "/workers", exact: false, name: "routes.workers", element: <Home /> },
  { path: "/fermers", exact: false, name: "routes.fermers", element: <Home /> },
  { path: "/drivers", exact: false, name: "routes.drivers", element: <Home /> },
  {
    path: "/partners",
    exact: false,
    name: "routes.partners",
    element: <Home />,
  },

  { path: "/clients/sugar", exact: false, name: "routes.sugar", element: "" },
  { path: "/clients/sugar/:id", exact: true, name: "", element: "" },

  {
    path: "/clients/melyasa",
    exact: true,
    name: "routes.melyasa",
    element: "",
  },
  { path: "/clients/zhom", exact: true, name: "routes.zhom", element: "" },

  {
    path: "/lectures-laboratory",
    exact: true,
    name: "routes.lecturesLaboratory",
    element: <LecturesLaboratory />,
  },
  //   { path: '/dashboard', name: 'Dashboard', element: <Dashboard /> },
  {
    path: "/crop-care",
    exact: true,
    name: "routes.cropCare",
    element: <CropCare />,
  },
  { path: "/soil", exact: true, name: "routes.soil", element: <Soil /> },
  {
    path: "/history",
    exact: true,
    name: "routes.history",
    element: <History />,
  },
  { path: "/contacts", exact: true, name: "routes.contacts", element: "" },

  {
    path: "/technology-beet",
    exact: true,
    name: "routes.technologyBeet",
    element: <TechnologyBeet />,
  },
  { path: "/pages", exact: true, name: "routes.pages", element: <Home /> },
  { path: "/pages/:id", exact: true, name: "", element: <Pages /> },

  { path: "/energy", exact: true, name: "routes.energy", element: <Energy /> },
  {
    path: "/energy/supply",
    exact: true,
    name: "routes.supply",
    element: <EnergySupply />,
  },

  {
    path: "/energy/production",
    exact: true,
    name: "routes.production",
    element: <Energyprod />,
  },
  {
    path: "/energy/gas",
    exact: true,
    name: "routes.gas",
    element: <Gasprod />,
  },
  {
    path: "/reports",
    exact: true,
    name: "routes.report",
    element: <Report />,
  },
];

export default routes;
