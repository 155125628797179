import React from "react";
import css from "./CardLecture.module.scss";
import { BsArrowRight } from "react-icons/bs";
import { NavLink } from "react-router-dom";

const CardLecture = ({ obj }) => {


  return (
    <div className={css["wrapperCard"]}>
      <h2>{obj.title}</h2>
      <h3>{obj.titleDirections}</h3>

      <ul>
        {obj.directions.map((el, innerIndex) => (
          <NavLink to={`/lecture-schedule/${el.id}`}>
            <li key={innerIndex}>
              <p>{el.name}</p>
              <BsArrowRight className={css["icon"]} />
            </li>
          </NavLink>
        ))}
      </ul>
    </div>
  );
};

export default CardLecture;
