import {  useTranslation } from "react-multi-lang";

const dataBestEmployees = [
  {
    // lastName: "Ткачук",
    lastName: "dataBestEmployees.employee1.lastName",
    // firstName: "Василина Михайлівна",
    firstName: "dataBestEmployees.employee1.firstName",
    imgURL: "/media/images/employees/vasuta.png",
    // description: "Оператор лінії у виробництві харчової продукції",
    description: "dataBestEmployees.employee1.description",
  },
  {
    // lastName: "Васюта",
    lastName: "dataBestEmployees.employee2.lastName",
    // firstName: "Зеновія Володимирівна",
    firstName: "dataBestEmployees.employee2.firstName",
    imgURL: "/media/images/employees/tkachuk.png",
    // description: "Оператор лінії у виробництві харчової продукції",
    description: "dataBestEmployees.employee2.description",
  },
];

const allBestEmployees = {
  dataBestEmployees,
};

export default allBestEmployees;
