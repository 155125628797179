import React from "react";
import css from "./ContentLogistics.module.scss";
// import { ReactComponent as Arrow } from "../../../media/images/icons/arrowLeftLong.svg";
import { ReactComponent as DecorBox } from "../../../media/images/icons/cubePagination.svg";
// import TitleEl from "../../../components/TitleEl/TitleEl";
import { useTranslation } from 'react-multi-lang'
import Title from "../../../components/Title/Title";


const ContentLogistics = ({ dataLogistics }) => {
  const t = useTranslation();

  // console.log(dataLogistics.arrData[0].definitionList[0][0]);
  // console.log(t(dataLogistics.arrData[0].definitionList[0][0]));
  // console.log(t(dataLogistics.arrData[0].definitionList[0][0]));
  // console.log(dataLogistics.arrData[0].definitionList[1]);

  function residuePos(str) {
    const array = str.split(" ");
    return array.slice(1, array.length).join(" ");
  }
 console.log(dataLogistics);
  return (
    <div className={css["ContentLogisticsWrapper"]}>

       {/* <TitleEl 
       data1='1' 
       data2='2' 
       data3={t(dataLogistics.title)} 
       boolean='true'/> */}

      <Title 
        num1={'01'} 
        num2={'02'} 
        row1={t(dataLogistics.title).split(' ')[0]} 
        row2={residuePos(t(dataLogistics.title))} />

      <img src={dataLogistics.imageURL} alt="decorIcon" />

      <div className={css["containerList"]}>
        <p className={css["description"]}>{t(dataLogistics.description)}</p>

        <ul className={css["wrapperList"]}>
          {dataLogistics.arrData.map((item, index) => (
            <li key={index}>
              <div className={css["itemWrapper"]}>
                <div className={css["itemIcon"]}>
                  <DecorBox />
                </div>

                <p>{t(item.text)}</p>
              </div>

              {item.definitionList ? (
                <ul>
                  {item.definitionList.map((item, index) => (
                    <li key={index}>
                      
                      <p>
                        <strong> {t(item[0])} </strong>
                        {t(item[1] ?? "")}
                      </p>

    
                   
                    </li>
                  ))}
                </ul>
              ) : (
                ""
              )}
            </li>
          ))}
        </ul>

        <p className={css["descriptionFinish"]}>{t(dataLogistics.descriptionFinish)}</p>
        
      </div>
    </div>
  );
};

export default ContentLogistics;
