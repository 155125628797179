import React from "react";
import css from "./entitle.module.scss";

export const EnergyTitle = ({index, text}) => {

    return (
        <div className={css["energy-title"]}>
            <img src="/media/images/energy/title-arrow.svg" alt="arrow" />
            <h3>
                <span>{index < 10 ? `0${index}` : index}</span> {text}
            </h3>
        </div>
    );
}