import React, { useState } from "react";
import css from "./Contacts.module.scss";
import Container from "../../components/Container/Container";
import allDataContacns from "./dataContacts/dataContacts";
import GoogleMaps from "../../components/GoogleMaps/GoogleMaps";
import ContactsList from "./ContactsList/ContactsList";

const { dataContacts } = allDataContacns;

const Contacts = () => {
  const [locationGoogle, setLocationGoogle] = useState("");

  const changeLocation = (data) => {
    setLocationGoogle(data);
  };

  return (
    <>
        <div className={css["contactsWrapper"]}>
          <ContactsList list={dataContacts} changeLocation={changeLocation} />
          <GoogleMaps path={locationGoogle} />



        </div>
    </>
  );
};

export default Contacts;

