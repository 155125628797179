// dataLogistics json

const dataLogistics = [
  {
    // title: "Автомобільні перевезення",
    title: "dataLogistics.logistic1.title",
    // description:
    //   "ТзОВ «Радехівський цукор» надає послуги з доставки власної продукції вантажним автомобільним транспортом по Україні",

    description: "dataLogistics.logistic1.description",

    arrData: [
      {
        // text: "Організація автомобільних перевезень всіх видів продукції:",
        text: "dataLogistics.logistic1.arrData.item1.text",
        // definitionList: [["Цукор, жом, меляса"]],
        definitionList: [
          ["dataLogistics.logistic1.arrData.item1.definitionList.el1"],
        ],
      },
      {
        // text: "Розробка оптимального графіку, маршруту та схеми доставки",
        text: "dataLogistics.logistic1.arrData.item2.text",
        definitionList: [],
      },
      {
        // text: "Оформлення супровідних документів",
        text: "dataLogistics.logistic1.arrData.item3.text",
        definitionList: [],
      },
      {
        // text: "Контроль процесу транспортування на всіх стадіях перевезення",
        text: "dataLogistics.logistic1.arrData.item4.text",
        definitionList: [],
      },
      {
        // text: "Можливість доставки продукції будь-яким видом автотранспорту:",
        text: "dataLogistics.logistic1.arrData.item5.text",
        // definitionList: [
        //   ["Автоцистернами, тентовими тягачами, самоскидами, контейнерами"],
        // ],
        definitionList: [
          ["dataLogistics.logistic1.arrData.item5.definitionList.el1"],
        ],
      },
      {
        // text: "Ваговий контроль під час завантаження",
        text: "dataLogistics.logistic1.arrData.item6.text",
        definitionList: [],
      },
    ],

    // descriptionFinish:
    //   'Для здійснення якісних автомобільних вантажоперевезень ТзОВ «Радехівський цукор» залучає до співпраці лише перевірені та надійні автотранспортні підприємства, що дозволяє забезпечити доставку нашої продукції у потрібних об’ємах та у максимально короткі терміни у будь-яку точку України у відповідності до потреб компанії-замовника',
    descriptionFinish: "dataLogistics.logistic1.descriptionFinish",

    imageURL: "/media/images/imageTIR.svg",
  },




  {
    // title: "Залізничні перевезення",
    title: "dataLogistics.logistic2.title",

    // description:
    //   "ТзОВ «Радехівський цукор» володіє власною залізничною інфраструктурою",
    description: "dataLogistics.logistic1.description",

    arrData: [
      {
        // text: "Власними під’їзними залізничними коліями, які з’єднані зі загальною мережею Укрзалізниці по станціях:",
        text: "dataLogistics.logistic2.arrData.item1.text",
        definitionList: [
          // ["Радехів", "— Радехівський підрозділ"],
          [
            "dataLogistics.logistic2.arrData.item1.definitionList.el1.word1",
            "dataLogistics.logistic2.arrData.item1.definitionList.el1.word2",
          ],
          // // ["Шманьківчики", "— Чортківський підрозділ"],
          [
            "dataLogistics.logistic2.arrData.item1.definitionList.el2.word1",
            "dataLogistics.logistic2.arrData.item1.definitionList.el2.word2",
          ],
          // // ["Хоростків", "— Хоростківський підрозділ"],
          [
            "dataLogistics.logistic2.arrData.item1.definitionList.el3.word1",
            "dataLogistics.logistic2.arrData.item1.definitionList.el3.word2",
          ],
          // // ["Козова", "— Козівський підрозділ"],
          [
            "dataLogistics.logistic2.arrData.item1.definitionList.el4.word1",
            "dataLogistics.logistic2.arrData.item1.definitionList.el4.word2",
          ],
          // // ["Збараж", "— Збаразький підрозділ"],
          [
            "dataLogistics.logistic2.arrData.item1.definitionList.el5.word1",
            "dataLogistics.logistic2.arrData.item1.definitionList.el5.word2",
          ],
        ],
      },
      {
        // text: "Тяговим рухомим складом — тепловозами з наявністю усіх необхідних дозволів для виїзду на станцію примикання, виконання маневрових робіт, подачі та забирання вагонів",
        text: "dataLogistics.logistic2.arrData.item2.text",
        definitionList: [],
      },
      {
        // text: "Пунктами зважування залізничних вагонів",
        text: "dataLogistics.logistic2.arrData.item3.text",
        definitionList: [],
      },
      {
        // text: "Пунктами завантаження в залежності від видів продукції у криті вагони, піввагони, цистерни, зерновози та контейнери",
        text: "dataLogistics.logistic2.arrData.item4.text",
        definitionList: [],
      },
    ],

    // descriptionFinish:
    //   "Провідну роль залізничного транспорту у загальній транспортній системі визначає порівняно низька собівартість, масовість, універсальність і надійність доставки вантажу до складу вантажоодержувача.",
    descriptionFinish: "dataLogistics.logistic2.descriptionFinish",

    imageURL: "/media/images/imageTrain.svg",
  },
];

const allDataLocation = {
  dataLogistics,
};

export default allDataLocation;
