import React from "react";
import css from "./energy.module.scss";
import { useTranslation } from "react-multi-lang";
import { Link } from "react-router-dom";

export const Energy = () => {
    const translate = useTranslation();
    return (
        <section className={css["energy"]}>
        <div className="container">
            <div className={css["energy__inner"]}>
                <Link to={'/energy/supply'} target="_blank" rel="noreferrer" >
                    <img src="/media/images/energy/supply.svg" alt="energy" />
                    <span>{translate('energy.supply')}</span>
                </Link>
                <Link to={'/energy/production'} target="_blank" rel="noreferrer" >
                    <img src="/media/images/energy/production.svg" alt="production" />
                    <span>{translate('energy.production')}</span>
                </Link>
                <Link to={'/energy/gas'} target="_blank" rel="noreferrer" >
                    <img src="/media/images/energy/gaz.svg" alt="gaz" />
                    <span>{translate('energy.gaz')}</span>
                </Link>
            </div>
        </div>
        </section>
    );
  }