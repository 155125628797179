import React from "react";
import css from "./TitleEl.module.scss";
import { ReactComponent as Arrow } from "../../media/images/icons/arrowLeftLong.svg";

const TitleEl = ({ data1, data2, data3, boolean }) => {
  return (
    <div className={css["titleContainer"]}>
      <div className={css["Wrapper"]}>
        {boolean && data3.length > 15 ? <div className={css["afterContainer"]}></div> : ""}

        <div className={css["content"]}>

          

        {/* <div className={css["wrap__text"]}> */}

          <p>
            &nbsp;0{data1}
            <span>/</span>
            <sup>0{data2}</sup>
          </p>

          <h2>{data3}</h2>
  
        {/* </div> */}




          <div className={css["decorArrowWrapper"]}>
            <Arrow />
          </div>
        </div>
      </div>
    </div>
  );
};

export default TitleEl;
