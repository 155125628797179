import React from "react";
import css from "./AboutUs.module.scss";
import InfoAboutUs from "./InfoAboutUs/InfoAboutUs";

const AboutUs = ({ infoAboutUs, communication }) => {
  return (
    <section className={css["wrapperContent"]}>
      <h2>About us</h2>
      <InfoAboutUs props={infoAboutUs} />
      <InfoAboutUs props={communication} />
    </section>
  );
};

export default AboutUs;
