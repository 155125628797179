import React from "react";
import css from "./InfoAboutUs.module.scss";
import { ReactComponent as LongArrow } from "../../../../../media/images/icons/longArrow.svg";
import { NavLink } from "react-router-dom";
import { useTranslation } from 'react-multi-lang'


const InfoAboutUs = ({ props }) => {
  const t = useTranslation();

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  return (
    <ul className={css["wrapperList"]}>
      {props.map((item, index) => (
        <li key={index}>
          <NavLink className={css[""]} to={item.path} target={item.target !== undefined ? item.target : '_self'} onClick={scrollToTop}>
            {item.imageURL && <img src={item.imageURL} alt="iconDecor" />}
            &nbsp;{t(item.title)}
          </NavLink>
          <LongArrow />
        </li>
      ))}
    </ul>
  );
};

export default InfoAboutUs;
