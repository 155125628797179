import React from 'react'
import css from "./tabeltemplate.module.scss";

const TableTemplate = ({tableData}) => {
  return (
    <table>
        <tr><th style={{textAlign:'left'}}>{tableData.title}</th>
            <th style={{textAlign:'right'}} className={css['light']} colSpan={3}><span>01</span> {tableData.title1} </th>
            <th style={{textAlign:'right'}} className={css['middle']} colSpan={3}><span>02</span> {tableData.title2} </th>
            <th style={{textAlign:'right'}} className={css['hard']} colSpan={3}><span>03</span> {tableData.title3} </th>
        </tr>
        {tableData.rows.map((row, index) =>{
            if (index === 0 ) {
                return <tr> {row.cols.map((col, index) => {
                    if (index === 0 ){
                        return <td></td>
                    }
                    else {
                        return <td style={{textAlign:'right'}}><span style={{borderBottom: 'none', textAlign:'right', maxWidth: '59px', display: 'inline-block', padding: '0'}}>
                            {col}</span>
                        </td>
                    }
                })}
            </tr>
            }
            else {
                return <tr> {row.cols.map((col,index) => {
                    if (index === 0){
                        return <td><span>{col}</span></td>
                    }
                    if(index > 0 && index <= 3){
                        return <td className={css['light']}>{col}</td>
                    }
                    if (index > 3 && index < 7){
                        return <td className={css['middle']}>{col}</td>
                    }
                    else {
                        return <td className={css['hard']}>{col}</td>
                    }
                })}
             </tr>
            }
        })}
    </table>
  )
}

export default TableTemplate