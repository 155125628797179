import React, {Suspense} from "react";
import {RouterProvider, createBrowserRouter} from "react-router-dom";
import routes from "./routes";
import DefaultLayout from "./layout/DefaultLayout";
import Page404 from "./views/page/Page404";
import { setTranslations, setDefaultLanguage} from 'react-multi-lang'
import ua from './localisation/uk.json'
import en from './localisation/en.json'
import dn from './localisation/de.json'

// Do this two lines only when setting up the application
setTranslations({ua, en, dn})
const currentLang = localStorage.getItem('lang') || 'ua';
setDefaultLanguage(currentLang);

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse">+</div>
  </div>
)

const router = createBrowserRouter([
  {
    path: "/",
    element: <DefaultLayout />,
    errorElement: <Page404 />,
    children: routes,
  },
]);

function App() {

    return (
      <Suspense fallback={loading}>
        <React.StrictMode>
          <RouterProvider router={router} />
        </React.StrictMode>
      </Suspense>
  )
}

export default App;
