import css from "./DetailsHeader.module.scss";
import { useParams } from "react-router";
import {  useTranslation } from "react-multi-lang";

import allLectureSchedule from "../../lectureSchedule/dataLectureSchedule/dataShipment";

const { lectureScheduleWithIds } = allLectureSchedule; // all obj

const DetailsHeader = () => {
  const t = useTranslation();
  const { id } = useParams();

  const idToFind = id;


  const foundObject = lectureScheduleWithIds.reduce((result, item) => {
    const direction = item.directions.find((direction) => direction.id === idToFind);
    if (direction) {
      result = item; // Повертаємо батьківський об'єкт, якщо знайдено direction
    }
    return result;
  }, null);

  // console.log("Знайдений об'єкт id:", foundObject);

  return (
    <div className={css["wrapper"]}>
        <p>2023</p>
        <h2>
          <div dangerouslySetInnerHTML={{_html: t('headTemplate.detailsHeader')}} />
          </h2>

    {foundObject ? <h3>{foundObject.title}</h3> : <h3>{t('headTemplate.detailsHeaderText')}</h3>}

    </div>
  );
};

export default DetailsHeader;
