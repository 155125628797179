import { AxiosInstance } from "./axios";

export const getPageItem = async (isLoading, param)=>{
    isLoading(true);
    const pageItem = await AxiosInstance.get(`/pages/${param}`);
    isLoading(false);
    return pageItem.data;
}

export const getPosts = async (param)=>{
    const postItem = await AxiosInstance.get(`/posts?type=${param}`);
    return postItem.data;
}