import React, { useEffect, useState } from "react";
import Dropdown_1 from "./Dropdown_1";
import { NavLink, useLocation } from "react-router-dom";

// Пункти меню (навігації)
const NavbarItem = (props) => {
  // console.log("props NavbarItem", props);

  // Для відкриття dropdown 1-го рівня
  const [isOpen_1, setIsOpen_1] = useState(false);
  // item:hover - при наведені курсора на пункт меню
  const [itemHover, setItemHover] = useState(false);
  // Взяти url
  const location = useLocation();

  // При зміні url закрити dropdown
  useEffect(() => {
    setItemHover(false);
  }, [location.pathname]);

  // При переході на іншу вкладку навігації - закрити попередню активну вкладку
  useEffect(() => {
    // console.log('props.activeItem', props.activeItem);
    if (itemHover !== false && props.activeItem.title !== props.item.title) {
      props.setActiveItem(false);
    }
  }, [props.activeItem, itemHover]);

  // Відкрити/закрити dropdown 1-го рівня
  useEffect(() => {
    //     console.log('props.activeItem', props.activeItem);
    if (
      props.activeItem !== undefined &&
      props.activeItem.title === props.item.title
    ) {
      setIsOpen_1(true);
    } else {
      setIsOpen_1(false);
    }
  }, [props.activeItem, props.item]);

  return (
    <li
      className={`menu__item ${
        props.activeItem.title === props.item.title ? "active" : ""
      }`}
      onClick={() => {
        props.setActiveItem(props.item);
        // props.item.submenu.length === 0 && props.closeMobile();
      }}
      onMouseEnter={() => setItemHover(props.item)}
      onMouseLeave={() => setItemHover(false)}
    >
      <div className="menu__link-wrapper">
        <NavLink to={props.item.path} className="menu__link">
          {props.item.title}
        </NavLink>
        <div>
          <svg
            width="10"
            height="10"
            viewBox="0 0 10 10"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M8.42141 3.25794L5.21308 5.11628C5.08391 5.19128 4.92141 5.19128 4.78808 5.11628L1.57974 3.25794C1.35058 3.12461 1.29224 2.81211 1.46724 2.61628C1.58808 2.47878 1.72558 2.36628 1.87141 2.28711L4.12974 1.03711C4.61308 0.766276 5.39641 0.766276 5.87974 1.03711L8.13808 2.28711C8.28391 2.36628 8.42141 2.48294 8.54225 2.61628C8.70891 2.81211 8.65058 3.12461 8.42141 3.25794Z"
              fill="#ffffff"
            />
            <path
              d="M4.76302 5.89154V8.7332C4.76302 9.04987 4.44219 9.2582 4.15885 9.1207C3.30052 8.69987 1.85469 7.91237 1.85469 7.91237C1.34635 7.62487 0.929688 6.89987 0.929688 6.30404V4.15404C0.929688 3.82487 1.27552 3.61654 1.55885 3.77904L4.55469 5.51654C4.67969 5.5957 4.76302 5.73737 4.76302 5.89154Z"
              fill="#ffffff"
            />
            <path
              d="M5.23828 5.89154V8.7332C5.23828 9.04987 5.55911 9.2582 5.84245 9.1207C6.70078 8.69987 8.14661 7.91237 8.14661 7.91237C8.65495 7.62487 9.07161 6.89987 9.07161 6.30404V4.15404C9.07161 3.82487 8.72578 3.61654 8.44245 3.77904L5.44661 5.51654C5.32161 5.5957 5.23828 5.73737 5.23828 5.89154Z"
              fill="#ffffff"
            />
          </svg>
        </div>
      </div>

      {/* Випадаючий список */}
      <Dropdown_1
        productArr={props.productArr}
        parentIndex={props.parentIndex}
        translate={props.translate}
        closeMobile={props.closeMobile}
        item={props.activeItem}
        activeItem={props.activeItem}
        isOpen_1={isOpen_1}
        itemHover={itemHover}
        setIsOpen_1={setIsOpen_1}
        productListBul={props.item.path === "/clients"}
      />
    </li>
  );
};

export default NavbarItem;
