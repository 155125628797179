import { useRef, useState } from 'react';
import css from "./technologyBeet.module.scss";
import { Link } from 'react-router-dom';
import { Fragment } from 'react';
import { useTranslation } from 'react-multi-lang';



export const TechnologyBeet = () => {

  const [openId, setId] = useState(0);
  const docs = useRef(null);
  const docsMob = useRef(null);
  const t = useTranslation();

  const tech = [
    {
      number: "01",
      title: t('beetTech.powersys.title'),
      path: '/power',
      image: '/media/images/power__head.png',
      rows: [t('beetTech.powersys.rows.row1'), t('beetTech.powersys.rows.row2')],
      docs: [
          {docTitle: t('beetTech.powersys.docs.docTitle1'), docHref: 'https://diamantsugar.com.ua/ua/technology-beet/download/34'},
          {docTitle: t('beetTech.powersys.docs.docTitle2'), docHref: 'https://diamantsugar.com.ua/ua/technology-beet/download/132'},
          {docTitle: t('beetTech.powersys.docs.docTitle3'), docHref: 'https://diamantsugar.com.ua/ua/technology-beet/download/188'},
          {docTitle: t('beetTech.powersys.docs.docTitle4'), docHref: 'https://diamantsugar.com.ua/ua/technology-beet/download/189'},
          {docTitle: t('beetTech.powersys.docs.docTitle5'), docHref: 'https://diamantsugar.com.ua/ua/technology-beet/download/192'},
        ]
    },
    {
      number: "02",
      title: t('beetTech.prepare.title'),
      path: '/soil',
      image: '/media/images/soil__head.png',
      rows: [t('beetTech.prepare.rows.row1')], 
      docs:[
          {docTitle: t('beetTech.prepare.docs.docTitle1'), docHref: 'https://diamantsugar.com.ua/ua/technology-beet/download/153'},
      ]
    },
    {
      number: "03",
      title: t('beetTech.sow.title'),
      path: '/sowing',
      image: '/media/images/sowing__head.png',
      rows: [t('beetTech.sow.rows.row1')], 
      docs:[
          {docTitle: t('beetTech.sow.docs.docTitle1'), docHref: 'https://diamantsugar.com.ua/ua/technology-beet/download/35'},
          {docTitle: t('beetTech.sow.docs.docTitle2'), docHref: 'https://diamantsugar.com.ua/ua/technology-beet/download/109'},
          {docTitle: t('beetTech.sow.docs.docTitle3'), docHref: 'https://diamantsugar.com.ua/ua/technology-beet/download/110'},
          {docTitle: t('beetTech.sow.docs.docTitle4'), docHref: 'https://diamantsugar.com.ua/ua/technology-beet/download/111'},
          {docTitle: t('beetTech.sow.docs.docTitle5'), docHref: 'https://diamantsugar.com.ua/ua/technology-beet/download/112'},
          {docTitle: t('beetTech.sow.docs.docTitle6'), docHref: 'https://diamantsugar.com.ua/ua/technology-beet/download/149'},
          {docTitle: t('beetTech.sow.docs.docTitle7'), docHref: 'https://diamantsugar.com.ua/ua/technology-beet/download/150'},
          {docTitle: t('beetTech.sow.docs.docTitle8'), docHref: '/media/pdf/Чек_лист_по_сівалці_MONOPIL_перевірка_перед_початком_роботи.pdf'},
      ]
    },
    {
      number: "04",
      title: t('beetTech.care.title'),
      path: '/crop-care',
      image: '/media/images/crop-care__head.png',
      rows: [t('beetTech.care.rows.row1')],
      docs: [
          {docTitle: t('beetTech.care.docs.docTitle8'), docHref: 'https://diamantsugar.com.ua/ua/technology-beet/download/37'},
          {docTitle: t('beetTech.care.docs.docTitle8'), docHref: 'https://diamantsugar.com.ua/ua/technology-beet/download/184'},
          {docTitle: t('beetTech.care.docs.docTitle8'), docHref: 'https://diamantsugar.com.ua/ua/technology-beet/download/191'},
      ]
    }
  ]

  const clickHandler = (id) => {
    if (id === openId) {
      setId(openId)
    } else {
      setId(id)
    }
  }

  function showDocs(e){
    const btn = e.target.closest('button');
    btn.nextElementSibling.classList.toggle('active');
  }

  return (
    <>
      <div className="container">
        <section className={css["tech"]}>
          <div className="acord">
            <ul className="acord__list">
              <div className="acord__wrapper">
                {tech.map((item, id) => (
                  <>
                    <li className={`acord__item ${id === openId ? "active" : ""}`} key={id} id={id} onClick={() => (
                      clickHandler(id)
                    )}>
                      <div className="acord__item--number">
                        {item.number}
                      </div>
                      <div className={`acord__item--text ${id === openId ? "active" : ""}`}>
                        {item.title}
                      </div>
                    </li>
                    <div className={`acord__content acord__content--phone tech  ${id === openId ? "active" : ""}`}>
                    <img src={item.image} alt="acord-img" />
                    <h2>{item.title}</h2>
                    {item.rows.map((row,index) => {
                        return <Fragment key={index}>
                                 <div dangerouslySetInnerHTML={{ __html: row}} />
                                  <br />
                                  <br />
                                </Fragment>
                    })}
                    <Link className='acord-details' to={item.path}>{t('beetTech.detail')}</Link>
                    <button onClick={showDocs}>
                      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M13.7146 4.95931C14.184 5.46826 13.7178 6.16683 13.0255 6.16683H2.33398C1.7817 6.16683 1.33398 5.71911 1.33398 5.16683V4.28016C1.33398 2.6535 2.65398 1.3335 4.28065 1.3335H5.82732C6.91398 1.3335 7.25398 1.68683 7.68732 2.26683L8.62065 3.50683C8.82732 3.78016 8.85398 3.8135 9.24065 3.8135H11.1007C12.1337 3.8135 13.0647 4.25446 13.7146 4.95931Z" fill="#292D32"/>
                      <path d="M14.6574 8.16313C14.6555 7.61217 14.2083 7.16651 13.6574 7.16652L2.33397 7.16672C1.78169 7.16673 1.33398 7.61444 1.33398 8.16672V11.1001C1.33398 13.0667 2.93398 14.6667 4.90065 14.6667H11.1007C13.0673 14.6667 14.6673 13.0667 14.6673 11.1001L14.6574 8.16313ZM9.66732 11.1667H8.54065V12.3334C8.54065 12.6067 8.31398 12.8334 8.04065 12.8334C7.76065 12.8334 7.54065 12.6067 7.54065 12.3334V11.1667H6.33398C6.06065 11.1667 5.83398 10.9401 5.83398 10.6667C5.83398 10.3934 6.06065 10.1667 6.33398 10.1667H7.54065V9.00007C7.54065 8.72674 7.76065 8.50007 8.04065 8.50007C8.31398 8.50007 8.54065 8.72674 8.54065 9.00007V10.1667H9.66732C9.94065 10.1667 10.1673 10.3934 10.1673 10.6667C10.1673 10.9401 9.94065 11.1667 9.66732 11.1667Z" fill="#292D32"/>
                      </svg>{t('beetTech.instructions')}</button>
                      <div className="accord-docs" ref={docsMob}>
                      {!!item.docs.length && item.docs.map((doc,index)=>{
                        return <div key={index} className='acord__content--link' style={{ color: 'blue', textDecoration: 'underline' }}>
                                    <Link to={doc.docHref} target="_blank">
                                    {doc.docTitle}
                                    </Link>
                            </div>
                      })}
                      </div>
                    </div>
                  </>
                ))}
              </div>
              <div className="acord__wrapper">
                {tech.map((item, id) =>
                  <>
                    <div key={id} className={`acord__content acord__content--desktop tech  ${id === openId ? "active" : ""}`}>
                    <img src={item.image} alt="acord-img" />
                    <h2>{item.title}</h2>
                    {item.rows.map((row,index) => {
                        return <Fragment key={index}>
                                 <div dangerouslySetInnerHTML={{ __html: row}} />
                                  <br />
                                  <br />
                                </Fragment>
                    })}
                    <Link className='acord-details' to={item.path}>{t('beetTech.detail')}</Link>
                    <button onClick={showDocs}>
                      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M13.7146 4.95931C14.184 5.46826 13.7178 6.16683 13.0255 6.16683H2.33398C1.7817 6.16683 1.33398 5.71911 1.33398 5.16683V4.28016C1.33398 2.6535 2.65398 1.3335 4.28065 1.3335H5.82732C6.91398 1.3335 7.25398 1.68683 7.68732 2.26683L8.62065 3.50683C8.82732 3.78016 8.85398 3.8135 9.24065 3.8135H11.1007C12.1337 3.8135 13.0647 4.25446 13.7146 4.95931Z" fill="#292D32"/>
                      <path d="M14.6574 8.16313C14.6555 7.61217 14.2083 7.16651 13.6574 7.16652L2.33397 7.16672C1.78169 7.16673 1.33398 7.61444 1.33398 8.16672V11.1001C1.33398 13.0667 2.93398 14.6667 4.90065 14.6667H11.1007C13.0673 14.6667 14.6673 13.0667 14.6673 11.1001L14.6574 8.16313ZM9.66732 11.1667H8.54065V12.3334C8.54065 12.6067 8.31398 12.8334 8.04065 12.8334C7.76065 12.8334 7.54065 12.6067 7.54065 12.3334V11.1667H6.33398C6.06065 11.1667 5.83398 10.9401 5.83398 10.6667C5.83398 10.3934 6.06065 10.1667 6.33398 10.1667H7.54065V9.00007C7.54065 8.72674 7.76065 8.50007 8.04065 8.50007C8.31398 8.50007 8.54065 8.72674 8.54065 9.00007V10.1667H9.66732C9.94065 10.1667 10.1673 10.3934 10.1673 10.6667C10.1673 10.9401 9.94065 11.1667 9.66732 11.1667Z" fill="#292D32"/>
                      </svg> {t('beetTech.instructions')}</button>
                    <div className="accord-docs" ref={docs}>
                      {!!item.docs.length && item.docs.map((doc,index)=>{
                        return <div key={index} className='acord__content--link' style={{ color: 'blue', textDecoration: 'underline' }}>
                                    <Link to={doc.docHref} target="_blank">
                                    {doc.docTitle}
                                    </Link>
                            </div>
                      })}
                      </div>
                    </div>
                  </>
                )}
              </div>
            </ul>
          </div>
        </section >
      </div >
    </>
  );
}