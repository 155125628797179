import { useEffect, useRef } from "react";

// Для закривання елемента при кліку поза елементом
const useOutsideClick = (elementRef, handler, attached = true) => {

    const windowWidth = useRef(window.innerWidth);

    useEffect(() => {
        if (!attached) return;

        const handleClick = (e) => {
            if (!elementRef.current) return;
            // Елемент не знаходиться всередині виводу пошуку
            if (!elementRef.current.contains(e.target) && e.target.closest('.slide-title') === null) {
                // Виклик функції для закривання
                handler();
            }
        }

        if (windowWidth.current > 1050) {
            document.addEventListener("click", handleClick);
        }

        return () => {
            document.removeEventListener("click", handleClick);
        }

    }, [elementRef, handler, attached]);
};

export default useOutsideClick;