import React from 'react'
import css from "./cropscheme.module.scss";

const CropScheme = ({type, name, measur, hint}) => {
  return (
    <div className={css['crop-scheme-item']}>
    {type === 'bottle' ?
    <><svg className={css['bottle']} xmlns="http://www.w3.org/2000/svg" width="162" height="209" viewBox="0 0 162 209" fill="none">
        <path d="M43.2543 22.0239V20.1289H10.1187V51.4797" stroke="#C2C7CC" stroke-miterlimit="10"/>
        <path d="M133.343 16.5337C150.135 24.5845 160.857 41.5932 160.857 60.2171V192.641C160.857 200.887 154.171 207.572 145.926 207.572H15.9353C7.68953 207.572 1.00391 200.887 1.00391 192.641V72.6472C1.00391 64.6431 4.30644 56.9782 10.1272 51.4838C27.3945 35.1746 64.2609 1.7338 76.7545 1.07245C94.4161 0.139766 116.317 8.37279 133.343 16.5337ZM131.715 35.6113C124.949 25.5341 96.4426 13.4347 81.6469 15.1347C78.0561 15.546 70.7642 21.1378 65.3886 25.6528C63.9811 26.8314 63.3112 28.6713 63.6207 30.4816C63.9344 32.2918 65.1808 33.7926 66.902 34.437L118.399 53.7223C120.964 54.6804 123.855 53.7901 125.428 51.5517C128.798 46.7526 133.784 38.7019 131.715 35.6155V35.6113Z" stroke="#C2C7CC" stroke-miterlimit="10"/>
        <path d="M160.853 110.782L133.932 102.074C130.863 101.082 127.721 103.367 127.721 106.593V116.433L160.853 127.15V110.782Z" stroke="#C2C7CC" stroke-miterlimit="10"/>
        <path d="M133.932 102.074C130.863 101.082 127.721 103.367 127.721 106.593V108.531C127.721 105.304 130.867 103.019 133.932 104.011L160.853 112.719V110.782L133.932 102.074Z" stroke="#C2C7CC" stroke-miterlimit="10"/>
        <path d="M43.25 17.5098H10.1187V20.134H43.25V17.5098Z" stroke="#C2C7CC" stroke-miterlimit="10"/>
        <path d="M43.2544 13.7957V8.05122C43.2544 5.93573 41.5374 4.21875 39.4219 4.21875H13.9555C11.84 4.21875 10.123 5.93573 10.123 8.05122V13.7957" stroke="#C2C7CC" stroke-miterlimit="10"/>
        <path d="M160.853 141.327L133.932 132.619C130.863 131.627 127.721 133.912 127.721 137.138V146.978L160.853 157.695V141.327Z" stroke="#C2C7CC" stroke-miterlimit="10"/>
        <path d="M160.853 171.872L133.932 163.164C130.863 162.172 127.721 164.457 127.721 167.683V177.523L160.853 188.24V171.872Z" stroke="#C2C7CC" stroke-miterlimit="10"/>
        <path d="M133.932 132.627C130.863 131.635 127.721 133.92 127.721 137.146V139.083C127.721 135.857 130.867 133.572 133.932 134.564L160.853 143.272V141.334L133.932 132.627Z" stroke="#C2C7CC" stroke-miterlimit="10"/>
        <path d="M133.932 163.181C130.863 162.189 127.721 164.474 127.721 167.701V169.638C127.721 166.412 130.867 164.127 133.932 165.119L160.853 173.827V171.889L133.932 163.181Z" stroke="#C2C7CC" stroke-miterlimit="10"/>
        <path d="M31.0954 73.1816H108.08C113.964 73.1816 118.742 77.9595 118.742 83.8439V180.347C118.742 186.231 113.964 191.009 108.08 191.009H31.0954C25.211 191.009 20.4331 186.231 20.4331 180.347V83.8439C20.4331 77.9595 25.211 73.1816 31.0954 73.1816Z" stroke="#676D73" stroke-miterlimit="10"/>
        <path d="M40.9651 13.7949L40.3971 5.65517C40.3801 5.38385 40.1512 5.17188 39.8798 5.17188H38.8369C38.5656 5.17188 38.3409 5.38385 38.3197 5.65517L37.7516 13.7949H34.6272L34.0591 5.65517C34.0421 5.38385 33.8132 5.17188 33.5418 5.17188H32.4989C32.2276 5.17188 32.0029 5.38385 31.9817 5.65517L31.4136 13.7949H28.2892L27.7211 5.65517C27.7041 5.38385 27.4752 5.17188 27.2039 5.17188H26.1609C25.8896 5.17188 25.6649 5.38385 25.6437 5.65517L25.0756 13.7949H21.9512L21.3831 5.65517C21.3661 5.38385 21.1372 5.17188 20.8659 5.17188H19.823C19.5516 5.17188 19.3269 5.38385 19.3058 5.65517L18.7377 13.7949H15.6132L15.0451 5.65517C15.0281 5.38385 14.7992 5.17188 14.5279 5.17188H13.485C13.2136 5.17188 12.989 5.38385 12.9678 5.65517L12.3997 13.7949H9.0293V17.5045H44.327V13.7949H40.9567H40.9651Z" stroke="#C2C7CC" stroke-miterlimit="10"/>
    </svg>
    {measur.count === '0.3' && <svg className={css['measur']} xmlns="http://www.w3.org/2000/svg" width="160" height="27" viewBox="0 0 160 27" fill="none">
        <path d="M159.853 0V11.2642C159.853 19.51 153.167 26.1956 144.922 26.1956H14.9314C6.68561 26.1956 0 19.51 0 11.2642V0C7.99138 0 7.99136 8.47892 15.987 8.47892C23.9826 8.47892 23.9784 0 31.974 0C39.9696 0 39.9654 8.47892 47.961 8.47892C55.9566 8.47892 55.9523 0 63.9437 0C71.9351 0 71.9351 8.47892 79.9307 8.47892C87.9264 8.47892 87.9221 0 95.9177 0C103.913 0 103.909 8.47892 111.905 8.47892C119.9 8.47892 119.896 0 127.892 0C135.887 0 135.887 8.47892 143.879 8.47892C151.87 8.47892 151.874 0 159.866 0H159.853Z" fill="#E4D6A2"/>
        </svg> }
    {measur.count === '0.8' && <svg className={css['measur']} xmlns="http://www.w3.org/2000/svg" width="160" height="45" viewBox="0 0 160 45" fill="none">
        <path d="M159.853 0V29.7144C159.853 37.9601 153.167 44.6457 144.922 44.6457H14.9314C6.68564 44.6457 0 37.9601 0 29.7144V0C7.99138 0 7.99139 8.47892 15.987 8.47892C23.9826 8.47892 23.9784 0 31.974 0C39.9696 0 39.9654 8.47892 47.961 8.47892C55.9566 8.47892 55.9524 0 63.9437 0C71.9351 0 71.9351 8.47892 79.9307 8.47892C87.9263 8.47892 87.9221 0 95.9178 0C103.913 0 103.909 8.47892 111.905 8.47892C119.9 8.47892 119.896 0 127.892 0C135.887 0 135.887 8.47892 143.879 8.47892C151.87 8.47892 151.874 0 159.866 0H159.853Z" fill="#E4D6A2"/>
        </svg> }
    {(measur.count === '1.0' || measur.count === '1.5') && <svg className={css['measur']} xmlns="http://www.w3.org/2000/svg" width="160" height="86" viewBox="0 0 160 86" fill="none">
        <path d="M159.857 0.914062V70.6447C159.857 78.8904 153.172 85.576 144.926 85.576H14.9314C6.68562 85.576 0 78.8904 0 70.6447V0.914062C7.99138 0.914062 7.99138 9.39298 15.987 9.39298C23.9826 9.39298 23.9784 0.914062 31.974 0.914062C39.9696 0.914062 39.9654 9.39298 47.961 9.39298C55.9566 9.39298 55.9524 0.914062 63.9437 0.914062C71.9351 0.914062 71.9351 9.39298 79.9307 9.39298C87.9263 9.39298 87.9221 0.914062 95.9177 0.914062C103.913 0.914062 103.909 9.39298 111.905 9.39298C119.9 9.39298 119.896 0.914062 127.892 0.914062C135.887 0.914062 135.887 9.39298 143.879 9.39298C151.87 9.39298 151.874 0.914062 159.866 0.914062H159.857Z" fill="#E4D6A2"/>
        </svg> }
    </>
    : <svg className={css['glue']} xmlns="http://www.w3.org/2000/svg" width="109" height="169" viewBox="0 0 109 169" fill="none">
        <path d="M64.8116 34.8652H43.7621C41.9276 34.8652 40.33 36.1058 39.8692 37.8805L36.6523 58.6947H71.93L68.7132 37.8805C68.2524 36.1058 66.6504 34.8652 64.8159 34.8652H64.8116Z" stroke="#C2C7CC" stroke-miterlimit="10"/>
        <path d="M68.8423 74.8576C67.2059 73.0614 67.4772 69.7402 67.4772 69.7402H41.0965C41.0965 69.7402 41.3721 73.0614 39.7314 74.8576C38.095 76.6539 34.8394 77.8212 34.8394 77.8212H73.7387C73.7387 77.8212 70.4787 76.6539 68.8466 74.8576H68.8423Z" stroke="#C2C7CC" stroke-miterlimit="10"/>
        <path d="M57.357 1.21484H51.2205C50.1181 1.21484 49.1836 2.03759 49.0501 3.13171L45.1055 34.8655H63.4721L59.5274 3.13171C59.3896 2.03759 58.4595 1.21484 57.357 1.21484Z" stroke="#C2C7CC" stroke-miterlimit="10"/>
        <path d="M41.471 57.3601C41.4323 57.3601 41.3892 57.3601 41.3461 57.3514C40.782 57.2825 40.3772 56.7699 40.4461 56.2013L42.4443 39.6474C42.5132 39.0831 43.0299 38.6825 43.5941 38.7471C44.1582 38.816 44.5587 39.3286 44.4941 39.8929L42.4959 56.4469C42.4313 56.9724 41.9878 57.3558 41.471 57.3558V57.3601Z" stroke="#C2C7CC" stroke-miterlimit="10"/>
        <path d="M67.107 57.3597C66.5945 57.3597 66.1466 56.9763 66.0864 56.4508L64.0882 39.8968C64.0193 39.3325 64.4241 38.8156 64.9882 38.751C65.5524 38.6864 66.0648 39.087 66.138 39.6513L68.1362 56.2052C68.2051 56.7695 67.8003 57.2864 67.2362 57.3554C67.1931 57.3597 67.15 57.364 67.1113 57.364L67.107 57.3597Z" stroke="#C2C7CC" stroke-miterlimit="10"/>
        <path d="M54.2005 57.3595H54.1876C53.6192 57.3552 53.1627 56.8857 53.167 56.3171L53.3479 39.7631C53.3522 39.1988 53.813 38.7422 54.3814 38.7422H54.3943C54.9628 38.7508 55.4192 39.216 55.4106 39.7846L55.2298 56.3386C55.2255 56.9029 54.7647 57.3595 54.2005 57.3595Z" stroke="#C2C7CC" stroke-miterlimit="10"/>
        <path d="M47.9262 57.3602C47.9262 57.3602 47.8874 57.3602 47.8702 57.3602C47.3018 57.33 46.8668 56.8433 46.897 56.2747L47.8056 39.7207C47.8358 39.1521 48.331 38.7214 48.8908 38.7472C49.4593 38.7774 49.8942 39.2641 49.8598 39.8327L48.9511 56.3867C48.921 56.938 48.4645 57.3602 47.9219 57.3602H47.9262Z" stroke="#C2C7CC" stroke-miterlimit="10"/>
        <path d="M60.6517 57.3602C60.1091 57.3602 59.6527 56.9337 59.6225 56.3867L58.7139 39.8327C58.6837 39.2641 59.1187 38.7774 59.6871 38.7472C60.2599 38.7214 60.7422 39.1521 60.7723 39.7207L61.681 56.2747C61.7111 56.8433 61.2762 57.33 60.712 57.3602C60.6905 57.3602 60.6733 57.3602 60.6517 57.3602Z" stroke="#C2C7CC" stroke-miterlimit="10"/>
        <path d="M103.134 77.8203H5.43994C2.98962 77.8203 1.00439 80.3058 1.00439 83.3771V162.227C1.00439 165.294 2.98962 167.784 5.43994 167.784H103.134C105.584 167.784 107.569 165.294 107.569 162.227V83.3771C107.569 80.3101 105.584 77.8203 103.134 77.8203Z" stroke="#C2C7CC" stroke-miterlimit="10"/>
        <path d="M107.574 162.227V151.94C106.252 153.297 104.779 154.495 102.178 154.495C96.1834 154.495 96.1834 148.137 90.1932 148.137C84.2031 148.137 84.2031 154.495 78.2086 154.495C72.2142 154.495 72.2185 148.137 66.224 148.137C60.2296 148.137 60.2339 154.495 54.2395 154.495C48.245 154.495 48.2493 148.137 42.2549 148.137C36.2604 148.137 36.2647 154.495 30.2746 154.495C24.2845 154.495 24.2845 148.137 18.29 148.137C12.2956 148.137 12.2999 154.495 6.30543 154.495C3.76468 154.495 2.30052 153.349 1 152.031V162.222C1 165.268 2.95939 167.74 5.38387 167.775C5.42693 167.775 5.4743 167.775 5.51737 167.775H102.962C103.056 167.775 103.151 167.771 103.246 167.766C105.644 167.689 107.569 165.238 107.569 162.218L107.574 162.227Z" fill="#C2C7CC"/>
        <path d="M74.1695 58.6309H34.4089C33.3065 58.6309 32.415 59.5225 32.415 60.621V67.75C32.415 68.8484 33.3065 69.7401 34.4089 69.7401H74.1695C75.2719 69.7401 76.1633 68.8484 76.1633 67.75V60.621C76.1633 59.5225 75.2719 58.6309 74.1695 58.6309Z" stroke="#C2C7CC" stroke-miterlimit="10"/>
        </svg> }
    <div className={css['question']}>
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
        <path d="M19.59 5.85L13.65 2.42C12.68 1.86 11.48 1.86 10.5 2.42L4.57 5.85C3.6 6.41 3 7.45 3 8.58V15.42C3 16.54 3.6 17.58 4.57 18.15L10.51 21.58C11.48 22.14 12.68 22.14 13.66 21.58L19.6 18.15C20.57 17.59 21.17 16.55 21.17 15.42V8.58C21.17 7.45 20.56 6.42 19.59 5.85ZM13 16.63C12.95 16.75 12.88 16.86 12.79 16.96C12.6 17.15 12.35 17.25 12.08 17.25C11.95 17.25 11.82 17.22 11.7 17.17C11.57 17.12 11.47 17.05 11.37 16.96C11.28 16.86 11.21 16.75 11.15 16.63C11.1 16.51 11.08 16.38 11.08 16.25C11.08 15.99 11.18 15.73 11.37 15.54C11.47 15.45 11.57 15.38 11.7 15.33C12.07 15.17 12.51 15.26 12.79 15.54C12.88 15.64 12.95 15.74 13 15.87C13.05 15.99 13.08 16.12 13.08 16.25C13.08 16.38 13.05 16.51 13 16.63ZM14.54 10.78C14.22 11.26 13.9 11.56 13.63 11.83C13.37 12.08 13.15 12.3 12.98 12.59C12.9 12.73 12.85 12.86 12.82 13.09C12.77 13.47 12.47 13.74 12.1 13.74H12.07C11.87 13.74 11.67 13.65 11.54 13.5C11.4 13.35 11.34 13.15 11.35 12.95C11.37 12.73 11.43 12.41 11.6 12.12C11.83 11.72 12.17 11.4 12.49 11.1C12.75 10.86 13.02 10.61 13.22 10.32C13.49 9.94 13.52 9.41 13.31 9C13.09 8.57 12.64 8.34 12.06 8.34C11.39 8.34 10.99 8.73 10.78 9.05C10.66 9.24 10.45 9.35 10.21 9.35C10.11 9.35 10.01 9.33 9.92 9.29C9.72 9.2 9.56 9.03 9.5 8.82C9.44 8.62 9.48 8.43 9.59 8.27C10.16 7.45 11.03 6.99 12.04 6.99C13.13 6.99 14.04 7.44 14.52 8.24C14.84 8.79 15.11 9.92 14.53 10.78H14.54Z" fill="#C2C7CC"/>
    </svg>
    <div className={css['hint']}>
        {hint}
    </div>
    </div>
    <div className={css['crop-scheme-item__descr']}>
        <p>{name}</p>
        <p>{measur.count} <span>{measur.type}</span></p>
    </div>
    </div>
  )
}

export default CropScheme