import React, { useEffect, useMemo } from 'react'
import { useState } from 'react';
import { Popup } from 'react-popupify'
import Select from 'react-select';
import { AxiosInstance } from '../../axios/axios';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { getSubscriberInfo, getSpecialtiesList } from '../../axios/study';
import ReactInputMask from 'react-input-mask';

// Дані для створення користувача
const initialData = {
    name: "",
    email: "",
    phone: "",
    ipn: "",
    pasportCode: "",
    pasportNumber: "",
};

const CustomPopupRegister = ({open, setIsOpen, setIsOpenLogin, eventInfo, eventLocations }) => {
  const [loader, setLoader] = useState(false);
  const [optionLocation, setOptionLocation] = useState(null);
  const [optionSpecialties, setOptionSpecialties] = useState(null);

  const [alertPhone, setAlertPhone] = useState(false);
  const [userExist, setUserExist] = useState(false);
  const [userData, setUserData] = useState(false);

  const [selectedLocation, setSelectedLocation] = useState(null);
  const [selectedSpecialties, setSelectedSpecialties] = useState(null);
  const [selectedSpecialtiesSub, setSelectedSpecialtiesSub] = useState(null);
  const [isIpn, setIsIpn] = useState(false);

  const [optionsSub, setOptionsSub] = useState(null);
  const laborProtectionLabel = 'Напрямок Охорона праці';
  const [laborProtectionId, setLaborProtectionId] = useState('');
  const [registrationData, setRegistrationData] = useState(initialData);
  
  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === 'ipn') {
      setIsIpn(false);
    }
    setRegistrationData({
      ...registrationData,
      [name]: name === 'phone' ? value.replace(/[+\s()]/g, '') : value
    });
  };

  // вибрати список підрозділів
  useEffect(()=>{
      let active = false;
      if (open && eventLocations) {
        const formatLocations = eventLocations.map(item => {
          return {
            value: item.location.id,
            label: item.location.name
          }
        })
        if (!active) {
          setOptionLocation(formatLocations);
        }
      }
      return () => {
        active = true;
      };
  }, [open, eventLocations])

  // вибрати список спеціальностей
  useEffect(()=>{
      let active = false;
      if (open && eventInfo) {
      const formatSpeciality = eventInfo.specialty.map(item => {
        return {
          value: item.id,
          label: item.name,
          countSub: item.countSub,
          specialties: item.specialties
        }
      })
      if (!active) {
        setOptionSpecialties(formatSpeciality);
      }
      return () => {
        active = true;
      };
    }
  }, [open, eventInfo])

  // Знайти id для "Напрямок Охорона праці"
  useEffect(() => {
    if (open) {
      getSpecialtiesList().then(response => {
        const specialtiesList = response['hydra:member'];
        if (specialtiesList.length > 0 && optionSpecialties) {
            let arr = specialtiesList.filter(specialty => specialty.name === laborProtectionLabel);
            if (arr.length > 0) {
                setLaborProtectionId(arr[0].id);
                 // видалити Охорону праці з переліку спеціальностей 
                setOptionSpecialties((state)=>state.filter(spec => {
                 return spec.value !== arr[0].id}
                ))
            };
        };
      })
    }
}, [open, optionSpecialties]);

  function submitReg (e) {
    e.preventDefault();
    // форматування допоміжних напрямків до відправки
    const formatSub = selectedSpecialtiesSub ? Object.values(selectedSpecialtiesSub).map(sub=> sub?.value && `/api/specialties/${sub?.value}`).filter((subs => subs !== undefined)) : [];

    const groupData = {
        ...registrationData,
        location: `/api/locations/${selectedLocation?.value}`,
        events: [`/api/events/${eventInfo.id}`],
        specialty: `/api/specialties/${selectedSpecialties?.value}`,
        specialties: [`/api/specialties/${laborProtectionId}`, ...formatSub],
        studyType: "Відвідування лекцій на підприємстві",
        ipn: !isIpn ? registrationData.ipn : null,
        phone: registrationData.phone
    }

    // перевірка чи телефон заповенений 
    if(!alertPhone) {
      setLoader(true);
      AxiosInstance.post('/subscribers', groupData).then(() => {
        toast.success("Користувача успішно зареєстровано", {
          position: toast.POSITION.TOP_LEFT
        });
        setIsOpenLogin(true);
      }).catch(()=>{
        toast.error("При відправці даних сталась помилка", {
          position: toast.POSITION.TOP_LEFT
        });
      }).finally(()=>{
        setLoader(false);
        setIsOpen(false);
        setRegistrationData(initialData);
        setSelectedLocation(null);
        setSelectedSpecialties(null);
        setSelectedSpecialtiesSub(null);
        setOptionsSub(null)
        setIsIpn(false);
      })
    }
  }
  
  // перевірка чи підписаний юзер на подію
  function checkUser() {
    if(registrationData.name.length > 0) {
      getSubscriberInfo(registrationData.name, eventInfo.id).then((response) => {
        const [data] = response['hydra:member'];
         if (data !== undefined) {
            setUserExist(true);
            setUserData(data);
         }
         else {
            setUserExist(false);
            setUserData(false);
         }
      }).catch(()=>{
          setUserExist(false);
          setUserData(false);
      })
    }
    else {
      setUserExist(false);
      setUserData(false);
    }
  }

  function loginUser() { 
    if (userData) {
      // Внести дані залогіненого користувача в localStorage для подальшого використання
      localStorage.setItem('user_id', userData.id);
      localStorage.setItem('user_name', userData.name);
      localStorage.setItem('events', JSON.stringify(userData?.events || ''));
      localStorage.setItem('location', JSON.stringify(userData?.location || ''));
      localStorage.setItem('specialty', JSON.stringify(userData?.specialty || ''));
      localStorage.setItem('specialties', JSON.stringify(userData?.specialties || ''));
      setIsOpen(false);
      toast.success("Користувача авторизовано", {
        position: toast.POSITION.TOP_LEFT
      })
    } 
  }

  useMemo(()=>{
    if(selectedSpecialties?.countSub !== undefined &&  selectedSpecialties?.countSub > 0){
      const subList =  selectedSpecialties.specialties.map(special => {return {value: special.id,label: special.name}});
      let tempSub = {arr: [], states: {}};
      for(let i = 0; i < selectedSpecialties?.countSub; i++) {
        tempSub.arr.push(subList);
        tempSub.states[i] = null;
      }
      setOptionsSub(tempSub.arr);
      setSelectedSpecialtiesSub(tempSub.states);
    }
    else {
      setOptionsSub(null);
    }
  }, [selectedSpecialties])

  function handleIpnChange() {
    setRegistrationData({...registrationData, pasportCode: '', pasportNumber: '' }) // очистити поля з паспортними даними
    setIsIpn(!isIpn);
    !isIpn && setRegistrationData({...registrationData, ipn: ''}); // очистити поле з іпн якщо галочка виставлена
  }
  
  return (
    <>
    <Popup
      popupId="registerPopup"
      animation="zoom"
      open={open}
      closeOnEscape={true}
      closeOnOutsideClick={true}
      closeButton={true}
      onClose = {()=> {setIsOpen(false)}}
    >
    <h4>
       Форма реєстрації
    </h4>
    <form action="submit" onSubmit={submitReg}>
    <div className="form__group field">
        <input 
           value={registrationData.name} 
           onChange={handleChange} 
           onBlur={checkUser}
           type="input" 
           className="form__field" 
           placeholder="П.І.Б. - логін" 
           name="name" 
           id='name'
          required />
        <label htmlFor="name" className="form__label">П.І.Б. - логін</label>
        {userExist && <p className='user-exist'>Введений П.І.Б. вже зареєстровано на подію. <span onClick={loginUser}>Увійти</span></p>}
    </div>
    <div className="form__group field">
        <input value={registrationData.email} onChange={handleChange} type="email" className="form__field" placeholder="radsugar@com.ua" name="email" id='email' required />
        <label htmlFor="email" className="form__label">Email</label>
    </div>
    <div className={"form__group field" + (alertPhone ? ' alert' : '')}>
      <ReactInputMask 
          mask={'+380 (99) 9999 999'}  
          name={'phone'}
          type={'phone'}
          id={'phone'}
          maskChar={''}
          className="form__field"
          placeholder="Телефон"
          onChange={handleChange}
          value={registrationData.phone}
          onBlur= {()=> {registrationData.phone.length < 12 ? setAlertPhone(true) : setAlertPhone(false)}} 
          min={100}
          required
          >
      </ReactInputMask>
      <label htmlFor="phone" className="form__label">Телефон{alertPhone && ': не заповнено'}</label>
    </div>

    <div className="form__group field">
        <input value={registrationData.ipn} maxLength={10} minLength={10} 
          onChange={(e)=>{
            const removeChar = e.target.value.replace(/\D/g, '');
            setRegistrationData({
              ...registrationData,
              [e.target.name]: removeChar
            });
          }} 
          type="text" 
          className="form__field" 
          placeholder="ІПН - Індивідуальний Податковий Номер" 
          name="ipn" 
          id='ipn' 
          required={!isIpn} 
        />
        <label htmlFor="ipn" className="form__label">ІПН - Індивідуальний Податковий Номер</label>
        <label className="form-control">
            <input required = {registrationData.ipn.length === 0}
            type="checkbox" value={isIpn} checked={isIpn} onChange = {handleIpnChange} 
            name="checkbox" />
             Немає ІПН
  </label>
    </div>
    {isIpn &&
      <>
      <label htmlFor="pasportNumber" className='label-pass'>Серія та номер паспорта <span>(у випадку ІД кртки заповнити тільки Номер)</span></label>
        <div className="form__group field">
            <input value={registrationData.pasportCode} onChange={handleChange} type="input" className="form__field" placeholder="Серія паспорта" name="pasportCode" id='pasportCode' />
            <label htmlFor="pasportCode" className="form__label">Серія паспорта</label>
        </div>
        <div className="form__group field">
            <input value={registrationData.pasportNumber} onChange={handleChange} type="number" className="form__field" placeholder="Номер паспорта" name="pasportNumber" id='pasportNumber' required />
            <label htmlFor="pasportNumber" className="form__label">Номер паспорта</label>
        </div>
      </>
    }
    <div className="form__group select">
        <label htmlFor="subpart">Підрозділ</label>
        <Select
            required
            defaultValue={selectedLocation}
            onChange={setSelectedLocation}
            placeholder = {'Підрозділ'}
            options={optionLocation || []}
            name={'subpart'}
        />
    </div>
    <div className="form__group select">
        <label htmlFor="subpart">
          Обов'язковий напрямок
        </label>
        <Select
            placeholder = {laborProtectionLabel}
            name={'mainpart'}
            isDisabled={true}
        />
    </div>
    <div className="form__group select">
        <label htmlFor="subpart">
        Основний напрямок<br />
            Вибрати тільки один Основний напрямок. <br />
            При виборі тільки Основного напрямку (без Допоміжних напрямків) <br />
            - це 4 Розряд
        </label>
        <Select
            required
            defaultValue={selectedSpecialties}
            onChange={setSelectedSpecialties}
            placeholder = {"Основний напрямок"}
            options={optionSpecialties || []}
            name={'mainpart'}
            onMenuOpen={() => {
              setOptionsSub(null);
              setSelectedSpecialtiesSub(null);
            }}
        />
    </div>
    {optionsSub && 
      optionsSub.map((options, index) => {
        return <div key={index} className="form__group select">
                <label htmlFor={`submain${index}`}>
                  Допоміжний напрямок {index + 1}
                </label>
                <Select
                    defaultValue={selectedSpecialtiesSub[index]}
                    onChange={(newValue) => {setSelectedSpecialtiesSub({...selectedSpecialtiesSub, [index]: newValue})}}
                    placeholder = {'Допоміжний напрямок ' + (index + 1)}
                    options={options}
                    name={`submain${index}`}
                />
            </div>
      })
    }
    <div className="form__group select">
        <label htmlFor="subpart">
            Форма навчання
        </label>
        <Select
            placeholder = {"Відвідування лекцій на підприємстві"}
            name={'mainpart'}
            isDisabled={true}
        />
    </div>
    {!userExist &&
      <button className="entrance__btn form" type='submit'>
          <div className="study__top-reg--text entrance__text">
            Завершити реєстрацію {loader && <div className="loader sm"></div>}
          </div>
      </button>}
    </form>
    </Popup>
    <ToastContainer /> 
    </>
  )
}

export default CustomPopupRegister