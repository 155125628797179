import React from "react";
import css from "./powerelem.module.scss";

const PowerElement = ({ img, from, to }) => {
  return (
    <div className={css.element}>
        <img className={css.image} src={img} alt="element" />
        <p className={css.text}>{from} <span>—</span> {to}</p>
    </div>
  );
};

export default PowerElement;
