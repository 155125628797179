import css from "./SchebuleHead.module.scss";

import Container from "../../../components/Container/Container";
import allLectureSchedule from "../dataLectureSchedule/dataShipment";
import { useTranslation } from "react-multi-lang";
const { lectureScheduleWithIds } = allLectureSchedule; // all obj

const SchebuleHead = () => {
const t = useTranslation();
  return (
    <div className={css["wrapper"]}>
  
        <p>2023</p>
        <h2>
          <div dangerouslySetInnerHTML={{ __html: t('headTemplate.schebuleHeadText1') }} />
           </h2>
        <h3>
        <div dangerouslySetInnerHTML={{ __html: t('headTemplate.schebuleHeadText2') }} />
          </h3>
    
    </div>
  );
};

export default SchebuleHead;
