import React, { useEffect } from "react";
import css from "./energy.module.scss";
import { EnergyTitle } from "../../components/energy/EnergyTitle";
import { Link } from "react-router-dom";
import { getPageItem } from "../../axios/pages";
import { useState } from "react";
import DynamicInfos from "../../components/DynamicInfos/DynamicInfos";


export const EnergySupply = () => {
    // const translate = useTranslation();
    const [loading, setLoading] = useState(false);
    const [pageData, setPageData] = useState([]);
    const startIndex = 0; // start from 0

    useEffect(()=>{
        let active = false;
        getPageItem(setLoading, 2).then(response=>{
            setPageData(response.pageInfos);
    
        }).catch(err=>{
          console.log(err);
        })

        return () => {
            active = true;
        }; 
    }, []);

    return (
        <section className={css["energy-supply"]}>
        <div className="container">
            <div className={css["energy-supply__inner"]}>
                <DynamicInfos pageData={pageData} startIndex={startIndex}/>

                <div className={css["energy-supply-block"]}>
                    <div className={css["energy-supply-block--btn"]}>
                        <Link to={"#"}>Електробезпека</Link>
                        <p>Важливі зауваження щодо електробезпеки. Будь ласка ретельно ознайомтесь!</p>
                    </div>
                </div>
                <div className={css["energy-supply-block"]}>
                    <EnergyTitle index={pageData.length + startIndex + 1} text={'Контакти'} />
                    <ul>
                        <li><span>Менеджер:</span> Тарасенко Тарас Тарасович</li>
                        <li><span><img src="/media/images/energy/phone-ico.svg" alt="phone" /></span><Link to={'tel:380965836762'}>+38 096 583 67 62</Link></li>
                        <li><span><img src="/media/images/energy/mail-ico.svg" alt="mail" /></span><Link to={'mailto:taras@gmail.com'}>taras@gmail.com</Link></li>
                    </ul>
                </div>
            </div>
        </div>
        </section>
    );
  }