import css from "./CardEngineer.module.scss";
// import { ReactComponent as CopyIcon } from "../../../../../media/images/icons/copy.svg";
import { ReactComponent as CopyIcon } from "./../../media/images/icons/copy.svg";

const CardEngineer = ({ objEngineer }) => {
  // console.log(objEngineer);

  const handleCopy = (e, text) => {
    e.preventDefault();

    navigator.clipboard.writeText(text);
    e.stopPropagation();
  };

  return (
    <div className={css["cardWrapper"]}>

<div className={css["wrapper_borderImg"]}>

      <div className={css["borderImg"]}>
        <img src={objEngineer.imgUrl} alt="Фото Інженера" />
      </div>
</div>


      <p className={css["firstName"]}>{objEngineer.firstName}</p>
      <p className={css["lastName"]}>{objEngineer.lastName}</p>

      {/* Копіювання */}
      <div className={css["contact"]}>
        <p>{objEngineer.phone}</p>
        <button>
          {objEngineer.phone && (
            <CopyIcon onClick={(e) => handleCopy(e, objEngineer.phone)} />
          )}
        </button>
      </div>

    {/* Копіювання */}
    <div className={css["contact"]}>
        <p>{objEngineer.email}</p>
        <button>
          {objEngineer.phone && (
            <CopyIcon onClick={(e) => handleCopy(e, objEngineer.email)} />
          )}
        </button>
      </div>
    </div>
  );
};

export default CardEngineer;
