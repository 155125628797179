import React, { useEffect, useRef, useState } from "react";
import css from "./News.module.scss";
import Container from "../../components/Container/Container";
import { getNewsItem } from "../../axios/news";
import * as dayjs from 'dayjs';
import { useParams } from "react-router-dom";
import { Breadcrumbs } from "../../components/Breadcrumps";
import routes from "../../routes";
import * as DOMPurify from 'dompurify';

const News = () => {

const param = useParams();
const [newsItem, SetNewsItem] = useState({});
const [loading, SetLoading] = useState(true);

  useEffect(()=>{
    let active = false;
    window.scrollTo({
        top: 500,
        behavior: "smooth",
    });

    getNewsItem(SetLoading, param.id).then((response)=>{

      const newsItem = {
        id : response.id,
        title: response.name,  
        date: dayjs(response.date).format('DD.MM.YYYY'),
        content: response.newsFull,
        imgURL: response.video_img || response.media?.contentUrl,
        active: response.active
    }; 

      if(!active){
        SetNewsItem(newsItem);
      }
    })

    return () => {
      active = true;
    };
},[])

const newRoutes = routes.map((route) =>
(route.path === "/news/:id" || route.path === "/social-activity/:id")
// берем назву з заголовку для хлібних крихт або ід якщо назва довше 70 символів
  ? { ...route, name: newsItem.title?.length < 80 ? newsItem.title : newsItem.title?.substring(0, 80) + '...' }
  : route
);

  return (
    
    <div className={css["NewsWrapper"]}>
      <div className="breadcrump-inner" style={{margin: '-106px 0 30px 0', position: 'relative', zIndex: '1'}}>
        <div className="container">
          <Breadcrumbs routes={newRoutes} />
        </div>
      </div>
      <Container>
      <div className={css["news-page"]}>
        <img
          // src={process.env.REACT_APP_SERVER_URL + newsItem.imgURL} 
          src={newsItem.imgURL?.includes('http') ? newsItem.imgURL : process.env.REACT_APP_SERVER_URL + newsItem.imgURL}
          alt="news" />
        <div className={css["news-page__inner"]}>
        <div className={css["news-page__title"]}>
            <h2>{newsItem.title}</h2>
            <p>{newsItem.date}</p>
        </div>
        <div className={css["news-page__content"]} dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(newsItem.content)}}></div>
        </div>
        </div>
      </Container>
    </div>
  );
};

export default News;
