import React from "react";
import Title from "../../components/Title/Title";
import PowerElement from "../../components/Power/PowerElement";
import css from "./power.module.scss";
import { useTranslation } from "react-multi-lang";
import { useOutletContext } from "react-router-dom";

export const Power = () => {
    const translate = useTranslation();
    const {lang, setLang} = useOutletContext();

    const powerElements = [
        { id: '1', img: '/media/images/power/azot.svg', from: '100', to: '140'},
        { id: '2', img: '/media/images/power/azot.svg', from: '110', to: '160'},
        { id: '3', img: '/media/images/power/azot.svg', from: '120', to: '160'},
        { id: '4', img: '/media/images/power/azot.svg', from: '150', to: '200'},
        { id: '5', img: '/media/images/power/azot.svg', from: '180', to: '220'},
        { id: '6', img: '/media/images/power/oksyd.svg', from: '45', to: '60'},
        { id: '7', img: '/media/images/power/oksyd.svg', from: '60', to: '80'},
        { id: '8', img: '/media/images/power/oksyd.svg', from: '75', to: '100'},
        { id: '9', img: '/media/images/power/oksyd.svg', from: '90', to: '120'},
        { id: '10', img: '/media/images/power/oksyd.svg', from: '105', to: '140'},
        { id: '11', img: '/media/images/power/k20.svg', from: '180', to: '220'},
        { id: '12', img: '/media/images/power/k20.svg', from: '240', to: '280'},
        { id: '13', img: '/media/images/power/k20.svg', from: '300', to: '350'},
        { id: '14', img: '/media/images/power/k20.svg', from: '360', to: '420'},
        { id: '15', img: '/media/images/power/k20.svg', from: '400', to: '440'},
        { id: '16', img: '/media/images/power/mgo.svg', from: '30', to: '60'},
        { id: '17', img: '/media/images/power/mgo.svg', from: '40', to: '60'},
        { id: '18', img: '/media/images/power/mgo.svg', from: '50', to: '100'},
        { id: '19', img: '/media/images/power/mgo.svg', from: '60', to: '120'},
        { id: '20', img: '/media/images/power/mgo.svg', from: '70', to: '140'},
        { id: '21', img: '/media/images/power/sirka.svg', from: '8', to: '10'},
        { id: '22', img: '/media/images/power/sirka.svg', from: '10', to: '12'},
        { id: '23', img: '/media/images/power/sirka.svg', from: '12', to: '15'},
        { id: '24', img: '/media/images/power/sirka.svg', from: '18', to: '20'},
        { id: '25', img: '/media/images/power/sirka.svg', from: '25', to: '30'},
    ]

    function residuePos(str) {
        const array = str.split(" ");
        return array.slice(1, array.length).join(" ");
    }

    console.log(lang === 'en');

    return (
        <section className={css["power"]}>
        <div className="container">
            <div className={css["beetroot"]}>
            <Title num1={'01'} num2={'03'} row1={translate('power.title1').split(' ')[0]} row2={residuePos(translate('power.title1'))} />
                <div className={css["beetroot__descr"]}>
                    <div className={css["beetroot__descr-item"]}>
                        <p> {translate('power.texblock1')} 
                        <br />
                        <a className={css["table-link"]} href="#table-block1">{translate('table')} №1</a>
                        <svg xmlns="http://www.w3.org/2000/svg" width="26" height="8" viewBox="0 0 26 8" fill="none">
                        <path d="M25.3536 4.35355C25.5488 4.15829 25.5488 3.84171 25.3536 3.64645L22.1716 0.464466C21.9763 0.269204 21.6597 0.269204 21.4645 0.464466C21.2692 0.659728 21.2692 0.976311 21.4645 1.17157L24.2929 4L21.4645 6.82843C21.2692 7.02369 21.2692 7.34027 21.4645 7.53553C21.6597 7.7308 21.9763 7.7308 22.1716 7.53553L25.3536 4.35355ZM1 3.5C0.723858 3.5 0.5 3.72386 0.5 4C0.5 4.27614 0.723858 4.5 1 4.5V3.5ZM25 3.5L1 3.5V4.5L25 4.5V3.5Z" fill="#C2C7CC"/>
                        </svg>
                     </p>
                    </div>
                    <div className={css["beetroot__descr-item"]}>
                        <p dangerouslySetInnerHTML={{__html: translate('power.texblock2')}}>
                        </p>
                        <img src={"/media/images/power/powe-azot_en.png"} alt="azot" />
                    </div>
                </div>
            </div>
            <div className={css["organization"]}>
            <Title num1={'02'} num2={'03'} row1={translate('power.title2').split(' ')[0]} row2={residuePos(translate('power.title2'))} />
                <h3 className={css["organization__subtitle"]} id="table-block1">{translate('power.subtitle2')}</h3>
                <p>
                    <a href="#table-block1" className={css["table-link"]}>{translate('table')} №1</a>
                    <svg xmlns="http://www.w3.org/2000/svg" width="26" height="8" viewBox="0 0 26 8" fill="none">
                    <path d="M0.646447 4.35355C0.451184 4.15829 0.451184 3.84171 0.646447 3.64645L3.82843 0.464466C4.02369 0.269204 4.34027 0.269204 4.53553 0.464466C4.7308 0.659728 4.7308 0.976311 4.53553 1.17157L1.70711 4L4.53553 6.82843C4.7308 7.02369 4.7308 7.34027 4.53553 7.53553C4.34027 7.7308 4.02369 7.7308 3.82843 7.53553L0.646447 4.35355ZM25 3.5C25.2761 3.5 25.5 3.72386 25.5 4C25.5 4.27614 25.2761 4.5 25 4.5V3.5ZM1 3.5L25 3.5V4.5L1 4.5L1 3.5Z" fill="#C2C7CC"/>
                    </svg>              
                </p>
                <div className={css["organization__table-inner"]}>
                <div className={css["organization__table"]}>
                    <div className={css["organization__table-item"]}><img src={"/media/images/power/30ton_en.svg"} alt="tons"/></div>
                    <div className={css["organization__table-item"]}><img src={"/media/images/power/40ton_en.svg"} alt="tons"/></div>
                    <div className={css["organization__table-item"]}><img src={"/media/images/power/50ton_en.svg"} alt="tons"/></div>
                    <div className={css["organization__table-item"]}><img src={"/media/images/power/60ton_en.svg"} alt="tons"/></div>
                    <div className={css["organization__table-item"]}><img src={"/media/images/power/70ton_en.svg"} alt="tons"/></div>
                </div>
                </div>
            </div>
            <div className={css["text-block"]}>
                <div className={css["text-block__arrow"]}><img src="/media/images/power/arrow-circle-up.svg" alt="arrow"/></div>
                <p>{translate('power.planned.text1')}</p>
                <svg width="34" height="2" viewBox="0 0 34 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1 1H33" stroke="#C2C7CC" stroke-linecap="round"/>
                </svg>
                <p>{translate('power.planned.text2')}</p>
                <div className={css["text-block__arrow"]}><img src="/media/images/power/arrow-circle-down.svg" alt="arrow"/></div>                
            </div>
            <div className={css["power-elements"]}>
                <div className={css["elements-inner"]}>
                {powerElements.map(item => {
                    return <PowerElement id={item.id} img = {item.img} from={item.from} to={item.to} />
                })}
                </div>
                <div className={css["power-elements__descr"]}>
                    <div className={css["power-elements__descr-item"]}>
                        <img src='/media/images/power/azot.svg' alt="item" /> 
                        <p>{translate('power.elements.nitrogen')}</p>
                    </div>
                    <div className={css["power-elements__descr-item"]}>
                        <img src='/media/images/power/oksyd.svg' alt="item" /> 
                        <p>{translate('power.elements.phosphorus')}</p>
                    </div>
                    <div className={css["power-elements__descr-item"]}>
                        <img src='/media/images/power/k20.svg' alt="item" /> 
                        <p>{translate('power.elements.potassium')}</p>
                    </div>
                    <div className={css["power-elements__descr-item"]}>
                        <img src='/media/images/power/mgo.svg' alt="item" /> 
                        <p>{translate('power.elements.magnesium')}</p>
                    </div>
                    <div className={css["power-elements__descr-item"]}>
                        <img src='/media/images/power/sirka.svg' alt="item" /> 
                        <p>{translate('power.elements.sulphur')}</p>
                    </div>
                </div>
            </div>
            <div className={css["azot"]}>
            <div className={css["beetroot__descr"]}>
                <div className={css["beetroot__descr-item"]}>
                    <div className={css["azot__inner"]}>
                        <p className={css["azot__inner-half"]} dangerouslySetInnerHTML={{__html: translate('power.azot.text1')}}>
                        </p>
                        <p className={css["azot__inner-half"]} dangerouslySetInnerHTML={{__html: translate('power.azot.text2')}}> 
                        </p>
                    </div>
                    <img src={"/media/images/power/powe-azot_en.png"} alt="azot" />
                </div>
                <div className={`${css["beetroot__descr-item"]} ${css["big"]}`}>
                    <div className={css["azot__inner"]}>
                        <p className={css["azot__inner-all"]} dangerouslySetInnerHTML={{__html: translate('power.azot.text3')}}>
                        </p>
                    </div>
                    <img src={"/media/images/power/bor_en.svg"} alt="element" />
                    <img src={"/media/images/power/natriy_en.svg"} alt="element" />
                    <img src={"/media/images/power/magniy_en.svg"} alt="element" />
                    <img src={"/media/images/power/phosphor_en.svg"} alt="element" />
                    <img src={"/media/images/power/sirkaelem_en.svg"} alt="element" />
                    <img src={"/media/images/power/kaliy_en.svg"} alt="element" />
                    <img src={"/media/images/power/marganez_en.svg"} alt="element" />
                    <img src={"/media/images/power/tsnk_en.svg"} alt="element" />
                    <img src={"/media/images/power/molibyden_en.svg"} alt="element" />
                </div>
            </div>
            </div>
            <div className={css["microelements"]}>
                <Title num1={'03'} num2={'03'} row1={translate('power.microelements.title')} row2={''} />
                <div className={css["beetroot__descr"]}>
                <div className={css["beetroot__descr-item"]}>
                    <p dangerouslySetInnerHTML={{__html: translate('power.microelements.text1')}}> 
                    </p>
                    <img src={"/media/images/power/bor_en.svg"} alt="bor" />
                </div>
                <div className={css["beetroot__descr-item"]}>
                    <div className={css["microelements__inner"]}>
                        <p dangerouslySetInnerHTML={{__html: translate('power.microelements.text2')}}> 
                        </p>
                        <p dangerouslySetInnerHTML={{__html: translate('power.microelements.text3')}}>
                        </p>
                    </div>
                    <img src={"/media/images/power/marganez_en.svg"} alt="margan" />
                </div>
                </div>
            </div>
        </div>
        </section>
    );
  }