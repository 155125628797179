import React, { useEffect, useRef, useState } from 'react'
import { getForm } from '../../axios/forms';
import axios from 'axios';
import * as dayjs from 'dayjs';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ReactInputMask from 'react-input-mask';
import css from "./apiform.module.scss";
import { useTranslation } from 'react-multi-lang'

const ApiForm = ({id,showLabels,showTitle, sendBtn}) => {

const t = useTranslation();

const [formData, SetFormData] = useState({});

const [file, setFile] = useState(false);
const [values, setValues] = useState({});
const button = useRef(null);

useEffect(()=>{
    let active = false;
    getForm(id).then((response) =>{
        if(!active) {
            SetFormData(response);
        }
      })

    return () => {
        active = true;
    }; 
},[id])


const handleInputChange = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value
    });
};

function handleFileChange(event) {
    setFile(event.target.files[0]);
}

function sendForm (event) {
    event.preventDefault();
    button.current.disabled = true;

    const formFiltred = formData.formFields.filter(field => {       
        return  field.type !== 'file';
    })

    const initalNames = formFiltred.map(field => {
        return field.name;
    }).reduce((a, v) => ({ ...a, [v]: ''}), {}); 

    const formAnswerFields = formFiltred.map(field => {
        return {name: field.name, formField: field['@id'], value: values[field.name]};
    })

    const configFile = { headers: { 'content-type': 'multipart/form-data',} }

    const configAll = { headers: { 'content-type': 'application/json', } }

    const article = {
        form: formData['@id'],
        media : [],
        dateEntered: dayjs().format(),
        "view": false,
        formAnswerFields: formAnswerFields,
    }


    if(file) {
        const url = process.env.REACT_APP_SERVER_URL + '/api/media_objects';
        const formFile = new FormData();
        formFile.append('file', file);
        formFile.append('fileName', file.name);

        axios.post(url, formFile, configFile).then((response) => {

            const articleFile = {...article, media: [`${response.data['@id']}`]}

            axios.post(process.env.REACT_APP_SERVER_URL  + '/api/form_answers', articleFile, configAll)
                .then(() => {
                    setValues(initalNames);
                    setFile(false);
                    button.current.disabled = false;
                    toast.success("Дані відправлено в обробку!", {
                        position: toast.POSITION.BOTTOM_LEFT
                    });
                })
                .catch(() => {
                    setValues(initalNames);
                    setFile(false);
                    toast.error("Помилка при відправці даних", {
                        position: toast.POSITION.BOTTOM_LEFT
                    });
            });
        }); 
    }
    else {        
        axios.post(process.env.REACT_APP_SERVER_URL  + '/api/form_answers', article, configAll)
            .then(() => {
                setValues(initalNames);
                setFile(false);
                button.current.disabled = false;
                toast.success("Дані відправлено в обробку!",{
                    position: toast.POSITION.BOTTOM_LEFT
                });
            })
            .catch(() => {
                setValues(initalNames);
                setFile(false);
                toast.error("Помилка при відправці даних",{
                    position: toast.POSITION.BOTTOM_LEFT
                });
        });
    }
};


return (
    <>
    <div className={css["application-form"]}>
        {showTitle && 
            <div className={css['application-form__title']}>
                {/* <h3>{formData.name}</h3>
                <p>{formData.description}</p> */}
                <h3>{t('footer.communication.name1')}</h3>
            </div>}
            <div className={css["application-form__inner"]}>
                <form onSubmit={sendForm} type='submit'>
                {formData.formFields && formData.formFields.map((field, index) => {
                    return <div className={css["application-form__item"]} key={field.id}>
                                {showLabels && 
                                <div className= {css["application-form__label"]}>
                                        <span>{field.label}</span> 
                                </div>}
                                <div className= {css["application-form__field"]}>
                                {field.type === 'select' &&
                                    <select onChange={handleInputChange} value={values[field.name]} name={field.name} required >
                                        <option value="">--{field.label}--</option>
                                        {
                                            field.defaultValue.split(", ").map((el, index) => {
                                                return <option key={index} value={el}>{el}</option>
                                            })
                                        }
                                    </select>
                                }
                                {field.type === 'textarea' && 
                                        <textarea 
                                            onChange={handleInputChange} 
                                            value={values[field.name]} 
                                            name={field.name} 
                                            className="form-control" 
                                            placeholder={t(field.defaultValue)} 
                                            aria-label="default textarea" 
                                            style={{minHeight:"144px"}} 
                                        required/>
                                }
                                {(field.type !== 'select' && field.type !== 'textarea') &&
                                <><ReactInputMask 
                                        mask={field.type === 'tel' ? '(+380)99 9999 999' : ''} 
                                        onChange={field.type === 'file' ? handleFileChange : handleInputChange} 
                                        value= {values[field.name]}
                                        name={field.name}
                                        type={field.type}
                                        placeholder={t(field.defaultValue)}
                                        className='form-control'
                                        required
                                        >
                                    </ReactInputMask>
                                {field.type === 'file' && <p className="grey-text">Ви можете додати файли формата doc, docx, xls, 
                                    xlsx, png, tiff, bmp, jpg, jpeg, gif, pdf, txt  розміром не більше чим 20Мб</p> }
                                </>}
                                </div>
                            </div>
                })}
                <div className= {css["application-form__consent"]}>
                    <input required type="checkbox" id="default-checkbox" className={css["form-check-input"]} />
                    <label htmlFor="default-checkbox">{t('question.text1')}</label>
                </div>
             <div className= {css["application-form__send"]}>  
                <button type="submit" ref={button}>{sendBtn}</button>                  
             </div>
            </form>
          </div>
    </div>
    <ToastContainer /> 
    </>
  )
}

export default ApiForm