import React from 'react'
import { EnergyList } from '../../energy/EnergyList';
import { EnergyTitle } from '../../energy/EnergyTitle'
import { findInfo } from '../../Store/findInfo';
import css from "./energy.module.scss";

const EnergyDynamicList = ({data, index}) => {
  const filtred = data.filter(elements =>{
    return elements.key !== 'title1';
  })
  
  return (
    <div className={css["energy-supply-block"]}>
        <EnergyTitle index={index + 1} text={findInfo(data, 'title1')} />
        <EnergyList list={filtred}/>
    </div>
  )
}

export default EnergyDynamicList