import React, { useEffect, useState } from "react";
import css from "./ContactsList.module.scss";
import { ReactComponent as LocationIcon } from "../../../media/images/icons/location.svg";
import { ReactComponent as EmailIcon } from "../../../media/images/icons/email.svg";
import { ReactComponent as PhoneIcon } from "../../../media/images/icons/phone.svg";
import { ReactComponent as CopyIcon } from "../../../media/images/icons/copy.svg";
import { useTranslation } from 'react-multi-lang'

const ContactsList = ({ list, changeLocation }) => {
  const t = useTranslation();
  const [currentIndex, setCurrentIndex] = useState(0);
  const translate = useTranslation();

  useEffect(() => {
    changeLocation(list[currentIndex].location.pathAPI);
  });

  const handlerItem = (index) => {
    setCurrentIndex(index);
  };

  const handleCopy = (e, text) => {
    e.preventDefault();

    navigator.clipboard.writeText(text);
    e.stopPropagation();
  };

  return (
    <div className={css["wrapperContent"]}>




      <ul className={css["location__list"]}>
        {list.map((item, index) => (
          <li
          key={index}
          className={index === currentIndex ? css["active"] : ""}
          onClick={() => handlerItem(index)}
          >
            {t(item.title[0])}&nbsp;<span>{t(item.title[1])}</span>
          </li>
        ))}
      </ul>

      <ul className={css["contacts__List"]}>
        <li className={css["contacts__item--location"]}>
          <div>
            <LocationIcon className={css["icon"]}/>
          </div>

          <div className={css["contacts__text"]}>
            {/* {list[currentIndex].location.text} */}
            {t(list[currentIndex].location.text)}
          </div>
          <div>
            <CopyIcon className={css["icon"]}
              onClick={(e) => handleCopy(e, list[currentIndex].location.text)}
            />
          </div>
        </li>

        <li className={css["contacts__item--email"]}>
          <div>
            <EmailIcon className={css["icon"]}/>
          </div>

          <div className={css["contacts__text"]}>
            {list[currentIndex].email}
          </div>

          <div>
            <CopyIcon className={css["icon"]}
              onClick={(e) => handleCopy(e, list[currentIndex].email)}
            />
          </div>
        </li>

        <li className={css["contacts__item--phone"]}>
          <PhoneIcon className={css["icon"]}/>

          <ul>
            {list[currentIndex].phone.map((item, index) => (
              <li key={index}>
                <div className={css["contacts__text"]}>{item}</div>

                <div>
                  <CopyIcon className={css["icon"]}
                    onClick={(e) =>
                      handleCopy(e, list[currentIndex].phone[index])
                    }
                  />
                </div>
              </li>
            ))}
          </ul>
        </li>
      </ul>
    </div>
  );
};

export default ContactsList;
