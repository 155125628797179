import React, { useState } from "react"; // Import React if not already imported
import css from "./LecturesLaboratory.module.scss";
import Container from "../../components/Container/Container";
import allLecturesLaboratory from "./dataLecturesLaboratory/dataLecturesLaboratory";
import InfoList from "./InfoList/InfoList";
import Schedule from "../../components/schedule/Schedule";

const { lecturesLaboratory } = allLecturesLaboratory;

const LecturesLaboratory = () => {
  const [currentIndex, setCurrentIndex] = useState(0);


  const changeLocation = (index) => {
    setCurrentIndex(index);
  };

  return (
    <section className={css["LectureWrapper"]}>
      <Container>
        <InfoList
          data={lecturesLaboratory}
          changeLocation={changeLocation}
          currentIndex={currentIndex}
        />

        <Schedule objInfo={lecturesLaboratory[currentIndex].lectureSchedule} />
        <Schedule objInfo={lecturesLaboratory[currentIndex].examSchedule} />
      </Container>
    </section>
  );
};

export default LecturesLaboratory;
