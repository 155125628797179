// dataShipment json переклад

const dataShipment = [
  {
    // title: "збір цукрових буряків",
    title: "dataShipment.el1.title",
    // description: [
    //   "На сьогоднішній день в Україні застосовується переважно перевалкова технологія збирання цукрових буряків  з використанням високопродуктивних самохідних бункерних комбайнів таких як: ROPA Tiger, HOLMER Terra Dos та інші. Зібрані ними цукрові буряки за допомогою причепів-перевантажувачів вивозяться на край поля , де з них формуються кагати.",
    // ],
    description: [
      "dataShipment.el1.description.par1",
    ],
    docs: [
      {title: "dataShipment.el1.docs.title1", url: process.env.REACT_APP_SERVER_URL  + '/media/files/Перевантажувач KLEINE LS 16.pdf'},
      {title: "dataShipment.el1.docs.title2", url: process.env.REACT_APP_SERVER_URL  + '/media/files/Перевантажувач Bergmann.pdf'},
      {title: "dataShipment.el1.docs.title3", url: process.env.REACT_APP_SERVER_URL  + '/media/files/Технічні характеристики ROPA Tiger 5.pdf'},
      {title: "dataShipment.el1.docs.title4", url: process.env.REACT_APP_SERVER_URL  + '/media/files/Навчання_збирання_цукрових_буряків_2022_Бишів.pptx'},
      {title: "dataShipment.el1.docs.title5", url: process.env.REACT_APP_SERVER_URL  + '/media/files/Перевантажувач Amity.pdf'},
      {title: "dataShipment.el1.docs.title6", url: process.env.REACT_APP_SERVER_URL  + '/media/files/Технічні_характеристики_HOLMER_Terra_DosT3.pdf'},
    ]
  },
  {
    // title: "зберігання",
    title: "dataShipment.el2.title",
    // description: [
    //   "У свіжому вигляді буряки зберігаються в окремих буртах, які мають у поперечному перетині вигляд трапеції. Називають їх кагатами. Місця, на яких обладнують польові кагати, повинні бути рівними, з невеликим нахилом для стікання води та лежати попри дорогу з твердим покриттям. У польові кагати закладають тільки кондиційні, не вражені хворобами буряки. Орієнтовані розміри кагатів такі: ширина основи не більше 8 м, висота від 2,0 м до 3,5 м. За для того,щоб цукрові буряки в кагатах зберегли свої технічні показники їх, починаючи в перших числах грудня накривають спеціально виготовленим для накривання волокном.",
    // ],
    description: [
      "dataShipment.el2.description.par1",
    ],
    docs: [
      {title: "dataShipment.el2.docs.title1", url: process.env.REACT_APP_SERVER_URL  + '/media/files/Інструкція_по_збиранню,_кагатуванню,_зберіганню_і_накриванню_цукрових.pdf'},
      {title: "dataShipment.el2.docs.title2", url: process.env.REACT_APP_SERVER_URL  + '/media/files/Укривання кагатів.pdf'},
    ]
  },
  {
    // title: "відвантаження",
    title: "dataShipment.el3.title",

    description: [
      "dataShipment.el3.description.par1",
      "dataShipment.el3.description.par2",
      "dataShipment.el3.description.par3",
    ],

    docs: [
      {title: "dataShipment.el3.docs.title1", url: process.env.REACT_APP_SERVER_URL  + '/media/files/Технічні_характеристики_ROPA_Euro_Maus_4.pdf'},
      {title: "dataShipment.el3.docs.title2", url: process.env.REACT_APP_SERVER_URL  + '/media/files/Технічні_характеристики_HOLMER_Terra_Felis_2'},
    ]

    // description: [
    //   "Сьогодні при розробцi нових конструкцiй доочищувачів-навантажувачів враховують постійно зростаючi вимоги щодо захисту ґрунтів. Неприпустимо, щоб великотонажнi автомобiлi для завантаження рухалися полями та надмірно ущільнювали ґрунт, а родюча земля разом із коренями вивозилась із полiв. У зв’язку з цим, метою створення нових очищувачiв-навантажувачiв було не лише пiдвищення продуктивностi та оптимізації процесу логістики, а й збереження структури та родючості ґрунтiв.",

    //   "Зважаючи на те, що з кожного гектара бурякових площ збирається по 40 — 80 тон коренеплодів, для додаткового їх очищення i перевантаження у транспортні засоби необхiднi мобiльнi потужнi доочищувачi-навантажувачi. В Україні така техніка, на жаль, не виробляється, і тому бурякосіючі господарства буряків використовують сучасні машини європейських виробників. Такі машини постійно удосконалюються. Останнім часом провідні європейські виробники бурякозбиральної техніки зробили вагомий крок у створенні нових доочищувачiв-навантажувачiв буряків.",

    //   "Основними робочими органами доочищувачів-навантажувачів є приймальний стiліз транспортно-очиснимивальцями, пристрій для пiдбирання залишкiв кагату, приймальний i перевантажувальний транспортери.",
    // ],
  },
];

const allShipment = {
  dataShipment,
};

export default allShipment;
