import React, {useEffect} from 'react'
import { Header } from '../components/Header'
import Footer from '../components/Footer/Footer'
import '../styles/main.scss';
// import { AppContent, AppSidebar, AppFooter, AppHeader } from '../components/index'
// import Login from 'src/views/pages/login/Login';
import { Outlet, useLocation } from "react-router-dom";
import { useLang } from '../hooks/useLang';
import { useTranslation } from 'react-multi-lang';


const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

const DefaultLayout = () => {

  const {lang, setLang} = useLang();
  const t = useTranslation();

  const location = useLocation();

  useEffect(()=>{
    switch (location.pathname) {
      case '/clients':
        document.title = `${t("header.title1.name")} | ${t("logo")}`;
        break;
      case '/workers':
        document.title = `${t("header.title2.name")} | ${t("logo")}`;
        break;
      case '/fermers':
        document.title = `${t("header.title3.name")} | ${t("logo")}`;
        break;
      case '/drivers':
        document.title = `${t("header.title4.name")} | ${t("logo")}`;
      break;
      case '/partners':
        document.title = `${t("header.title5.name")} | ${t("logo")}`;
      break;
      default:
        document.title = t("logo");
        break;
    }
  }, [lang, location])

  return (
    <div className='App'>
        <Header lang={lang} setLang={setLang}/>
        <Outlet context={{lang, setLang}} />
        <Footer/>
        <ScrollToTop/>
    </div>
  )

}

export default DefaultLayout
