import React from 'react';
import ProductsListItem from './ProductsListItem';

// Список з товарами вибраної категорії
const ProductsList = (props) => {
    return (
        <ul className="sugar">
            {
                (props.itemHover_2 !== false) ?
                    // Список продуктів при наведенні курсора (hover)
                    props.itemHover_2.submenu.map((item, index) => <ProductsListItem key={index} closeMobile = {props.closeMobile} item={item} />)
                    :
                    // Список продуктів active
                    props.activeItem_2.submenu.map((item, index) => <ProductsListItem key={index} closeMobile = {props.closeMobile} item={item} />)
            }
        </ul>
    );
};

export default ProductsList;