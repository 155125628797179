import React from "react";
import css from "./Contacts.module.scss";
import TitleContact from "./TitleContact/TitleContact";
import ContactsList from "./ContactsList/ContactsList";

const Contacts = ({ contacts }) => {



  return (
    <section className={css["contactsWrapper"]}>
      <TitleContact />

        <ContactsList contacts={contacts} />
   
    </section>
  );
};

export default Contacts;
