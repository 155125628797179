const lecturesLaboratory = [
  {
    nameLocation: "Радехівський підрозділ",

    lectureSchedule: {
      title: "Графік проведення лекцій",
      byDate: [
        {
          date: "14.08.2023",
          byTime: [
            {
              time: "10:00 — 14:00",
              description:
                "Робота сировинної лабораторії. Охорона праці для лаборанта бурякопункту та лаборанта пробовідбірника.",
              lecturer: "Фалендиш І.А.",
            },

            {
              time: "14:00 — 17:00",
              description:
                "Забезпечення спецодягом та ЗІЗ. Види інструктажів з ОП. Картки ризиків. Правила надання домедичної допомоги. Правила пожежної безпеки. Техногенна безпека.",
              lecturer: "Домитраш П.В.",
            },
          ],
        },
      ],
    },

    examSchedule: {
      title: "Графік складання іспитів",
      byDate: [
        {
          date: "14.08.2023",
          byTime: [
            {
              time: "10:00 — 14:00",
              description: "",
              lecturer: "",
            },
            {
              time: "15:00 — 16:00",
              description: "",
              lecturer: "",
            },

          ],
        },
      ],
    },

    
  },
  {
    nameLocation: "Чортківський підрозділ",
    lectureSchedule: {
      title: "Графік проведення лекцій",
      byDate: [
        {
          date: "14.08.2023",
          byTime: [
            {
              time: "10:00 — 14:00",
              description:
                "Робота сировинної лабораторії.",
              lecturer: "Фалендиш І.А.",
            },

            {
              time: "14:00 — 17:00",
              description:
                "Забезпечення спецодягом та ЗІЗ. Види інструктажів з ОП. Картки ризиків. Правила надання домедичної допомоги. Правила пожежної безпеки. Техногенна безпека.",
              lecturer: "Домитраш П.В.",
            },
          ],
        },
      ],
    },

    examSchedule: {
      title: "Графік складання іспитів",
      byDate: [
        {
          date: "14.08.2023",
          byTime: [
            {
              time: "10:00 — 14:00",
              description: "",
              lecturer: "",
            },
            {
              time: "11:00 — 12:00",
              description: "",
              lecturer: "",
            },

            {
              time: "12:00 — 13:00",
              description: "",
              lecturer: "",
            },


          ],
        },
      ],
    },

    
  },
  {
    nameLocation: "Хоростківський підрозділ",
    lectureSchedule: {
      title: "Графік проведення лекцій",
      byDate: [
        {
          date: "14.08.2023",
          byTime: [
            {
              time: "10:00 — 14:00",
              description:
                "Робота сировинної лабораторії. Охорона праці для лаборанта бурякопункту та лаборанта пробовідбірника.",
              lecturer: "Фалендиш І.А.",
            },

            {
              time: "14:00 — 17:00",
              description:
                "Забезпечення спецодягом та ЗІЗ. Види інструктажів з ОП. Картки ризиків. Правила надання домедичної допомоги. Правила пожежної безпеки. Техногенна безпека.",
              lecturer: "Домитраш П.В.",
            },
            {
              time: "15:00 — 16:00",
              description:
                "Робота .",
              lecturer: "Фалендиш І.А.",
            },

            {
              time: "17:00 — 18:00",
              description:
                " Види інструктажів з ОП. Картки ризиків. Правила надання домедичної допомоги. Правила пожежної безпеки.",
              lecturer: "Домитраш П.В.",
            },
          ],
        },
      ],
    },

    examSchedule: {
      title: "Графік складання іспитів",
      byDate: [
        {
          date: "14.08.2023",
          byTime: [
            {
              time: "10:00 — 14:00",
              description: "",
              lecturer: "",
            },
            {
              time: "11:00 — 15:00",
              description: "",
              lecturer: "",
            },
            {
              time: "12:00 — 16:00",
              description: "",
              lecturer: "",
            },
            {
              time: "15:00 — 18:00",
              description: "",
              lecturer: "",
            },

          ],
        },
      ],
    },

    
  },
  {
    nameLocation: "Козівський підрозділ",
    lectureSchedule: {
      title: "Графік проведення лекцій",
      byDate: [
        {
          date: "14.08.2023",
          byTime: [
            {
              time: "10:00 — 14:00",
              description:
                "Робота сировинної.",
              lecturer: "Фалендиш І.А.",
            },

          ],
        },
      ],
    },

    examSchedule: {
      title: "Графік складання іспитів",
      byDate: [
        {
          date: "14.08.2023",
          byTime: [
            {
              time: "10:00 — 14:00",
              description: "",
              lecturer: "",
            },
            {
              time: "10:00 — 14:00",
              description: "Види інструктажів з ОП. Картки ризиків. Правила надання домедичної допомоги. Правила пожежної безпеки.",
              lecturer: "",
            },

          ],
        },
      ],
    },

    
  },
  {
    nameLocation: "Збаразький підрозділ",
    lectureSchedule: {
      title: "Графік проведення лекцій",
      byDate: [
        {
          date: "14.08.2023",
          byTime: [
            {
              time: "10:00 — 14:00",
              description:
                "Робота сировинної лабораторії. Охорона праці для лаборанта бурякопункту та лаборанта пробовідбірника.",
              lecturer: "Фалендиш І.А.",
            },

            {
              time: "14:00 — 17:00",
              description:
                "Забезпечення спецодягом та ЗІЗ. Види інструктажів з ОП. Картки ризиків. Правила надання домедичної допомоги. Правила пожежної безпеки. Техногенна безпека.",
              lecturer: "Домитраш П.В.",
            },
          ],
        },
      ],
    },

    examSchedule: {
      title: "Графік складання іспитів",
      byDate: [
        {
          date: "14.08.2023",
          byTime: [
            {
              time: "10:00 — 14:00",
              description: "",
              lecturer: "",
            },

          ],
        },
      ],
    },

    
  },
];

const allLecturesLaboratory = {
  lecturesLaboratory,
};

export default allLecturesLaboratory;
