import React from "react";
import Title from "../../components/Title/Title";
import  "./soil.scss";
import CalculateSoil from "../../components/Soil/CalculateSoil/CalculateSoil";
import TableTemplate from "../../components/Soil/Table/TableTemplate";
import { useTranslation } from "react-multi-lang";

const Soil = () => {
  const translate = useTranslation();

  const soilTable = {
    title: translate('soil.soilTable1.title'),
    title1: translate('soil.soilTable1.title1'),
    title2: translate('soil.soilTable1.title2'),
    title3: translate('soil.soilTable1.title3'),
    rows: [
      {
        cols: [
          "",
          translate('soil.soilTable1.cols.colTitl1'),
          translate('soil.soilTable1.cols.colTitl2'),
          translate('soil.soilTable1.cols.colTitl3'),
          translate('soil.soilTable1.cols.colTitl1'),
          translate('soil.soilTable1.cols.colTitl2'),
          translate('soil.soilTable1.cols.colTitl3'),
          translate('soil.soilTable1.cols.colTitl1'),
          translate('soil.soilTable1.cols.colTitl2'),
          translate('soil.soilTable1.cols.colTitl3'),
        ],
      },
      {
        cols: [
          translate('soil.soilTable1.cols.subT1'),
          "< 4,5",
          "3,5",
          "11,7",
          "< 5,0",
          "4,5",
          "15,0",
          "< 5,5",
          "5,0",
          "16,7",
        ],
      },
      {
        cols: [
          translate('soil.soilTable1.cols.subT2'),
          "4,6 — 5,0",
          "2,5",
          "8,3",
          "5,1 — 5,5",
          "3,0",
          "10,0",
          "5,6 — 6,0",
          "3,0",
          "10,0",
        ],
      },
      {
        cols: [
          translate('soil.soilTable1.cols.subT3'),
          "5,1 — 5,5",
          "1,5",
          "5,0",
          "5,6 — 6,0",
          "1,7",
          "5,7",
          "6,1 — 6,5",
          "2,0",
          "6,7",
        ],
      },
      {
        cols: [
          translate('soil.soilTable1.cols.subT4'),
          "5,6 — 6,0",
          "0,0",
          "0,0",
          "6,1 — 6,5",
          "1,0",
          "3,3",
          "6,6 — 7,0",
          "1,0",
          "3,3",
        ],
      },
      {
        cols: [
          translate('soil.soilTable1.cols.subT5'),
          "> 6,1",
          "0,0",
          "0,0",
          "> 6,6",
          "0,0",
          "0,0",
          "> 7,0",
          "0,0",
          "0,0",
        ],
      },
    ],
  };

  function residuePos(str) {
    const array = str.split(" ");
    return array.slice(1, array.length).join(" ");
  }

  return (
    <section className={"soil"}>
      <div className="container">
        <div className={"soil__descr"} dangerouslySetInnerHTML={{__html: translate('soil.topDescr')}}>
        </div>

        <div className={"soil__vapn"} id="table-block1">
          <Title num1={"01"} num2={"01"} row1={translate('soil.title1').split(' ')[0]} row2={residuePos(translate('soil.title1'))} />
          <h3>
            {translate('soil.sub1')}
          </h3>
          <a href="#table-block1" className={"table-link"}>
          {translate('table')} №1
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="26"
              height="8"
              viewBox="0 0 26 8"
              fill="none"
            >
              <path
                d="M0.646447 4.35355C0.451184 4.15829 0.451184 3.84171 0.646447 3.64645L3.82843 0.464466C4.02369 0.269204 4.34027 0.269204 4.53553 0.464466C4.7308 0.659728 4.7308 0.976311 4.53553 1.17157L1.70711 4L4.53553 6.82843C4.7308 7.02369 4.7308 7.34027 4.53553 7.53553C4.34027 7.7308 4.02369 7.7308 3.82843 7.53553L0.646447 4.35355ZM25 3.5C25.2761 3.5 25.5 3.72386 25.5 4C25.5 4.27614 25.2761 4.5 25 4.5V3.5ZM1 3.5L25 3.5V4.5L1 4.5L1 3.5Z"
                fill="#C2C7CC"
              />
            </svg>
          </a>

          <div className={"table__inner"}>
            <TableTemplate tableData={soilTable} />
          </div>

          <div className={"table__description"}>
            <div className={"table__description-item"}>
              <p>
                01<span className={"light"}> {translate('soil.soilTable1.title1')}</span>
              </p>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="26"
                height="8"
                viewBox="0 0 26 8"
                fill="none"
              >
                <path
                  d="M25.3536 4.35355C25.5488 4.15829 25.5488 3.84171 25.3536 3.64645L22.1716 0.464466C21.9763 0.269204 21.6597 0.269204 21.4645 
                            0.464466C21.2692 0.659728 21.2692 0.976311 21.4645 1.17157L24.2929 4L21.4645 6.82843C21.2692 7.02369 21.2692 7.34027 21.4645 7.53553C21.6597 
                            7.7308 21.9763 7.7308 22.1716 7.53553L25.3536 4.35355ZM1 3.5C0.723858 3.5 0.5 3.72386 0.5 4C0.5 4.27614 0.723858 4.5 1 4.5V3.5ZM25 3.5L1 
                            3.5V4.5L25 4.5V3.5Z"
                  fill="#C2C7CC"
                />
              </svg>
              <p>
              {translate('soil.grunts.title')}
                <span className={"gray"}>(0 &#60; 0,02 {translate('soil.grunts.measur')})</span>
                <span className={"light"}> 11 — 20%</span>
              </p>
            </div>
            <div className={"table__description-item"}>
              <p>
                02<span className={"middle"}> {translate('soil.soilTable1.title2')}</span>
              </p>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="26"
                height="8"
                viewBox="0 0 26 8"
                fill="none"
              >
                <path
                  d="M25.3536 4.35355C25.5488 4.15829 25.5488 3.84171 25.3536 3.64645L22.1716 0.464466C21.9763 0.269204 21.6597 0.269204 21.4645 
                            0.464466C21.2692 0.659728 21.2692 0.976311 21.4645 1.17157L24.2929 4L21.4645 6.82843C21.2692 7.02369 21.2692 7.34027 21.4645 7.53553C21.6597 
                            7.7308 21.9763 7.7308 22.1716 7.53553L25.3536 4.35355ZM1 3.5C0.723858 3.5 0.5 3.72386 0.5 4C0.5 4.27614 0.723858 4.5 1 4.5V3.5ZM25 3.5L1 
                            3.5V4.5L25 4.5V3.5Z"
                  fill="#C2C7CC"
                />
              </svg>
              <p>
              {translate('soil.grunts.title')}
                <span className={"gray"}>(0 &#60; 0,02 {translate('soil.grunts.measur')})</span>
                <span className={"middle"}> 21 — 35%</span>
              </p>
            </div>
            <div className={"table__description-item"}>
              <p>
                03<span className={"hard"}> {translate('soil.soilTable1.title3')}</span>
              </p>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="26"
                height="8"
                viewBox="0 0 26 8"
                fill="none"
              >
                <path
                  d="M25.3536 4.35355C25.5488 4.15829 25.5488 3.84171 25.3536 3.64645L22.1716 0.464466C21.9763 0.269204 21.6597 0.269204 21.4645 
                            0.464466C21.2692 0.659728 21.2692 0.976311 21.4645 1.17157L24.2929 4L21.4645 6.82843C21.2692 7.02369 21.2692 7.34027 21.4645 7.53553C21.6597 
                            7.7308 21.9763 7.7308 22.1716 7.53553L25.3536 4.35355ZM1 3.5C0.723858 3.5 0.5 3.72386 0.5 4C0.5 4.27614 0.723858 4.5 1 4.5V3.5ZM25 3.5L1 
                            3.5V4.5L25 4.5V3.5Z"
                  fill="#C2C7CC"
                />
              </svg>
              <p>
              {translate('soil.grunts.title')}
                <span className={"gray"}>(0 &#60; 0,02 {translate('soil.grunts.measur')})</span>
                <span className={"hard"}> &#62; 35%</span>
              </p>
            </div>
          </div>
          <CalculateSoil />
        </div>
        <div className={"soil__prepare"} id="table-block2">
          <Title num1={"02"} num2={"02"} row1={translate('soil.title2').split(' ')[0]} row2={residuePos(translate('soil.title2'))} />
          <h3>
            {translate('soil.sub2')}
          </h3>
          <a href="#table-block2" className={"table-link"}>
            {translate('table')} №2
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="26"
              height="8"
              viewBox="0 0 26 8"
              fill="none"
            >
              <path
                d="M0.646447 4.35355C0.451184 4.15829 0.451184 3.84171 0.646447 3.64645L3.82843 0.464466C4.02369 0.269204 4.34027 0.269204 4.53553 0.464466C4.7308 0.659728 4.7308 0.976311 4.53553 1.17157L1.70711 4L4.53553 6.82843C4.7308 7.02369 4.7308 7.34027 4.53553 7.53553C4.34027 7.7308 4.02369 7.7308 3.82843 7.53553L0.646447 4.35355ZM25 3.5C25.2761 3.5 25.5 3.72386 25.5 4C25.5 4.27614 25.2761 4.5 25 4.5V3.5ZM1 3.5L25 3.5V4.5L1 4.5L1 3.5Z"
                fill="#C2C7CC"
              />
            </svg>
          </a>

          <div className={"soil__prepare-table"}>
            <div className={"soil__prepare-table-item"}>
              <p>≥ 60 {translate('soil.soilTable2.depth.measur')}</p>
              <p>{translate('soil.soilTable2.depth.title')}</p>
            </div>
            <div className={"soil__prepare-table-item"}>
              <p>1,5 — 6,0 %</p>
              <p>{translate('soil.soilTable2.gumus.title')}</p>
            </div>
            <div className={"soil__prepare-table-item"}>
              <p>1,0 — 1,35 {translate('soil.soilTable2.density.measur')}</p>
              <p>{translate('soil.soilTable2.density.title')}</p>
            </div>
            <div className={"soil__prepare-table-item"}>
              <p>6,5 — 7,3 {translate('soil.soilTable2.ph.measur')}</p>
              <p>{translate('soil.soilTable2.ph.title')}</p>
            </div>
          </div>
          <div className={"soil__descr"}>
            <p dangerouslySetInnerHTML={{__html: translate('soil.bottomDescr.text1')}}>
            </p>
            <p dangerouslySetInnerHTML={{__html: translate('soil.bottomDescr.text2')}}>
            </p>
            <p dangerouslySetInnerHTML={{__html: translate('soil.bottomDescr.text3')}}>
            </p>
            <p dangerouslySetInnerHTML={{__html: translate('soil.bottomDescr.text4')}}>
            </p>
            <p dangerouslySetInnerHTML={{__html: translate('soil.bottomDescr.text5')}}>
            </p>
            <p dangerouslySetInnerHTML={{__html: translate('soil.bottomDescr.text6')}}>
            </p>
            <p dangerouslySetInnerHTML={{__html: translate('soil.bottomDescr.text7')}}>
            </p>
            <p dangerouslySetInnerHTML={{__html: translate('soil.bottomDescr.text8')}}>
            </p>
            <p dangerouslySetInnerHTML={{__html: translate('soil.bottomDescr.text9')}}>
            </p>
            <p dangerouslySetInnerHTML={{__html: translate('soil.bottomDescr.text10')}}>
            </p>
            <p dangerouslySetInnerHTML={{__html: translate('soil.bottomDescr.text11')}}>
            </p>
            <p dangerouslySetInnerHTML={{__html: translate('soil.bottomDescr.text12')}}>
            </p>           
            <p dangerouslySetInnerHTML={{__html: translate('soil.bottomDescr.text13')}}>
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Soil;
