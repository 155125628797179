import React, { useEffect, useState } from 'react';
import DropdownItem_2 from './DropdownItem_2';

// Список другого рівня
const Dropdown_2 = (props) => {
    // console.log('props Dropdown_2', props);

    // Активний пункт меню другого рівня
    const [activeItem_2, setActiveItem_2] = useState([]);

    // При обнуленні активної вкладки меню першого рівня - обнулити активний пункт меню другого рівня
    useEffect(() => {
        if (props.activeItem_1 === false) {
            setActiveItem_2([]);
        }
    }, [props.activeItem_1]);

    return (
        <ul className={`menu__list-three ${props.activeItem_1.title === props.item.title ? 'active' : ''}`}>
            {
                (props.item !== undefined && props.item.submenu !== undefined) &&
                props.item.submenu.map((item) => <DropdownItem_2 key={item.title} closeMobile = {props.closeMobile} item={item} activeItem_2={activeItem_2} setActiveItem_2={setActiveItem_2} />)
            }
        </ul>
    );
};

export default Dropdown_2;