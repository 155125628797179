// footer це json перекладів на інші мови передаєм стрінгу

const ourServices = [
    {
        number: '01',
        // title: 'КЛІЄНТАМ',
        title: 'footer.ourServices.services01.title',
        arrServices: [
            {
                // name: 'Цукор',
                name: 'footer.ourServices.services01.name1',
                path: 'https://diamant-sugar.com.ua',
                target: '_blank'
            },
            {
                // name: 'Жом',
                name: 'footer.ourServices.services01.name2',
                path: 'https://radocorm.com.ua/',
                target: '_blank'
            },
        ],
        path: '',
    },
    {
        number: '02',
        // title: 'ПРАЦІВНИКАМ',
        title: 'footer.ourServices.services02.title',
        arrServices: [
            {
                // name: 'Навчання',
                name: 'footer.ourServices.services02.name1',
                path: '/study',
            },
            {
                // name: 'Вакансії',
                name: 'footer.ourServices.services02.name2',
                path: '/vacancies',
            },
            {
                // name: 'Кращі працівники',
                name: 'footer.ourServices.services02.name3',
                path: '/best-employees',
            },
        ],
        path: '',
    },
    {
        number: '03',
        // title: 'ФЕРМЕРАМ',
        title: 'footer.ourServices.services03.title',
        arrServices: [
            {
                // name: 'Насіння',
                name: 'footer.ourServices.services03.name1',
                path: '/seed',
            },
            {
                // name: 'Технологія вирощування',
                name: 'footer.ourServices.services03.name2',
                path: '/technology-beet',
            },
            {
                // name: 'Збір та відвантаження',
                name: 'footer.ourServices.services03.name3',
                path: '/shipment',
            },
        ],
        path: '',
    },
    {
        number: '04',
        // title: 'ВОДІЯМ',
        title: 'footer.ourServices.services04.title',
        arrServices: [
            {
                // name: 'Логістика',
                name: 'footer.ourServices.services04.name1',
                path: '/logistics',
            },
        ],
        path: '',
    },
];

const infoAboutUs = [
    {
        // title: 'Компанія',
        title: 'footer.infoAboutUs.name1',
        path: '/history',
        imageURL: '',
    },
 
    {
        // title: 'Новини',
        title: 'footer.infoAboutUs.name2',
        path: '/news',
        imageURL: '',
    },
    {
        // title: 'Кодекс етики',
        title: 'footer.infoAboutUs.name3',
        path: '/kodeks_etyky_ua.pdf',
        imageURL: '',
        target: '_blank',
    },
];

const communication = [
    {
        // title: 'Запитати',
        title: 'footer.communication.name1',
        path: '/question',
        imageURL: '/media/images/icons/answer.svg',
    },
    {
        // title: 'Соціальна активність',
        title: 'footer.communication.name2',
        path: '/social-activity',
        imageURL: '/media/images/icons/socialActivity.svg',
    },

];

const contacts = [
    {
        // title:'Радехівський',
        title:'footer.contacts.radekhiv',
        location: 'https://goo.gl/maps/wM6hG7eNxJfyF4jD9',
        email: 'office.p@radsugar.com.ua', 
        emailDecor: ['office.p','@radsugar.com.ua'],
        phone: '380325541110',
        phoneDecor: ['+38',' 032', ' 554-11-10'],
    },
    {
        // title:'Чортківський',
        title:'footer.contacts.chortkiv',
        location: 'https://goo.gl/maps/HYQY8eqzQq6omuEc6',
        email: 'office.h@radsugar.com.ua',
        emailDecor: ['office.ch','@radsugar.com.ua'],
        phone: '380509111010',
        phoneDecor: ['+38',' 050', ' 911-10-10'],
    },
    {
        // title:'Збаразький',
        title:'footer.contacts.zbarazh',
        location: 'https://goo.gl/maps/JW2dy76Ww5ZxrTqA6',
        email: 'tutska.t@radsugar.com.ua',
        emailDecor: ['office.z','@radsugar.com.ua'],
        phone: '',
        phoneDecor: [],
    },
    {
        // title:'Козівський',
        title:'footer.contacts.kozova',
        location: 'https://goo.gl/maps/U1mFzgT3rcDMVCP17',
        email: 'tutska.t@radsugar.com.ua',
        emailDecor: ['office.a','@radsugar.com.ua'],
        phone: '',
        phoneDecor: [],
    },
    {
        // title:'Хоростківський',
        title:'footer.contacts.khorostkiv',
        location: 'https://goo.gl/maps/chXRR1i3CndBoQnm7',
        email: 'office.h@radsugar.com.ua',
        emailDecor: ['office.h','@radsugar.com.ua'],
        phone: '',
        phoneDecor: [],
    },
    {
        // title:'Гнідавський',
        title:'footer.contacts.hnidava',
        location: 'https://maps.app.goo.gl/qMbKZaA6dgdfX9LP8',
        email: 'office.g@radsugar.com.ua',
        emailDecor: ['office.g','@radsugar.com.ua'],
        phone: '',
        phoneDecor: [],
    },
];

const partners = [
 
    {
        title: 'IT Maksimum',
        imageURL: '/media/images/partners/maximum.png',
        linkURL: 'https://itmaximum.com.ua/',
    },
    {
        title: 'ClEANING сервіс',
        imageURL: '/media/images/partners/clean.png',
        linkURL: 'https://www.instagram.com/cleaning.service.chg?igsh=YzYxZmsycTJtemQ5',
    },
    {
        title: 'Aгрологістика',
        imageURL: '/media/images/partners/agrologistik.svg',
        linkURL: 'https://agrologistics.com.ua/',
    },
    {
        title: 'Західний буг',
        imageURL: '/media/images/partners/westBug.png',
        linkURL: 'https://zahbug.com/',
    },
    {
        title: 'Milkriver',
        imageURL: '/media/images/partners/milkriver.png',
        linkURL: '#',
    },
    {
        title: 'Citadel',
        imageURL: '/media/images/partners/citadel.png',
        linkURL: 'https://citadel-llc.com.ua/ua/',
    },
    {
        title: 'Галицька правнича група',
        imageURL: '/media/images/partners/galych.png',
        linkURL: 'https://galpravgroup.com.ua/',
    },
    {
        title: 'IT Maksimum',
        imageURL: '/media/images/partners/maximum.png',
        linkURL: 'https://itmaximum.com.ua/',
    },
    {
        title: 'ClEANING сервіс',
        imageURL: '/media/images/partners/clean.png',
        linkURL: 'https://www.instagram.com/cleaning.service.chg?igsh=YzYxZmsycTJtemQ5',
    },
    {
        title: 'Aгрологістика',
        imageURL: '/media/images/partners/agrologistik.svg',
        linkURL: 'https://agrologistics.com.ua/',
    },
    {
        title: 'Західний буг',
        imageURL: '/media/images/partners/westBug.png',
        linkURL: 'https://zahbug.com/',
    },
    {
        title: 'Milkriver',
        imageURL: '/media/images/partners/milkriver.png',
        linkURL: '#',
    },
    {
        title: 'Citadel',
        imageURL: '/media/images/partners/citadel.png',
        linkURL: 'https://citadel-llc.com.ua/ua/',
    },
    {
        title: 'Галицька правнича група',
        imageURL: '/media/images/partners/galych.png',
        linkURL: 'https://galpravgroup.com.ua/',
    },
    {
        title: 'IT Maksimum',
        imageURL: '/media/images/partners/maximum.png',
        linkURL: 'https://itmaximum.com.ua/',
    },
    {
        title: 'ClEANING сервіс',
        imageURL: '/media/images/partners/clean.png',
        linkURL: 'https://www.instagram.com/cleaning.service.chg?igsh=YzYxZmsycTJtemQ5',
    },
    {
        title: 'Aгрологістика',
        imageURL: '/media/images/partners/agrologistik.svg',
        linkURL: 'https://agrologistics.com.ua/',
    },
    {
        title: 'Західний буг',
        imageURL: '/media/images/partners/westBug.png',
        linkURL: 'https://zahbug.com/',
    },
    {
        title: 'Milkriver',
        imageURL: '/media/images/partners/milkriver.png',
        linkURL: '#',
    },
    {
        title: 'Citadel',
        imageURL: '/media/images/partners/citadel.png',
        linkURL: 'https://citadel-llc.com.ua/ua/',
    },
    {
        title: 'Галицька правнича група',
        imageURL: '/media/images/partners/galych.png',
        linkURL: 'https://galpravgroup.com.ua/',
    },
    {
        title: 'IT Maksimum',
        imageURL: '/media/images/partners/maximum.png',
        linkURL: 'https://itmaximum.com.ua/',
    },
    {
        title: 'ClEANING сервіс',
        imageURL: '/media/images/partners/clean.png',
        linkURL: 'https://www.instagram.com/cleaning.service.chg?igsh=YzYxZmsycTJtemQ5',
    },
];

const allDataFooter = {
     ourServices,
     infoAboutUs,
     communication,
     contacts,
     partners,
};

export default allDataFooter;