import React, { useEffect, useRef, useState } from "react";
import NavbarItem from "./NavbarItem";
import useOutsideClick from "../Store/useOutsideClick";
import { useLocation } from "react-router-dom";
import ProductList from "../ProductList/ProductList";

const Navbar2 = (props) => {
  // console.log("props", props);
  // Посилання на елемент <ul> dropdown
  const dropdownRef = useRef(null);
  // Взяти url
  const location = useLocation();

  //   useEffect(() => {
  //     console.log("productList11111111", props.productList);
  //   }, [props.productList]);

  // При зміні url закрити dropdown
  useEffect(() => {
    props.setActiveItem(false);
  }, [location.pathname]);

  // Функція для закривання dropdown при кліку поза елементом <ul>
  const closeDropdown = () => {
    props.setActiveItem(false);
  };

  // Клік поза елементом  <ul className="menu__list"... - закрити dropdown / тільки для desktop

  useOutsideClick(dropdownRef, closeDropdown, props.activeItem);

  return (
    <nav className="main__menu menu">
      <ul className="menu__list" ref={dropdownRef}>
        {props.menuItems != undefined &&
          props.menuItems.map((item, index) => {
            // console.log("item", item);
            return (     
                // {/* {item.path === "/clients" ? ( */}
                // {/* <ProductList productArr={props.productList} /> */}
                // {/* ) : ( */}
                <NavbarItem
                  key={item.title}
                  item={{
                    ...item,
                    title: props.translate(
                      "header.title" + (index + 1) + ".name"
                    ),
                  }}
                  productArr={props.productList}
                  activeItem={props.activeItem}
                  setActiveItem={props.setActiveItem}
                  closeMobile={props.closeMobile}
                  translate={props.translate}
                  parentIndex={index + 1}
                />         
            );
          })}
      </ul>
    </nav>
  );
};

export default Navbar2;
