import { NavLink } from "react-router-dom";
import 'react-popupify/dist/bundle.css'
import "./Study.scss";
import { useTranslation } from "react-multi-lang";
import { useEffect, useRef, useState } from "react";
import { getEventId } from "../../axios/study";
import { Loader } from "../../components/Loader/Loader";


export const Study = () => {
  const t = useTranslation();
  const refStudy = useRef(null);
  const [eventList, setEventList] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    refStudy.current.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });
  }, [])

  useEffect(() => {
    let active = false;
    setLoading(true);
    getEventId('').then(response => {
      const data = response['hydra:member'];
      const showEvent = data.filter(event => event.show); // показати тільки активні події

      if (!active) {
        setEventList(showEvent);
      }
    }).finally(()=>{
      setLoading(false);
    })

    return () => {
      active = true;
    };
  }, []);

  const showCurrentYear = () => {
    const currentYear = new Date().getFullYear();
    return currentYear;
  };

  return (
    <>
      <section className="study" ref={refStudy}>
        <div className="container">
          <div className="study__top">
            <div className="study__top-title">
              <div className="study__top-title--year">{showCurrentYear()}</div>
              <div className="study__top-title--text">
                {t("studyMain.title")}
              </div>
            </div>
          </div>
          <div className="study__content">
            {loading &&
              <div className="loader-study"> 
                <Loader/>
              </div>}
            {eventList && eventList.map((event, index) => {
              // const count = index + 1;
              return <NavLink
                key={event.id}
                exact
                to={`/study/${event.id}`}
                className="study__content-card"
              >
                {/* <div className="study__content-card--number">{count < 10 ? `0${count}` : count}</div> */}
                {/* <div className="study__content-card--line"></div> */}
                <div className="study__content-card--text">
                  <div>
                    {event.name}
                  </div>
                </div>
              </NavLink>
            })}
            {/* <NavLink
              exact
              to="/study/events"
              className="study__content-card"
            >
              <div className="study__content-card--number">01</div>
              <div className="study__content-card--line"></div>
              <div className="study__content-card--text">
                <div
                  dangerouslySetInnerHTML={{ __html: t("studyMain.text1") }}
                />
              </div>
            </NavLink>
            <NavLink to="https://diamantsugar.com.ua/ua/event/view/11" target="__blank" className="study__content-card">
              <div className="study__content-card--number">02</div>
              <div className="study__content-card--line"></div>
              <div className="study__content-card--text">
                <div
                  dangerouslySetInnerHTML={{ __html: t("studyMain.text2") }}
                />
              </div>
            </NavLink> */}
          </div>
        </div>
      </section>
    </>
  );
};
