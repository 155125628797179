import React, { useEffect, useRef, useState } from "react";
import Contacts from "../views/contacts/Contacts";
import SocialActivityHead from "../views/socialActivity/SocialActivityHead";
import DetailsHeader from "../views/LectureDetails/DetailsHeader/DetailsHeader";
// import ShebuleHead from "../views/lectureSchedule/SchebuleHead";
import SchebuleHead from "../views/lectureSchedule/SchebuleHead/SchebuleHead";
// import BestEmployersHeader from "../views/bestEmployees/BestEmployersHeader";
// import LogisticsHeader from "../views/Logistics/LogisticsHeader";
// import ShipmentHeader from "../views/shipment/ShipmentHeader";
import { HeadSlider } from "./HeadSlider";
import Product from "./CardProduct/Product";
import StaticProduct from "./CardProduct/StaticProduct";
import { Question } from "../views/Question/Question";
import { useTranslation } from "react-multi-lang";
import PagesHead from "../views/pages/PagesHead";

export const HeadTop = ({ currentRoute, showMenu, lang }) => {
  const translate = useTranslation();
  const headTemplate = [
    {
      path: "/",
      slide: 0,
      slider: true,
    },
    {
      path: "/clients",
      slide: 1,
      slider: true,
    },
    {
      path: "/workers",
      slide: 2,
      slider: true,
    },
    {
      path: "/fermers",
      slide: 3,
      slider: true,
    },
    {
      path: "/drivers",
      slide: 4,
      slider: true,
    },
    {
      path: "/partners",
      slide: 5,
      slider: true,
    },
    {
      path: "/pages",
      content: "",
      component: <PagesHead />,
    },
    {
      path: "/seed",
      media: "/media/images/seed__head.png",
      content: translate("headTemplate.seed"),
      color: { r: "32", g: "41", b: "75", a: "0.7" },
    },
    {
      path: "/vacancies",
      media: "/media/images/vacancies__head.jpg",
      content: "",
      color: { r: "0", g: "0", b: "0", a: "0" },
    },
    {
      path: "/logistics",
      media: "/media/images/logistic__head.png",
      content: "",
      color: { r: "0", g: "0", b: "0", a: "0" },
      // media: "/media/images/logistic__head.png",
      // component: <LogisticsHeader/>,
    },
    {
      path: "/power",
      media: "/media/images/power__head.png",
      content: translate("headTemplate.power"),
      color: { r: "0", g: "0", b: "0", a: "0" },
    },
    {
      path: "/study",
      media: "/media/images/study__head.png",
      content: "",
      color: { r: "0", g: "0", b: "0", a: "0" },
    },
    {
      path: "/reports",
      media: "/media/images/reports/1.jpg",
      content: "",
      color: { r: "0", g: "0", b: "0", a: "0" },
    },
    // {
    //   path: "/study/events",
    //   media: "/media/images/study__head.png",
    //   content: "",
    //   color: { r: "0", g: "0", b: "0", a: "0" },
    // },
    {
      path: "/news",
      media: "/media/images/news__head.png",
      content: translate("headTemplate.news"),
      color: { r: "0", g: "0", b: "0", a: "0" },
    },
    {
      path: "/social-activity",
      media: "/media/images/sugar__head.png",
      component: <SocialActivityHead />,
    },
    {
      path: "/crop-care",
      media: "/media/images/crop-care__head.png",
      content: "",
      color: { r: "26", g: "35", b: "50", a: "0.7" },
    },
    {
      path: "/best-employees",
      media: "/media/images/bestEmployees__head.jpg",
      content: "",
      color: { r: "0", g: "0", b: "0", a: "0" },
      // component: <BestEmployersHeader/>
    },
    {
      path: "/shipment",
      media: "/media/images/shipment__head.jpg",
      content: translate("headTemplate.shipment"),
      color: { r: "11", g: "98", b: "67", a: "0.7" },
      //  component: <ShipmentHeader/>,
    },

    {
      path: "/lecture-schedule",
      media: "/media/images/bg_main.jpg",
      component: <SchebuleHead />,
      // component: <DetailsHeader />,
    },

    {
      path: "/lecture-schedule/:id",
      media: "/media/images/bg_main.jpg",
      component: <DetailsHeader />,
    },

    {
      path: "/lectures-laboratory",
      media: "/media/images/bg_main.jpg",
      content: "Навчання працівників СИРОВИННОЇ ЛАБОРАТОРІЇ",
      color: { r: "0", g: "0", b: "0", a: "0" },
    },
    {
      path: "/soil",
      media: "/media/images/soil__head.png",
      content: "",
      color: { r: "55", g: "59", b: "69", a: "0.7" },
    },

    {
      path: "/contacts",
      // media: '/media/images/bg_main.jpg',
      content: "",
      component: <Contacts />,
    },
    {
      path: "/sowing",
      media: "/media/images/sowing__head.png",
      content: "",
      color: { r: "41", g: "42", b: "46", a: "0.7" },
    },
    {
      path: "/history",
      media: "/media/images/history__head.svg",
      content: "",
      color: { r: "0", g: "0", b: "0", a: "0" },
    },

    {
      path: "/question",
      // media: "/media/images/history__head.svg",
      component: <Question />,
    },

    {
      path: "/technology-beet",
      media: "/media/images/history__head.jpg",
      content: translate("headTemplate.technology-beet"),
      color: { r: "19", g: "109", b: "49", a: "0.7" },
    },

    {
      path: "/clients/sugar/:id",
      component: <Product lang={lang} />,
    },
    {
      path: "/clients/melyasa",
      component: <StaticProduct type={"Меляса"} lang={lang} />,
    },
    {
      path: "/clients/zhom",
      component: <StaticProduct type={"Жом"} lang={lang} />,
    },
    {
      path: "/energy",
      media: "/media/images/energy__head.png",
      content: translate("headTemplate.energy"),
      color: { r: "0", g: "0", b: "0", a: "0" },
    },
    {
      path: "/energy/supply",
      media: "/media/images/supply__head.png",
      content: "",
      color: { r: "0", g: "0", b: "0", a: "0" },
    },
    {
      path: "/energy/production",
      media: "/media/images/energy__production.png",
      content: "",
      color: { r: "0", g: "0", b: "0", a: "0" },
    },
    {
      path: "/energy/gas",
      media: "/media/images/gas__supply.png",
      content: "",
      color: { r: "0", g: "0", b: "0", a: "0" },
    },
  ];
  const videoRef = useRef();
  const [isApple, setIsApple] = useState(false);

  const [pageName, setPageName] = useState(
    currentRoute !== undefined && currentRoute.name !== undefined
      ? currentRoute.name
      : ""
  );

  const currentContent =
    currentRoute !== undefined
      ? headTemplate.filter((head) => head.path.includes(currentRoute.path))
      : undefined;
  const currentSlide =
    currentRoute !== undefined
      ? headTemplate.find(
          (head) => head.slider && head.path.includes(currentRoute.path)
        )
      : undefined;

  function getTitle(name) {
    switch (name) {
      case "routes.sugar":
        return "product";
      case "routes.melyasa":
        return "product";
      case "routes.zhom":
        return "product";

      case "routes.main":
        return "main";
      case "routes.clients":
        return "main";
      case "routes.workers":
        return "main";
      case "routes.fermers":
        return "main";
      case "routes.drivers":
        return "main";
      case "routes.partners":
        return "main";

      case "routes.question":
        return "question";
      case "routes.contacts":
        return "contacts";

      default:
        return "page";
    }
  }

  useEffect(() => {
    videoRef.current?.load();

    const appleExpression = /(Mac|iPod|iPad)/i;
    if (appleExpression.test(navigator.platform)) {
      setIsApple(true);
    } else {
      setIsApple(false);
    }

    if (currentRoute !== undefined && currentRoute.name !== undefined) {
      setPageName(currentRoute.name);
    } else {
      setPageName("");
    }
  }, [currentRoute]);

  return (
    <>
      <div className={`main__inner ${getTitle(currentRoute.name)}`}>
        {currentSlide !== undefined ? (
          <>
            <img
              className="video-bg"
              src={
                !isApple ? "/media/images/bg_t.jpg" : "/media/images/bg_tr.jpg"
              }
              //src ={'/media/images/bg_t.jpg'}
              alt="head"
            />
            <HeadSlider slide={currentSlide.slide} showMenu={showMenu} />
          </>
        ) : (
          <div className="container">
            <div className="main__inner-pad"></div>
            <div className="main__inner-content">
              {currentContent[1]?.component || currentContent[0]?.component || (
                <>
                  <h1>
                    {translate(pageName)
                      .split(" ")
                      .map((name, index) => {
                        return (
                          <span
                            key={index}
                            style={{
                              padding: "9px 9px 0 9px",
                              borderRadius: "2px",
                            }}
                          >
                            {name}
                          </span>
                        );
                      })}
                  </h1>
                  {!!currentContent[0].content.length && (
                    <h4
                      dangerouslySetInnerHTML={{
                        __html: currentContent[0].content,
                      }}
                      style={{
                        backgroundColor: `rgba(${currentContent[0].color.r}, ${currentContent[0].color.g}, ${currentContent[0].color.b}, ${currentContent[0].color.a})`,
                        padding: "9px",
                        borderRadius: "2px",
                      }}
                    ></h4>
                  )}
                </>
              )}
            </div>
            <img
              src={currentContent[0]?.media || "/media/images/bg_main.jpg"}
              alt="head"
            />
          </div>
        )}
      </div>
    </>
  );
};
