import React from "react";
import { useParams } from "react-router";
import Container from "../../components/Container/Container";
import Schedule from "../../components/schedule/Schedule";
import allLectureSchedule from "../lectureSchedule/dataLectureSchedule/dataShipment";
import css from "./LectureDetails.module.scss"

const { lectureScheduleWithIds } = allLectureSchedule; // all obj

const LectureDetails = () => {
  const { id } = useParams();

  const foundObject = lectureScheduleWithIds.reduce((result, item) => {
    const direction = item.directions.find(
      (direction) => direction.id === id
    );
    if (direction) {
      result = direction;
    }
    return result;
  }, null);

  // Перевірка, чи знайдено об'єкт перед відображенням
  if (!foundObject) {
    return (
      <section>
        <Container>
          <p>Об'єкт з id {id} не знайдено, після перезавантаженя змінено id, потрібні сталі дані.</p>
        </Container>
      </section>
    );
  }

  return (
    <section className={css["wrapperDetails"]}>
      <Container >
        <Schedule objInfo={foundObject.lectureSchedule} />
      </Container>
    </section>
  );
};

export default LectureDetails;
