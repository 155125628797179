import { useState } from 'react';
import './Seed.scss';
import { Link } from 'react-router-dom';
import {  useTranslation } from "react-multi-lang";

export const Seed = () => {
  const t = useTranslation();
  const seed = [
    {
      number: "01",
      title: t('seed.text0'),
      docHref: 'https://api-diamant.inneti.net/media/6658bfc0d1dcc_Звіт по насінні за 2016 рік.xlsx',
      docTitle: t('seed.text0docTitle'),
      docHref2: 'https://api-diamant.inneti.net/media/6658bfd02582f_Звіт по насінні за 2017 рік.xlsx',
      docTitle2: t('seed.text0docTitle2'),
      docHref3: 'https://api-diamant.inneti.net/media/6658bfd773ae5_Звіт по насінні за 2018 рік.xlsx',
      docTitle3: t('seed.text0docTitle3'),
      docHref4: 'https://api-diamant.inneti.net/media/6658bfdda9205_Звіт по насінні за 2019 рік.xlsx',
      docTitle4: t('seed.text0docTitle4'),
      docHref5: 'https://api-diamant.inneti.net/media/6658bfe3842ba_Звіт по насінні за 2020 рік.xlsx',
      docTitle5: t('seed.text0docTitle5'),
      docHref6: 'https://api-diamant.inneti.net/media/6658bfeb34ea5_Звіт по насінні за 2021 рік.xlsx',
      docTitle6: t('seed.text0docTitle6'),
      docHref7: 'https://api-diamant.inneti.net/media/6658bff1689a2_Звіт по насінні за 2022 рік.xlsx',
      docTitle7: t('seed.text0docTitle7'),
      docHref8: 'https://api-diamant.inneti.net/media/6658bff92e892_Звіт по насінні за 2023 рік.xlsx',
      docTitle8: t('seed.text0docTitle8'),
      docHref9: 'https://api-diamant.inneti.net/media/6658c0014bd62_Зведений звіт по насінні за 2016-2023рр..xlsx',
      docTitle9: t('seed.text0docTitle9'),
    },
    {
      number: "02",
      title: t('seed.text1'),
      row1: <div dangerouslySetInnerHTML={{ __html: t('seed.text1row1')}} />,
      row2: <div dangerouslySetInnerHTML={{ __html: t('seed.text1row2')}} />,
      row3: <div dangerouslySetInnerHTML={{ __html: t('seed.text1row3')}} />,
      docHref: 'https://api-diamant.inneti.net/media/seeds/%D0%9A%D0%B0%D1%82%D0%B0%D0%BB%D0%BE%D0%B3%20Betaseed%202022.pdf',
      docTitle: t('seed.text1docTitle'),
    },
    {
      number: "03",
      title: t('seed.text2'),
      row1: t('seed.text2row1'),
      row2: t('seed.text2row2'),
      docHref: ' ',
      docTitle: ' ',
    },
    {
      number: "04",
      title: t('seed.text3'),
      row1: t('seed.text3row1'),
      row2: t('seed.text3row2'),
      row3: t('seed.text3row3'),
      docHref: 'https://api-diamant.inneti.net/media/seeds/%D0%9A%D0%B0%D1%82%D0%B0%D0%BB%D0%BE%D0%B3%20%D0%A8%D1%82%D1%80%D1%83%D0%B1%D0%B5%202023.pdf',
      docTitle: t('seed.text3docTitle'),
    },
    {
      number: "05",
      title: t('seed.text4'),
      row1: <div dangerouslySetInnerHTML={{ __html: t('seed.text4row1')}} />,
      row2: <div dangerouslySetInnerHTML={{ __html: t('seed.text4row2')}} />,
      row3: <div dangerouslySetInnerHTML={{ __html: t('seed.text4row3')}} />,
      row4: <div dangerouslySetInnerHTML={{ __html: t('seed.text4row4')}} />,
      row5: <div dangerouslySetInnerHTML={{ __html: t('seed.text4row5')}} />,
      docHref: 'https://api-diamant.inneti.net/media/seeds/%D0%9A%D0%B0%D1%82%D0%B0%D0%BB%D0%BE%D0%B3%20Hilleshog%202022.pdf',
      docTitle: t('seed.text4docTitle'),
      docHref2: 'https://api-diamant.inneti.net/media/seeds/%D0%9A%D0%B0%D1%82%D0%B0%D0%BB%D0%BE%D0%B3%20Maribo%202022.pdf',
      docTitle2: t('seed.text4docTitle2'),
      docHref3: 'https://api-diamant.inneti.net/media/seeds/%D0%9E%D0%BF%D0%B8%D1%81%D0%B8%20%D0%BD%D0%BE%D0%B2%D1%96%20%D0%B3%D1%96%D0%B1%D1%80%D0%B8%D0%B4%D0%B8%20%D0%A6%D0%91.pdf',
      docTitle3: t('seed.docTitle3'),
    },
    {
      number: "06",
      title: <div dangerouslySetInnerHTML={{ __html: t('seed.text5')}} />,
      row1: <div dangerouslySetInnerHTML={{ __html: t('seed.text5row1')}} />,
      row2: <div dangerouslySetInnerHTML={{ __html: t('seed.text5row2')}} />,
      docHref: 'https://api-diamant.inneti.net/media/seeds/%D0%9A%D0%B0%D1%82%D0%B0%D0%BB%D0%BE%D0%B3%20SESVANDERHAVE%202022.pdf',
      docTitle: <div dangerouslySetInnerHTML={{ __html: t('seed.text5docTitle')}} />,
    },
    {
      number: "07",
      title: <div dangerouslySetInnerHTML={{ __html: t('seed.text6')}} />,
      row1: <div dangerouslySetInnerHTML={{ __html: t('seed.text6row1')}} />,
      row2: <div dangerouslySetInnerHTML={{ __html: t('seed.text6row2')}} />,
      row3: <div dangerouslySetInnerHTML={{ __html: t('seed.text6row3')}} />,
      docHref: 'https://api-diamant.inneti.net/media/seeds/%D0%9A%D0%B0%D1%82%D0%B0%D0%BB%D0%BE%D0%B3%20Florimond%20Desprez%202022.pdf',
      docTitle: <div dangerouslySetInnerHTML={{ __html: t('seed.text6docTitle')}} />,
    },
  ]
  // console.log(<div dangerouslySetInnerHTML={{ __html: t('seed.text2')}} />)
  const [openId, setId] = useState(0)
  const clickHandler = (id) => {
    if (id === openId) {
      setId(openId)
    } else {
      setId(id)
    }
  }

  return (
    <>
      <div className="container">
        <section className="seed">
          <div className="acord">
            <ul className="acord__list">
              <div className="acord__wrapper">


                {seed.map((item, id) => (
                  <>
                    <li className={`acord__item ${id === openId ? "active" : ""}`} key={id} id={id} onClick={() => (
                      clickHandler(id)
                    )}>
                      <div className="acord__item--number">
                        {item.number}
                      </div>
                      <div className={`acord__item--text ${id === openId ? "active" : ""}`}>
                        {item.title}
                      </div>
                    </li>
                    <div className={`acord__content acord__content--phone  ${id === openId ? "active" : ""}`}>
                      {item.row1 !== undefined &&
                      <>
                        {item.row1}
                        <br />
                        <br />
                        {item.row2}
                        <br />
                        <br />
                        {item.row3}
                        <br />
                        <br />
                        {item.row4}
                        <br />
                        <br />
                        {item.row5}
                        <br />
                        <br />
                      </>
                      }
                      {(item.docTitle) && (<div className='acord__content--link' style={{ color: 'blue', textDecoration: 'underline' }}>
                        <Link to={item.docHref} target="_blank">
                          {item.docTitle}
                        </Link>
                      </div>)}
                      {(item.docTitle2) && (<div className='acord__content--link' style={{ color: 'blue', textDecoration: 'underline' }}>
                        <Link to={item.docHref2} target="_blank">
                          {item.docTitle2}
                        </Link>
                      </div>)}
                      {(item.docTitle3) && (<div className='acord__content--link' style={{ color: 'blue', textDecoration: 'underline' }}>
                        <Link to={item.docHref3} target="_blank">
                          {item.docTitle3}
                        </Link>
                      </div>)}
                      {(item.docTitle4) && (<div className='acord__content--link' style={{ color: 'blue', textDecoration: 'underline' }}>
                        <Link to={item.docHref4} target="_blank">
                          {item.docTitle4}
                        </Link>
                      </div>)}
                      {(item.docTitle5) && (<div className='acord__content--link' style={{ color: 'blue', textDecoration: 'underline' }}>
                        <Link to={item.docHref5} target="_blank">
                          {item.docTitle5}
                        </Link>
                      </div>)}
                      {(item.docTitle6) && (<div className='acord__content--link' style={{ color: 'blue', textDecoration: 'underline' }}>
                        <Link to={item.docHref6} target="_blank">
                          {item.docTitle6}
                        </Link>
                      </div>)}
                      {(item.docTitle7) && (<div className='acord__content--link' style={{ color: 'blue', textDecoration: 'underline' }}>
                        <Link to={item.docHref7} target="_blank">
                          {item.docTitle7}
                        </Link>
                      </div>)}
                      {(item.docTitle8) && (<div className='acord__content--link' style={{ color: 'blue', textDecoration: 'underline' }}>
                        <Link to={item.docHref8} target="_blank">
                          {item.docTitle8}
                        </Link>
                      </div>)}
                      {(item.docTitle9) && (<div className='acord__content--link' style={{ color: 'blue', textDecoration: 'underline' }}>
                        <Link to={item.docHref9} target="_blank">
                          {item.docTitle9}
                        </Link>
                      </div>)}
                    </div>
                  </>
                ))}
              </div>



              <div className="acord__wrapper">
                {seed.map((item, id) =>
                  <>
                    <div key={id} className={`acord__content acord__content--desktop  ${id === openId ? "active" : ""}`}>
                    {item.row1 !== undefined &&
                    <>
                    {item.row1}
                      <br />
                      <br />
                      {item.row2}
                      <br />
                      <br />
                      {item.row3}
                      <br />
                      <br />
                      {item.row4}
                      <br />
                      <br />
                      {item.row5}
                      <br />
                      <br />
                    </>}
                      {(item.docTitle) && (<div className='acord__content--link' style={{ color: 'blue', textDecoration: 'underline' }}>
                        <Link to={item.docHref} target="_blank">
                          {item.docTitle}
                        </Link>
                      </div>)}
                      {(item.docTitle2) && (<div className='acord__content--link' style={{ color: 'blue', textDecoration: 'underline' }}>
                        <Link to={item.docHref2} target="_blank">
                          {item.docTitle2}
                        </Link>
                      </div>)}
                      {(item.docTitle3) && (<div className='acord__content--link' style={{ color: 'blue', textDecoration: 'underline' }}>
                        <Link to={item.docHref3} target="_blank">
                          {item.docTitle3}
                        </Link>
                      </div>)}
                      {(item.docTitle4) && (<div className='acord__content--link' style={{ color: 'blue', textDecoration: 'underline' }}>
                        <Link to={item.docHref4} target="_blank">
                          {item.docTitle4}
                        </Link>
                      </div>)}
                      {(item.docTitle5) && (<div className='acord__content--link' style={{ color: 'blue', textDecoration: 'underline' }}>
                        <Link to={item.docHref5} target="_blank">
                          {item.docTitle5}
                        </Link>
                      </div>)}
                      {(item.docTitle6) && (<div className='acord__content--link' style={{ color: 'blue', textDecoration: 'underline' }}>
                        <Link to={item.docHref6} target="_blank">
                          {item.docTitle6}
                        </Link>
                      </div>)}
                      {(item.docTitle7) && (<div className='acord__content--link' style={{ color: 'blue', textDecoration: 'underline' }}>
                        <Link to={item.docHref7} target="_blank">
                          {item.docTitle7}
                        </Link>
                      </div>)}
                      {(item.docTitle8) && (<div className='acord__content--link' style={{ color: 'blue', textDecoration: 'underline' }}>
                        <Link to={item.docHref8} target="_blank">
                          {item.docTitle8}
                        </Link>
                      </div>)}
                      {(item.docTitle9) && (<div className='acord__content--link' style={{ color: 'blue', textDecoration: 'underline' }}>
                        <Link to={item.docHref9} target="_blank">
                          {item.docTitle9}
                        </Link>
                      </div>)}
                    </div>
                  </>
                )}
              </div>

            </ul>
          </div>
        </section >
      </div >
    </>
  );
}
