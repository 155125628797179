import React from "react";
import css from "./title.module.scss";
import { ReactComponent as Arrow } from "../../media/images/icons/arrowLeftLong.svg";
import { ReactComponent as Arrow2 } from "../../media/images/icons/arrowShort.svg";

const Title = (content) => {
  const { num1, num2, row1, row2 } = content;
  return (
    <div className={css.title}>
      <div className={css.number}>
        {num1}
        <span>
          /
        </span>
        <span>
          {num2}
        </span>
      </div>
      <div className={css.text}>
        {row1}
        <br />
        {row2}
      </div>
      <div className={css.decorArrowWrapper}>
          <Arrow />
          <Arrow2 />
      </div>
    </div>
  );
};

export default Title;
