import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Navigation } from 'swiper/modules';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import "swiper/css/navigation";
import { useEffect } from 'react';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-multi-lang';

export const HeadSlider = ({slide, showMenu}) => {

const [sliderInstanse, setSwiperInstance] = useState(null);
const navigate = useNavigate();
const translate = useTranslation();

useEffect(()=>{
    if(sliderInstanse) {
        sliderInstanse.slideTo(slide, 1);
    }
},[slide, sliderInstanse])
  
 const slideData = [
    {
        index: 0, 
        path: '/',
        media: '/media/videos/Logo_Diamant.mp4',
    },
    {
        index: 1,
        path: '/clients',
        media: "/media/videos/luduna_Diamant_his.mp4",
    },
    {
        index: 2,
        path: '/workers',
        media: "/media/videos/Luduna_Diamant.mp4",
    },
    {
        index: 3,
        path: '/fermers',
        media: "/media/videos/traktor_diamant.mp4",
    },
    {
        index: 4,
        path: '/drivers',
        media: "/media/videos/Fura_diamant.mp4",
    },
    {
        index: 5,
        path: '/partners',
        media: '/media/videos/Robo4uj_Diamant.mp4',
    },
]

  return (
    <>
    <div className="mobile-bg"></div>
    <Swiper
        direction={'vertical'}
        slidesPerView={1}
        spaceBetween={30}
        grabCursor = {true}
        initialSlide={slide}
        onSwiper={(swiper) => setSwiperInstance(swiper)}
        pagination={{
        clickable: true,
        }}
        navigation={true}  
        modules={[Pagination, Navigation]}
        onSlideChange={(e)=> {const current = slideData.find(element => e.activeIndex === element.index); navigate(current.path)}}
        className="mySwiper"
    >
        {slideData.map((slide, index)=> {
            return <SwiperSlide key={index}>
                        <div className="container">
                        <div className="main__inner-content">
                            <h1 className='slide-title' onClick={showMenu}>
                                {translate(`slider.slide`+index+".mainTitle")} </h1>
                            <h4><span className='outer-span'>
                                    <span className='inner-span'>
                                    <span>{translate('slider.animated.film1').split(' ')[0]}<br />{translate('slider.animated.film1').split(' ')[1]}</span>
                                    <span>{translate('slider.animated.film2').split(' ')[0]}<br />{translate('slider.animated.film2').split(' ')[1]}</span>
                                    <span>{translate('slider.animated.film3').split(' ')[0]}<br />{translate('slider.animated.film3').split(' ')[1]}</span>
                                    <span>{translate('slider.animated.film4').split(' ')[0]}<br />{translate('slider.animated.film4').split(' ')[1]}</span>
                                </span>
                                </span>
                                {translate(`slider.slide`+index+".sub2")} 
                                </h4>
                            <h3>{translate(`slider.slide`+index+".sub3")}</h3>
                        </div>
                            <video
                            // ref={slide.media}
                            width="100%"
                            height="100%"
                            preload="auto"
                            muted
                            autoPlay
                            playsInline
                            loop
                            >
                            <source src={slide.media} type="video/mp4" />
                            </video>
                        </div>
            </SwiperSlide>           
        })}
  </Swiper>
  </>
  );
};