import React from "react";
import css from "./question.module.scss";
// import Container from "../../components/Container/Container";
// import ReCAPTCHA from "react-google-recaptcha";
import ApiForm from "../../components/ApiForm/ApiForm";
import { useTranslation } from "react-multi-lang";

export const Question = () => {

  // function onChange(value) {
  //   console.log("Captcha value:", value);
  // }
  const t = useTranslation();

  return (
      <section className={css['question']}>
        <ApiForm id={2} showLabels={false} showTitle={true} sendBtn = {t('formPlace.sendMsg')}/>
      </section>
  );
}