import css from "./bestEmployees.module.scss";
import Container from "../../components/Container/Container";
import allBestEmployees from "./dataBestEmployees/dataBestEmployees"; // Зміни шлях до імпорту даних
import CardEmployee from "../../components/cardEmployees/CardEmployee";

const { dataBestEmployees } = allBestEmployees;

const BestEmployees = () => {
  return (
    <section className={css["cardsWrapper"]}>
      <Container>
        <ul>
          {dataBestEmployees.map((item, index) => ( 
            <li key={index}>
              <CardEmployee employees={item} />
            </li>
          ))}
        </ul>
      </Container>
    </section>
  );
};

export default BestEmployees;
