import React, { useEffect, useState } from "react";
import { ClientRoom } from "../LogRegister/ClientRoom";
import DropdownItem_1 from "./DropdownItem_1";
import ProductList from "../ProductList/ProductList";

// Список першого рівня
const Dropdown_1 = (props) => {
  //   console.log("props Dropdown_2", props.productArr);

  // Активний пункт меню першого рівня
  const [activeItem_1, setActiveItem_1] = useState(false);

  // При обнуленні активної вкладки меню (навігації) - обнулити активний пункт меню першого рівня
  useEffect(() => {
    if (props.activeItem === false) {
      setActiveItem_1(false);
    }
  }, [props.activeItem]);

  return (
    <div
      className={`menu__list-second-inner ${props.isOpen_1 ? "active" : ""}`}
    >
      {props.productListBul ? (
        <ProductList
          productArr={props.productArr}
          translate={props.translate}
        />
      ) : (
        <ul className={`menu__list-second`}>
          {props.itemHover !== false ? (
            // Список пенктів меню (навігації) при наведенні курсора
            props.itemHover.submenu.map((item, index) => (
              <DropdownItem_1
                parentIndex={props.parentIndex}
                index={index}
                translate={props.translate}
                closeMobile={props.closeMobile}
                key={item.title}
                item={item}
                activeItem_1={activeItem_1}
                setActiveItem_1={setActiveItem_1}
              />
            ))
          ) : (
            <>
              {
                // Список пенктів меню (навігації) при active
                props.item !== undefined &&
                  props.item.submenu !== undefined &&
                  props.item.submenu.map((item, index) => (
                    <DropdownItem_1
                      parentIndex={props.parentIndex}
                      index={index}
                      translate={props.translate}
                      closeMobile={props.closeMobile}
                      key={item.title}
                      item={item}
                      activeItem_1={activeItem_1}
                      setActiveItem_1={setActiveItem_1}
                    />
                  ))
              }
            </>
          )}
        </ul>
      )}

      {(props.isOpen_1 || props.itemHover) && (
        <ClientRoom
          translate={props.translate}
          type={props.itemHover || props.item}
        />
      )}
    </div>
  );
};

export default Dropdown_1;
