import css from "./Table2.module.scss";
import {  useTranslation } from "react-multi-lang";

export const Table2 = () => {
const t = useTranslation();
  return (
    <>
      <div className={css['table']}>
        <p className={css['table__title']}>
          <div dangerouslySetInnerHTML={{ __html: t('sowingTable1.text1')}} />
        </p>
        <div className={css['table__wrapper']}>
          <div className={css['table__col-1']}>
            <p className={css['table__col-1--title']}>
            <div dangerouslySetInnerHTML={{ __html: t('sowingTable1.text2')}} />
            </p>
            <div className={css['table__col-1-row-1']}>
              <p className={css['table__col-1-row-1--text']}>
              13 <span style={{ color: '#C2C7CC', fontWeight: '400' }}>см</span>
              </p>
              <div className={css['table__col-1-row-1--img']}>
                <div className={css['dumbbell__one']}>
                  <div className={css['dumbbell__one--left']}></div>
                  <div className={css['dumbbell__one--midle']}></div>
                  <div className={css['dumbbell__one--right']}></div>
                </div>
              </div>
            </div>
            <div className={css['table__col-1-row-2']}>
              <p className={css['table__col-1-row-2--text']}>
                14 <span style={{ color: '#C2C7CC', fontWeight: '400' }}>см</span>
              </p>
              <div className={css['table__col-1-row-2--img']}>
                <div className={css['dumbbell__two']}>
                  <div className={css['dumbbell__two--left']}></div>
                  <div className={css['dumbbell__two--midle']}></div>
                  <div className={css['dumbbell__two--right']}></div>
                </div>
              </div>
            </div>
            <div className={css['table__col-1-row-3']}>
              <p className={css['table__col-1-row-3--text']}>
                15 <span style={{ color: '#C2C7CC', fontWeight: '400' }}>см</span>
              </p>
              <div className={css['table__col-1-row-3--img']}>
                <div className={css['dumbbell__three']}>
                  <div className={css['dumbbell__three--left']}></div>
                  <div className={css['dumbbell__three--midle']}></div>
                  <div className={css['dumbbell__three--right']}></div>
                </div>
              </div>
            </div>
            <div className={css['table__col-1-row-4']}>
              <p className={css['table__col-1-row-4--text']}>
                16 <span style={{ color: '#C2C7CC', fontWeight: '400' }}>см</span>
              </p>
              <div className={css['table__col-1-row-4--img']}>
                <div className={css['dumbbell__four']}>
                  <div className={css['dumbbell__four--left']}></div>
                  <div className={css['dumbbell__four--midle']}></div>
                  <div className={css['dumbbell__four--right']}></div>
                </div>
              </div>
            </div>
            <div className={css['table__col-1-row-5']}>
              <p className={css['table__col-1-row-5--text']}>
                17 <span style={{ color: '#C2C7CC', fontWeight: '400' }}>см</span>
              </p>
              <div className={css['table__col-1-row-5--img']}>
                <div className={css['dumbbell__five']}>
                  <div className={css['dumbbell__five--left']}></div>
                  <div className={css['dumbbell__five--midle']}></div>
                  <div className={css['dumbbell__five--right']}></div>
                </div>
              </div>
            </div>
            <div className={css['table__col-1-row-6']}>
              <p className={css['table__col-1-row-6--text']}>
                18 <span style={{ color: '#C2C7CC', fontWeight: '400' }}>см</span>
              </p>
              <div className={css['table__col-1-row-6--img']}>
                <div className={css['dumbbell__six']}>
                  <div className={css['dumbbell__six--left']}></div>
                  <div className={css['dumbbell__six--midle']}></div>
                  <div className={css['dumbbell__six--right']}></div>
                </div>
              </div>
            </div>
            <div className={css['table__col-1-row-7']}>
              <p className={css['table__col-1-row-7--text']}>
                19 <span style={{ color: '#C2C7CC', fontWeight: '400' }}>см</span>
              </p>
              <div className={css['table__col-1-row-7--img']}>
                <div className={css['dumbbell__seven']}>
                  <div className={css['dumbbell__seven--left']}></div>
                  <div className={css['dumbbell__seven--midle']}></div>
                  <div className={css['dumbbell__seven--right']}></div>
                </div>
              </div>
            </div>
            <div className={css['table__col-1-row-8']}>
              <p className={css['table__col-1-row-8--text']}>
                20 <span style={{ color: '#C2C7CC', fontWeight: '400' }}>см</span>
              </p>
              <div className={css['table__col-1-row-8--img']}>
                <div className={css['dumbbell__eight']}>
                  <div className={css['dumbbell__eight--left']}></div>
                  <div className={css['dumbbell__eight--midle']}></div>
                  <div className={css['dumbbell__eight--right']}></div>
                </div>
              </div>
            </div>
            <div className={css['table__col-1-row-9']}>
              <p className={css['table__col-1-row-9--text']}>
                21 <span style={{ color: '#C2C7CC', fontWeight: '400' }}>см</span>
              </p>
              <div className={css['table__col-1-row-9--img']}>
                <div className={css['dumbbell__nine']}>
                  <div className={css['dumbbell__nine--left']}></div>
                  <div className={css['dumbbell__nine--midle']}></div>
                  <div className={css['dumbbell__nine--right']}></div>
                </div>
              </div>
            </div>
            <div className={css['table__col-1-row-10']}>
              <p className={css['table__col-1-row-10--text']}>
                22 <span style={{ color: '#C2C7CC', fontWeight: '400' }}>см</span>
              </p>
              <div className={css['table__col-1-row-10--img']}>
                <div className={css['dumbbell__ten']}>
                  <div className={css['dumbbell__ten--left']}></div>
                  <div className={css['dumbbell__ten--midle']}></div>
                  <div className={css['dumbbell__ten--right']}></div>
                </div>
              </div>
            </div>
            <div className={css['table__col-1-row-11']}>
              <p className={css['table__col-1-row-11--text']}>
                23 <span style={{ color: '#C2C7CC', fontWeight: '400' }}>см</span>
              </p>
              <div className={css['table__col-1-row-11--img']}>
                <div className={css['dumbbell__eleven']}>
                  <div className={css['dumbbell__eleven--left']}></div>
                  <div className={css['dumbbell__eleven--midle']}></div>
                  <div className={css['dumbbell__eleven--right']}></div>
                </div>
              </div>
            </div>
          </div>
          <div className={css['table__col-2']}>
            <p className={css['table__col-2--title']}>
            <div dangerouslySetInnerHTML={{ __html: t('sowingTable1.text3')}} />
            </p>
            <div className={css['table__col-2--wrapper']}>
              <div className={css['table__col-2-row-1']}>
                <p className={css['table__col-2-row-1--text']}>
                  171 <span style={{ color: '#C2C7CC', fontWeight: '500' }}>000</span>
                </p>
                <div className={css['table__col-2-row-1--line-1']}></div>
                <div className={css['table__col-2-row-1--line-2']}></div>
              </div>
              <div className={css['table__col-2-row-2']}>
                <p className={css['table__col-2-row-2--text']}>
                  159 <span style={{ color: '#C2C7CC', fontWeight: '500' }}>000</span>
                </p>
                <div className={css['table__col-2-row-2--line-1']}></div>
                <div className={css['table__col-2-row-2--line-2']}></div>
              </div>
              <div className={css['table__col-2-row-3']}>
                <p className={css['table__col-2-row-3--text']}>
                  148 <span style={{ color: '#C2C7CC', fontWeight: '500' }}>000</span>
                </p>
                <div className={css['table__col-2-row-3--line-1']}></div>
                <div className={css['table__col-2-row-3--line-2']}></div>
              </div>
              <div className={css['table__col-2-row-4']}>
                <p className={css['table__col-2-row-4--text']}>
                  139 <span style={{ color: '#C2C7CC', fontWeight: '500' }}>000</span>
                </p>
                <div className={css['table__col-2-row-4--line-1']}></div>
                <div className={css['table__col-2-row-4--line-2']}></div>
              </div>
              <div className={css['table__col-2-row-5']}>
                <p className={css['table__col-2-row-5--text']}>
                  134 <span style={{ color: '#C2C7CC', fontWeight: '500' }}>000</span>
                </p>
                <div className={css['table__col-2-row-5--line-1']}></div>
                <div className={css['table__col-2-row-5--line-2']}></div>
              </div>
              <div className={css['table__col-2-row-6']}>
                <p className={css['table__col-2-row-6--text']}>
                  124 <span style={{ color: '#C2C7CC', fontWeight: '500' }}>000</span>
                </p>
                <div className={css['table__col-2-row-6--line-1']}></div>
                <div className={css['table__col-2-row-6--line-2']}></div>
              </div>
              <div className={css['table__col-2-row-7']}>
                <p className={css['table__col-2-row-7--text']}>
                  117 <span style={{ color: '#C2C7CC', fontWeight: '500' }}>000</span>
                </p>
                <div className={css['table__col-2-row-7--line-1']}></div>
                <div className={css['table__col-2-row-7--line-2']}></div>
              </div>
              <div className={css['table__col-2-row-8']}>
                <p className={css['table__col-2-row-8--text']}>
                  111 <span style={{ color: '#C2C7CC', fontWeight: '500' }}>000</span>
                </p>
                <div className={css['table__col-2-row-8--line-1']}></div>
                <div className={css['table__col-2-row-8--line-2']}></div>
              </div>
              <div className={css['table__col-2-row-9']}>
                <p className={css['table__col-2-row-9--text']}>
                  106 <span style={{ color: '#C2C7CC', fontWeight: '500' }}>000</span>
                </p>
                <div className={css['table__col-2-row-9--line-1']}></div>
                <div className={css['table__col-2-row-9--line-2']}></div>
              </div>
              <div className={css['table__col-2-row-10']}>
                <p className={css['table__col-2-row-10--text']}>
                  101 <span style={{ color: '#C2C7CC', fontWeight: '500' }}>000</span>
                </p>
                <div className={css['table__col-2-row-10--line-1']}></div>
                <div className={css['table__col-2-row-10--line-2']}></div>
              </div>
              <div className={css['table__col-2-row-11']}>
                <p className={css['table__col-2-row-11--text']}>
                  97 <span style={{ color: '#C2C7CC', fontWeight: '500' }}>000</span>
                </p>
                <div className={css['table__col-2-row-11--line-1']}></div>
                <div className={css['table__col-2-row-11--line-2']}></div>
              </div>
            </div>
          </div>
          <div className={css['table__col-3']}>
            <div className={css['table__col-3--pag']}>
              <div className={css['table__col-3--pag--numb']}>
                80%
              </div>
              <div className={css['table__col-3--pag--pag']}>
                <div className={css['table__col-3--pagImg-1']}></div>
                <div className={css['table__col-3--pagImg-2']}></div>
                <div className={css['table__col-3--pagPag']}></div>
                <div className={css['table__col-3--pagPag']}></div>
                <div className={css['table__col-3--pagPag']}></div>
                <div className={css['table__col-3--pagPag']}></div>
                <div className={css['table__col-3--pagPag']}></div>
                <div className={css['table__col-3--pagPag']}></div>
                <div className={css['table__col-3--pagPag']}></div>
                <div className={css['table__col-3--pagPag']}></div>
              </div>
            </div>
            <div className={css['table__col-3--wrapper']}>
              <div className={css['table__col-3-row-1']}>
                <p className={css['table__col-3-row-1--text']}>
                  137 <span style={{ color: '#C2C7CC', fontWeight: '500' }}>000</span>
                </p>
                <div className={css['table__col-3-row-1--line-1']}></div>
                <div className={css['table__col-3-row-1--line-2']}></div>
              </div>
              <div className={css['table__col-3-row-2']}>
                <p className={css['table__col-3-row-2--text']}>
                  127 <span style={{ color: '#C2C7CC', fontWeight: '500' }}>000</span>
                </p>
                <div className={css['table__col-3-row-2--line-1']}></div>
                <div className={css['table__col-3-row-2--line-2']}></div>
              </div>
              <div className={css['table__col-3-row-3']}>
                <p className={css['table__col-3-row-3--text']}>
                  118 <span style={{ color: '#C2C7CC', fontWeight: '500' }}>000</span>
                </p>
                <div className={css['table__col-3-row-3--line-1']}></div>
                <div className={css['table__col-3-row-3--line-2']}></div>
              </div>
              <div className={css['table__col-3-row-4']}>
                <p className={css['table__col-3-row-4--text']}>
                  111 <span style={{ color: '#C2C7CC', fontWeight: '500' }}>000</span>
                </p>
                <div className={css['table__col-3-row-4--line-1']}></div>
                <div className={css['table__col-3-row-4--line-2']}></div>
              </div>
              <div className={css['table__col-3-row-5']}>
                <p className={css['table__col-3-row-5--text']}>
                  105 <span style={{ color: '#C2C7CC', fontWeight: '500' }}>000</span>
                </p>
                <div className={css['table__col-3-row-5--line-1']}></div>
                <div className={css['table__col-3-row-5--line-2']}></div>
              </div>
              <div className={css['table__col-3-row-6']}>
                <p className={css['table__col-3-row-6--text']}>
                  99 <span style={{ color: '#C2C7CC', fontWeight: '500' }}>000</span>
                </p>
                <div className={css['table__col-3-row-6--line-1']}></div>
                <div className={css['table__col-3-row-6--line-2']}></div>
              </div>
              <div className={css['table__col-3-row-7']}>
                <p className={css['table__col-3-row-7--text']}>
                  94 <span style={{ color: '#C2C7CC', fontWeight: '500' }}>000</span>
                </p>
                <div className={css['table__col-3-row-7--line-1']}></div>
                <div className={css['table__col-3-row-7--line-2']}></div>
              </div>
              <div className={css['table__col-3-row-8']}>
                <p className={css['table__col-3-row-8--text']}>
                  89 <span style={{ color: '#C2C7CC', fontWeight: '500' }}>000</span>
                </p>
                <div className={css['table__col-3-row-8--line-1']}></div>
                <div className={css['table__col-3-row-8--line-2']}></div>
              </div>
              <div className={css['table__col-3-row-9']}>
                <p className={css['table__col-3-row-9--text']}>
                  85 <span style={{ color: '#C2C7CC', fontWeight: '500' }}>000</span>
                </p>
                <div className={css['table__col-3-row-9--line-1']}></div>
                <div className={css['table__col-3-row-9--line-2']}></div>
              </div>
              <div className={css['table__col-3-row-10']}>
                <p className={css['table__col-3-row-10--text']}>
                  81 <span style={{ color: '#C2C7CC', fontWeight: '500' }}>000</span>
                </p>
                <div className={css['table__col-3-row-10--line-1']}></div>
                <div className={css['table__col-3-row-10--line-2']}></div>
              </div>
              <div className={css['table__col-3-row-11']}>
                <p className={css['table__col-3-row-11--text']}>
                  78 <span style={{ color: '#C2C7CC', fontWeight: '500' }}>000</span>
                </p>
                <div className={css['table__col-3-row-11--line-1']}></div>
                <div className={css['table__col-3-row-11--line-2']}></div>
              </div>
            </div>
          </div>
          <div className={css['table__col-4']}>
            <div className={css['table__col-4--pag']}>
              <div className={css['table__col-4--pag--numb']}>
                70%
              </div>
              <div className={css['table__col-4--pag--pag']}>
                <div className={css['table__col-4--pagImg-1']}></div>
                <div className={css['table__col-4--pagImg-2']}></div>
                <div className={css['table__col-4--pagImg-2']}></div>
                <div className={css['table__col-4--pagPag']}></div>
                <div className={css['table__col-4--pagPag']}></div>
                <div className={css['table__col-4--pagPag']}></div>
                <div className={css['table__col-4--pagPag']}></div>
                <div className={css['table__col-4--pagPag']}></div>
                <div className={css['table__col-4--pagPag']}></div>
                <div className={css['table__col-4--pagPag']}></div>
              </div>
            </div>
            <div className={css['table__col-4--wrapper']}>
              <div className={css['table__col-4-row-1']}>
                <p className={css['table__col-4-row-1--text']}>
                  120 <span style={{ color: '#C2C7CC', fontWeight: '500' }}>000</span>
                </p>
                <div className={css['table__col-4-row-1--line-1']}></div>
                <div className={css['table__col-4-row-1--line-2']}></div>
              </div>
              <div className={css['table__col-4-row-2']}>
                <p className={css['table__col-4-row-2--text']}>
                  111 <span style={{ color: '#C2C7CC', fontWeight: '500' }}>000</span>
                </p>
                <div className={css['table__col-4-row-2--line-1']}></div>
                <div className={css['table__col-4-row-2--line-2']}></div>
              </div>
              <div className={css['table__col-4-row-3']}>
                <p className={css['table__col-4-row-3--text']}>
                  104 <span style={{ color: '#C2C7CC', fontWeight: '500' }}>000</span>
                </p>
                <div className={css['table__col-4-row-3--line-1']}></div>
                <div className={css['table__col-4-row-3--line-2']}></div>
              </div>
              <div className={css['table__col-4-row-4']}>
                <p className={css['table__col-4-row-4--text']}>
                  97 <span style={{ color: '#C2C7CC', fontWeight: '500' }}>000</span>
                </p>
                <div className={css['table__col-4-row-4--line-1']}></div>
                <div className={css['table__col-4-row-4--line-2']}></div>
              </div>
              <div className={css['table__col-4-row-5']}>
                <p className={css['table__col-4-row-5--text']}>
                  91 <span style={{ color: '#C2C7CC', fontWeight: '500' }}>000</span>
                </p>
                <div className={css['table__col-4-row-5--line-1']}></div>
                <div className={css['table__col-4-row-5--line-2']}></div>
              </div>
              <div className={css['table__col-4-row-6']}>
                <p className={css['table__col-4-row-6--text']}>
                  86 <span style={{ color: '#C2C7CC', fontWeight: '500' }}>000</span>
                </p>
                <div className={css['table__col-4-row-6--line-1']}></div>
                <div className={css['table__col-4-row-6--line-2']}></div>
              </div>
              <div className={css['table__col-4-row-7']}>
                <p className={css['table__col-4-row-7--text']}>
                  82 <span style={{ color: '#C2C7CC', fontWeight: '500' }}>000</span>
                </p>
                <div className={css['table__col-4-row-7--line-1']}></div>
                <div className={css['table__col-4-row-7--line-2']}></div>
              </div>
              <div className={css['table__col-4-row-8']}>
                <p className={css['table__col-4-row-8--text']}>
                  78 <span style={{ color: '#C2C7CC', fontWeight: '500' }}>000</span>
                </p>
                <div className={css['table__col-4-row-8--line-1']}></div>
                <div className={css['table__col-4-row-8--line-2']}></div>
              </div>
              <div className={css['table__col-4-row-9']}>
                <p className={css['table__col-4-row-9--text']}>
                  74 <span style={{ color: '#C2C7CC', fontWeight: '500' }}>000</span>
                </p>
                <div className={css['table__col-4-row-9--line-1']}></div>
                <div className={css['table__col-4-row-9--line-2']}></div>
              </div>
              <div className={css['table__col-4-row-10']}>
                <p className={css['table__col-4-row-10--text']}>
                  71 <span style={{ color: '#C2C7CC', fontWeight: '500' }}>000</span>
                </p>
                <div className={css['table__col-4-row-10--line-1']}></div>
                <div className={css['table__col-4-row-10--line-2']}></div>
              </div>
              <div className={css['table__col-4-row-11']}>
                <p className={css['table__col-4-row-11--text']}>
                  68 <span style={{ color: '#C2C7CC', fontWeight: '500' }}>000</span>
                </p>
                <div className={css['table__col-4-row-11--line-1']}></div>
                <div className={css['table__col-4-row-11--line-2']}></div>
              </div>
            </div>
          </div>
          <div className={css['table__col-5']}>
            <div className={css['table__col-5--pag']}>
              <div className={css['table__col-5--pag--numb']}>
                60%
              </div>
              <div className={css['table__col-5--pag--pag']}>
                <div className={css['table__col-5--pagImg-1']}></div>
                <div className={css['table__col-5--pagImg-2']}></div>
                <div className={css['table__col-5--pagImg-2']}></div>
                <div className={css['table__col-5--pagImg-2']}></div>
                <div className={css['table__col-5--pagPag']}></div>
                <div className={css['table__col-5--pagPag']}></div>
                <div className={css['table__col-5--pagPag']}></div>
                <div className={css['table__col-5--pagPag']}></div>
                <div className={css['table__col-5--pagPag']}></div>
                <div className={css['table__col-5--pagPag']}></div>
              </div>
            </div>
            <div className={css['table__col-5--wrapper']}>
              <div className={css['table__col-5-row-1']}>
                <p className={css['table__col-5-row-1--text']}>
                  103 <span style={{ color: '#C2C7CC', fontWeight: '500' }}>000</span>
                </p>
                <div className={css['table__col-5-row-1--line-1']}></div>
                <div className={css['table__col-5-row-1--line-2']}></div>
              </div>
              <div className={css['table__col-5-row-2']}>
                <p className={css['table__col-5-row-2--text']}>
                  95 <span style={{ color: '#C2C7CC', fontWeight: '500' }}>000</span>
                </p>
                <div className={css['table__col-5-row-2--line-1']}></div>
                <div className={css['table__col-5-row-2--line-2']}></div>
              </div>
              <div className={css['table__col-5-row-3']}>
                <p className={css['table__col-5-row-3--text']}>
                  89 <span style={{ color: '#C2C7CC', fontWeight: '500' }}>000</span>
                </p>
                <div className={css['table__col-5-row-3--line-1']}></div>
                <div className={css['table__col-5-row-3--line-2']}></div>
              </div>
              <div className={css['table__col-5-row-4']}>
                <p className={css['table__col-5-row-4--text']}>
                  83 <span style={{ color: '#C2C7CC', fontWeight: '500' }}>000</span>
                </p>
                <div className={css['table__col-5-row-4--line-1']}></div>
                <div className={css['table__col-5-row-4--line-2']}></div>
              </div>
              <div className={css['table__col-5-row-5']}>
                <p className={css['table__col-5-row-5--text']}>
                  78 <span style={{ color: '#C2C7CC', fontWeight: '500' }}>000</span>
                </p>
                <div className={css['table__col-5-row-5--line-1']}></div>
                <div className={css['table__col-5-row-5--line-2']}></div>
              </div>
              <div className={css['table__col-5-row-6']}>
                <p className={css['table__col-5-row-6--text']}>
                  74 <span style={{ color: '#C2C7CC', fontWeight: '500' }}>000</span>
                </p>
                <div className={css['table__col-5-row-6--line-1']}></div>
                <div className={css['table__col-5-row-6--line-2']}></div>
              </div>
              <div className={css['table__col-5-row-7']}>
                <p className={css['table__col-5-row-7--text']}>
                  70 <span style={{ color: '#C2C7CC', fontWeight: '500' }}>000</span>
                </p>
                <div className={css['table__col-5-row-7--line-1']}></div>
                <div className={css['table__col-5-row-7--line-2']}></div>
              </div>
              <div className={css['table__col-5-row-8']}>
                <p className={css['table__col-5-row-8--text']}>
                  67 <span style={{ color: '#C2C7CC', fontWeight: '500' }}>000</span>
                </p>
                <div className={css['table__col-5-row-8--line-1']}></div>
                <div className={css['table__col-5-row-8--line-2']}></div>
              </div>
              <div className={css['table__col-5-row-9']}>
                <p className={css['table__col-5-row-9--text']}>
                  63 <span style={{ color: '#C2C7CC', fontWeight: '500' }}>000</span>
                </p>
                <div className={css['table__col-5-row-9--line-1']}></div>
                <div className={css['table__col-5-row-9--line-2']}></div>
              </div>
              <div className={css['table__col-5-row-10']}>
                <p className={css['table__col-5-row-10--text']}>
                  61 <span style={{ color: '#C2C7CC', fontWeight: '500' }}>000</span>
                </p>
                <div className={css['table__col-5-row-10--line-1']}></div>
                <div className={css['table__col-5-row-10--line-2']}></div>
              </div>
              <div className={css['table__col-5-row-11']}>
                <p className={css['table__col-5-row-11--text']}>
                  58 <span style={{ color: '#C2C7CC', fontWeight: '500' }}>000</span>
                </p>
                <div className={css['table__col-5-row-11--line-1']}></div>
                <div className={css['table__col-5-row-11--line-2']}></div>
              </div>
            </div>
          </div>
          <div className={css['table__col-6']}>
            <p className={css['table__col-6--title']}>
            <div dangerouslySetInnerHTML={{ __html: t('sowingTable1.text4')}} />
            </p>
            <div className={css['table__col-6--pag']}>
              <div className={css['table__col-6--pag--numb']}>
                50%
              </div>
              <div className={css['table__col-6--pag--pag']}>
                <div className={css['table__col-6--pagImg-1']}></div>
                <div className={css['table__col-6--pagImg-2']}></div>
                <div className={css['table__col-6--pagImg-2']}></div>
                <div className={css['table__col-6--pagImg-3']}></div>
                <div className={css['table__col-6--pagImg-3']}></div>
                <div className={css['table__col-6--pagImg-3']}></div>
                <div className={css['table__col-6--pagPag']}></div>
                <div className={css['table__col-6--pagPag']}></div>
                <div className={css['table__col-6--pagPag']}></div>
                <div className={css['table__col-6--pagPag']}></div>
                <div className={css['table__col-6--pagPag']}></div>
              </div>
            </div>
            <div className={css['table__col-6--wrapper']}>
              <div className={css['table__col-6-row-1']}>
                <p className={css['table__col-6-row-1--text']}>
                  85 <span style={{ color: '#C2C7CC', fontWeight: '500' }}>000</span>
                </p>
                <div className={css['table__col-6-row-1--line-1']}></div>
                <div className={css['table__col-6-row-1--line-2']}></div>
              </div>
              <div className={css['table__col-6-row-2']}>
                <p className={css['table__col-6-row-2--text']}>
                  80 <span style={{ color: '#C2C7CC', fontWeight: '500' }}>000</span>
                </p>
                <div className={css['table__col-6-row-2--line-1']}></div>
                <div className={css['table__col-6-row-2--line-2']}></div>
              </div>
              <div className={css['table__col-6-row-3']}>
                <p className={css['table__col-6-row-3--text']}>
                  75 <span style={{ color: '#C2C7CC', fontWeight: '500' }}>000</span>
                </p>
                <div className={css['table__col-6-row-3--line-1']}></div>
                <div className={css['table__col-6-row-3--line-2']}></div>
              </div>
              <div className={css['table__col-6-row-4']}>
                <p className={css['table__col-6-row-4--text']}>
                  69 <span style={{ color: '#C2C7CC', fontWeight: '500' }}>000</span>
                </p>
                <div className={css['table__col-6-row-4--line-1']}></div>
                <div className={css['table__col-6-row-4--line-2']}></div>
              </div>
              <div className={css['table__col-6-row-5']}>
                <p className={css['table__col-6-row-5--text']}>
                  65 <span style={{ color: '#C2C7CC', fontWeight: '500' }}>000</span>
                </p>
                <div className={css['table__col-6-row-5--line-1']}></div>
                <div className={css['table__col-6-row-5--line-2']}></div>
              </div>
              <div className={css['table__col-6-row-6']}>
                <p className={css['table__col-6-row-6--text']}>
                  62 <span style={{ color: '#C2C7CC', fontWeight: '500' }}>000</span>
                </p>
                <div className={css['table__col-6-row-6--line-1']}></div>
                <div className={css['table__col-6-row-6--line-2']}></div>
              </div>
              <div className={css['table__col-6-row-7']}>
                <p className={css['table__col-6-row-7--text']}>
                  58 <span style={{ color: '#C2C7CC', fontWeight: '500' }}>000</span>
                </p>
                <div className={css['table__col-6-row-7--line-1']}></div>
                <div className={css['table__col-6-row-7--line-2']}></div>
              </div>
              <div className={css['table__col-6-row-8']}>
                <p className={css['table__col-6-row-8--text']}>
                  56 <span style={{ color: '#C2C7CC', fontWeight: '500' }}>000</span>
                </p>
                <div className={css['table__col-6-row-8--line-1']}></div>
                <div className={css['table__col-6-row-8--line-2']}></div>
              </div>
              <div className={css['table__col-6-row-9']}>
                <p className={css['table__col-6-row-9--text']}>
                  53 <span style={{ color: '#C2C7CC', fontWeight: '500' }}>000</span>
                </p>
                <div className={css['table__col-6-row-9--line-1']}></div>
                <div className={css['table__col-6-row-9--line-2']}></div>
              </div>
              <div className={css['table__col-6-row-10']}>
                <p className={css['table__col-6-row-10--text']}>
                  51 <span style={{ color: '#C2C7CC', fontWeight: '500' }}>000</span>
                </p>
                <div className={css['table__col-6-row-10--line-1']}></div>
                <div className={css['table__col-6-row-10--line-2']}></div>
              </div>
              <div className={css['table__col-6-row-11']}>
                <p className={css['table__col-6-row-11--text']}>
                  49 <span style={{ color: '#C2C7CC', fontWeight: '500' }}>000</span>
                </p>
                <div className={css['table__col-6-row-11--line-1']}></div>
                <div className={css['table__col-6-row-11--line-2']}></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}