import { useLayoutEffect, useState } from "react"
import { setLanguage } from "react-multi-lang";

export const useLang = () =>{
    const [lang, setLang] = useState(localStorage.getItem('lang') || 'ua');

    useLayoutEffect(()=>{
        setLanguage(lang);
        localStorage.setItem('lang', lang);
    },[lang])

    return {lang, setLang}
}
