import React, { useState } from "react";
import css from "./calculate.module.scss";
import { useTranslation } from "react-multi-lang";

const CalculateSoil = () => {
  
  const [cao2, setCao2] = useState(0);
  const translate = useTranslation();
  
  return (
    <div className={css['calculate-soil']}>
        <p dangerouslySetInnerHTML={{__html: translate('soil.soilCalc')}}></p>
        <div className={css['calculate-soil__inner']}>
        <input type="number" value={cao2} onChange={(e)=> setCao2(e.target.value)}/>
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M1 1L17 17" stroke="#959EA6" stroke-linecap="round"/>
        <path d="M17 1L1 17" stroke="#959EA6" stroke-linecap="round"/>
        </svg>
        <span>3,33</span>
        <input type="number" value={(cao2 * 3.33).toFixed(2)} disabled/>
        </div>
    </div>
  );
};

export default CalculateSoil;