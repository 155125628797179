import React, { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";

import css from "./SliderCard.module.scss";
import { ReactComponent as Search } from "../../media/images/icons/search.svg";
import { ReactComponent as ArrowLeft } from "../../media/images/icons/arrowLeft.svg";
import { ReactComponent as ArrowRight } from "../../media/images/icons/arrowRight.svg";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-multi-lang";

const getCurrentYear = () => {
  return new Date().getFullYear();
};

const currentYear = getCurrentYear();

const SliderCard = ({
  allArrCard,
  setCurrentIndex,
  currentIndex,
  type,
  total,
}) => {
  
  const navigate = useNavigate();
  // фільтруємо вибраний рік по останньому в системі
  const filtredYear = allArrCard.map((elem)=>{
    return parseInt(elem.date.split(".")[2]);
  }).sort((a, b) => b - a);

  // поле пошуку
  const [searchTerm, setSearchTerm] = useState("");
  // вибраний рік
  const [selectedYear, setSelectedYear] = useState(null);
  // Кількість елементів на сторінці
  const itemsPerPage = 10;
  // індекс кнопки фільтра
  const [currentIndexFilter, setCurrentIndexFilter] = useState(0);
  const [arrCard, setArrCard] = useState(allArrCard);

  useEffect(() => {
    setArrCard(allArrCard);
    if(selectedYear === null || selectedYear === undefined) {
      setSelectedYear(filtredYear[0]);
    }
  }, [allArrCard]);

  // useEffect(() => {
  //   console.log("arrCard", arrCard);
  // }, [arrCard]);

  const changeActiveFilter = (index, year) => {
    setCurrentIndexFilter(index);
    setSelectedYear(year);
    setCurrentIndex(0);
  };

  // Обробник зміни поля пошуку
  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  // Обробник кнопки "Назад"
  const handlePrev = () => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
    }
  };

  // Обробник кнопки "Вперед"
  const handleNext = () => {
    if (currentIndex + 1 < Math.ceil(total / itemsPerPage)) {
      setCurrentIndex(currentIndex + 1);
    }
  };

  // Перша сторінка
  const handleFirstPage = () => {
    setCurrentIndex(0);
  };

  // Остання сторінка
  const handleLastPage = () => {
    // догори         34       6
    setCurrentIndex(Math.ceil(total / itemsPerPage) - 1);
  };

  // Фільтруємо за полем та роками

  const [filteredData, setFilteredData] = useState([]);

  useEffect(() => {
    const arrFilteredData = arrCard.filter((item) => {
      // console.log("selectedYea1r111111111", selectedYear);
      return (
        item.title.toLowerCase().includes(searchTerm.toLowerCase()) &&
        item.date.endsWith(selectedYear)
      );
    });

    setFilteredData(arrFilteredData);
  }, [selectedYear, searchTerm, arrCard]);

  // Розраховуємо індекси елементів для поточної сторінки
  // Оголошуємо стан для зберігання поточних елементів
  const [currentItems, setCurrentItems] = useState([]);

  // Розраховуємо індекси елементів для поточної сторінки
  const startIndex = currentIndex * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  useEffect(() => {
    // Перевіряємо, чи є дані для фільтрації
    if (filteredData.length > 0) {
      const updatedCurrentItems = filteredData.slice(startIndex, endIndex);
      setCurrentItems(updatedCurrentItems);
      // console.log("updatedCurrentItems", updatedCurrentItems);
    }
  }, [startIndex, endIndex, arrCard, filteredData]);

  // useEffect(() => {
  //   console.log("currentItems", currentItems);
  // }, [currentItems]);

  // Генеруємо кнопки пагінації
  const pageNumbers = [];

  for (let i = 1; i <= Math.ceil(filteredData.length / itemsPerPage); i++) {
    // for (let i = 1; i <= Math.ceil(total / itemsPerPage); i++) {
    pageNumbers.push(i);
  }
  // console.log("pageNumbers", pageNumbers);

  // зміщення елемента на його ширену + відступ
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const paginationItemWidth = isMobile ? 40 : 48;

  // створення масиву доступних років
  const arrAvailableDate = (arrForFilter) => {
    let newArrDate = [];

    const filterByDate = arrForFilter.map((el) => {
      const dateStr = el.date;
      const parts = dateStr.split(".");
      const year = parts[2];
      // console.log("year", year);
      if (newArrDate.includes(year)) {
        return;
      } else {
        newArrDate.unshift(year);
      }
    });
    
    const sortYear = newArrDate.sort(function (a, b) {
      return parseInt(b) - parseInt(a);
    })

    return sortYear;
  };

  const arrYear = arrAvailableDate(arrCard);

  const t = useTranslation();

  return (
    <div>
      {/* Поле пошуку */}
      <div className={css["inputWrapper"]}>
        <input
          type="text"
          id="search-input"
          placeholder={t("formPlace.search")}
          value={searchTerm}
          onChange={handleSearchChange}
        />

        <Search className={css["iconButton"]} />

        {/* <button id="search-button">
          <Search />
        </button> */}
      </div>

      {/* Фільтр по роках */}
      <div className={css["filterWrapper"]}>
        <ul>
          {/* {[2023, 2022, 2021, 2020, 2019].map((item, index) => ( */}
          {arrYear.map((item, index) => (
            <li key={index}>
              <button
                className={
                  index === currentIndexFilter ? css["active__button"] : ""
                }
                onClick={() => changeActiveFilter(index, item)}
              >
                {item}
              </button>
            </li>
          ))}
        </ul>
      </div>

      {/* Контейнер новин */}
      <ul className={css["cardContainer"]}>
        {/* {currentItems.map((item, index) => ( */}
        {currentItems.map((item, index) => (
          <li key={index}>
            <img
              // src={process.env.REACT_APP_SERVER_URL + item.imgURL}
              src={item.imgURL?.includes('http') ? item.imgURL : process.env.REACT_APP_SERVER_URL + item.imgURL}
              alt={item.title}
              onClick = {()=>{navigate(`/${type}/` + item.id)}}
            />
            <p className={`${css["date"]}`}>{item.date}</p>
            <h3 onClick = {()=>{navigate(`/${type}/` + item.id)}}>
              {item.title}
            </h3>
            {/* <div className={`${css["wrapperCard"]}`}> */}
            {/* {item.description !== undefined && 
            <div dangerouslySetInnerHTML={{__html: item.description }} className={`${css["description"]}`}></div>} */}
            <button className={`${css["buttonCard"]}`}>
              {" "}
              <Link to={`/${type}/` + item.id}>Детальніше</Link>
            </button>
            {/* </div> */}
          </li>
        ))}
      </ul>

      {/* Кнопки пагінації */}

      <div className={css["containerPagination"]}>
        <ArrowLeft
          className={`${css["prevArrow"]} ${
            currentIndex === 0 ? css["inactiveArrow"] : ""
          }`}
          onClick={handlePrev}
        />

        {/* додаткова кнопка за умовою */}

        <div className={css["wrapLostBtn"]}>
          {currentIndex + 1 > 4 ? (
            <button
              className={css["firstPageButton"]}
              onClick={handleFirstPage}
            >
              1
            </button>
          ) : (
            ""
          )}
        </div>

        {/* блок пагінації всі сторінки */}

        <div className={css["overflowWrapper"]}>
          <div className={css["decorBorder"]}></div>

          <div className={css["pagination"]}>
            <div className={css["pagination_border"]}></div>
            <div
              className={css["pagination_dots"]}
              style={{
                transform: `translateX(-${
                  paginationItemWidth * currentIndex
                }px)`,
              }}
            >
              {pageNumbers.map((page) => (
                <div
                  key={page}
                  className={`${css["dot"]} ${
                    page === currentIndex + 1 ? css["activeDot"] : ""
                  }`}
                  onClick={() => setCurrentIndex(page - 1)}
                >
                  {page}
                </div>
              ))}
            </div>
          </div>
        </div>

        {/* додати умову зникненн  */}

        <div className={css["wrapLostBtn"]}>
          {/* {currentIndex < Math.ceil(total / itemsPerPage) - 4 ? ( */}
          {currentIndex < Math.ceil(filteredData.length / itemsPerPage) - 4 ? (
            <button className={css["lastPageButton"]} onClick={handleLastPage}>
              {/* {Math.ceil(total / itemsPerPage)} */}
              {Math.ceil(filteredData.length / itemsPerPage)}
            </button>
          ) : (
            ""
          )}
        </div>

        <ArrowRight
          className={`${css["nextArrow"]} ${
            currentIndex === Math.ceil(total) - 1 ? css["inactiveArrow"] : ""
          }`}
          onClick={handleNext}
        />
      </div>
    </div>
  );
};

export default SliderCard;
