import React from "react"; // Import React if not already imported
import css from "./LectureSchedule.module.scss";
import Container from "../../components/Container/Container";
import allLectureSchedule from "./dataLectureSchedule/dataShipment";
import CardLecture from "./CardLecture/CardLecture";

const LectureSchedule = () => {
  const { lectureScheduleWithIds } = allLectureSchedule;
  // console.log('start arr', lectureScheduleWithIds);

  return (
    <section className={css["LectureWrapper"]}>
      <Container>
        <ul className={css["LectureList"]}>
          {lectureScheduleWithIds.map((item, index) => (
            <li key={index}>
              <CardLecture obj={item} />
            </li>
          ))}
        </ul>
      </Container>
    </section>
  );
};

export default LectureSchedule;
