import React from "react";
import css from './Logistics.module.scss';
// import PagePath from "../../components/PagePath/PagePath";
import ContentLogistics from "./ContentLogistics/ContentLogistics";
import Container from "../../components/Container/Container";
import allDataLocation from "./dataLogistics/dataLogistics";

const { dataLogistics } = allDataLocation;

// console.log('dataLogistics[1]',dataLogistics[1]);

const LogisticsContent = () => {
  return (
    <div className={css["LogisticsWrapper"]}>
      <Container>
        <ContentLogistics dataLogistics={dataLogistics[0]} />
        <ContentLogistics dataLogistics={dataLogistics[1]} />
      </Container>
    </div>
  );
};

export default LogisticsContent;
