import React from "react";
import css from "./loader.module.scss";

export const Loader = () => {
    
  return (
    <div className={css["loader"]}>
        <div className={css["tile"]}></div>
    </div>
  );
};