import Container from "../../../components/Container/Container";
import css from "./SocialActivity.module.scss";
import { useTranslation } from "react-multi-lang";

const SocialActivityHead = () => {
  const t = useTranslation()
  return (
    <div className={css["socialActivityHead"]}>
      <Container>
        <div className={css["wrapper"]}>
          <h2>
            <b>{t('headTemplate.social')}</b>
          </h2>
          <p>{t('headTemplate.socialtext')}</p>
        </div>
      </Container>
    </div>
  );
};

export default SocialActivityHead;
