import React from 'react';
import { Link } from 'react-router-dom';

// Конкретний товар вибраної категорії
const ProductsListItem = (props) => {
    return (
        <li>
            <Link to={props.item.path} className="sugar__item" onClick = {()=>props.closeMobile()}>
                <img className="sugar__img" src={props.item.img} alt="sugar" />
                <div className="sugar__name">{props.item.title}</div>
                <div className="sugar__weight--one">{props.item.weight2}</div>
                <div className="sugar__weight">{props.item.weight}</div>
            </Link>
        </li>
    );
};

export default ProductsListItem;