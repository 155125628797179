import React, { Fragment, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-multi-lang';
import { Link, useParams } from 'react-router-dom';
import { getEventId, getEventLocations,  getMaterialsList } from '../../axios/study';
import CustomPopupLogin from '../../components/Popup/CustomPopupLogin';
import CustomPopupRegister from '../../components/Popup/CustomPopupRegister';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import { Loader } from '../../components/Loader/Loader';

const StudyEvent = () => {

  const param = useParams();
  const t = useTranslation();
  const [loading, setLoading] = useState(false);
  const [isOpenLogin, setIsOpenLogin] = useState(false);
  const [isOpenRegister, setIsOpenRegister] = useState(false);
  const [userInfo, setUserInfo] = useState(false);
  
  const [eventInfo, setEventInfo] = useState(false);
  const [eventLocations, setEventLocations] = useState(false);
  const refStudy =  useRef(null);

  const [optionMaterials, setOptionMaterials] = useState(null);

  const [tabIndex, setTabIndex] = useState(0);

  useEffect(()=>{
    refStudy.current.scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" });
  }, [])

  useEffect(()=>{

    if (localStorage.getItem('user_id') !== null) {
      // пошук чи до юзера існує прив'язка події
      const isEvent = JSON.parse(localStorage.getItem('events')).find(event => event.id === eventInfo.id) !== undefined;
      
      const userData = {
        name: localStorage.getItem('user_name'),
        location: JSON.parse(localStorage.getItem('location')),
        events: JSON.parse(localStorage.getItem('events')),
        specialty: JSON.parse(localStorage.getItem('specialty')),
        specialties: JSON.parse(localStorage.getItem('specialties')),
      }
      if(isEvent) {
        setUserInfo(userData);
      }
      else {
        setUserInfo(false);
      }
    }
    else {
      setUserInfo(false);
    }
  }, [isOpenLogin, isOpenRegister, eventInfo.id])

  const popupReg = (e) => {
    e.preventDefault();
    setIsOpenRegister(!isOpenRegister);
  }

  const popupLog = (e) => {
    e.preventDefault();
    setIsOpenLogin(!isOpenLogin);
  }

  // const showCurrentYear = () => {
  //   const currentYear = new Date().getFullYear();
  //   return currentYear;
  // };

  // вибрати список матеріалів
  useEffect(()=>{
    let active = false;
    getMaterialsList(param.id).then(response => {
      const data = response['hydra:member'];
      if (userInfo) {
        // показати матеріали тільки по спеціальностях юзера з даної події
        const allSpecUser = [...userInfo?.specialties, userInfo?.specialty];
        const filtredMaterials = allSpecUser.map(spc => {
          return data.filter(material => {
            return material.specialty.find(spec => spec.id === spc.id)
          })
        }).flat();
        // показати матеріали по спеціальностях та підрозділу юзера з даної події
        const filtredMaterialsLoc = filtredMaterials.filter(material => {
          return material.location.find(loc => loc.id === userInfo?.location?.id)
        })
        if (!active) {
          setOptionMaterials(filtredMaterialsLoc);
        }
      }
      else {
        setOptionMaterials([]);
      }
    });
    return () => {
      active = true;
    };
  }, [param.id, userInfo])

  // задати інфо по події 
  useEffect(()=>{
    let active = false;
    setLoading(true); // loader на підвантаження події
    getEventId(param.id).then(response => {
      if (!active) {
        setEventInfo(response);
      }
    }).finally(()=>{
      setLoading(false); 
    })
    return () => {
      active = true;
    };
  }, [param.id])

  // задати інфо-локацію по події / відкрити вкладку юзеру
  useEffect(()=>{
    let active = false;

    getEventLocations(param.id).then(response => {
      const data = response['hydra:member'];
      // пошук поточної вкладки для прив'язаного розділу під споживача
      const currentIndex = data.indexOf(data.find(events => events.location.id === userInfo?.location?.id)); 
      if (!active) {
        setEventLocations(data);
        setTabIndex(currentIndex > 0 ? currentIndex : 0);
      }
    })
    return () => {
      active = true;
    };
  }, [param.id, userInfo])
  
  
  return (
    <>
    <section className="study" ref={refStudy}>
        <div className="container">
        <div className="study__top">
          {loading &&
              <div className="loader-study"> 
                <Loader/>
              </div>}
            <div className="study__top-title">
              {/* <div className="study__top-title--year">{showCurrentYear()}</div> */}
              <div className="study__top-title--text">
                {eventInfo.name}
              </div>
            </div>
            <div className="study__top-entrance entrance">
            {!userInfo ? 
            <>
              <Link to="/#" className="entrance__btn" onClick={popupLog}>
                <div className="study__top-entry--img entrance__img entrance__img--entry"></div>
                <div className="study__top-entry--text entrance__text">
                  {t("studyMain.enter")}
                </div>
              </Link>
              <Link to="#" className="entrance__btn" onClick={popupReg}>
                <div className="study__top-reg--img entrance__img entrance__img--reg"></div>
                <div className="study__top-reg--text entrance__text">
                  {t("studyMain.register")}
                </div>
              </Link>
            </> 
            : <>
                <Link to="#" className="entrance__btn"onClick={()=> {
                  const storeLang = localStorage.getItem('lang');
                  localStorage.clear();
                  localStorage.setItem('lang', storeLang);
                  setUserInfo(false); 
                }}>
                  <div className="study__top-reg--img entrance__img entrance__img--reg"></div>
                  <div className="study__top-reg--text entrance__text">
                    Вихід
                  </div>
                </Link>
                <p>{userInfo.name}</p>
              </>
            }
            </div>
          </div>
              <div className="study__content-event">
              {eventInfo && 
                <div className='event-main-description' dangerouslySetInnerHTML={{ __html: eventInfo.description}}></div>
              }
              {/* {eventInfo &&
              <div className='event-location'>
                <h3>Де проводиться:</h3>
                <ul>
                    {eventInfo.location.map(loc => {
                        return <li key={loc.id} className={eventInfo.location.find(events => {return events.id === userInfo?.location?.id})?.id === loc.id? 'location active' : 'location'} >
                            {loc.name}
                        </li>
                    })}
                </ul>
              </div>}  -- виведення списком підрозділів де проводиться*/} 

              {optionMaterials &&
              <div className='event-materials'>
                <h3>Список матеріалів:</h3>
                  {userInfo ? <div>
                    {optionMaterials.map(material => {
                        return <Fragment key={material.id}>
                                <h4 key={material.id}>
                                    {material.name}
                                </h4>
                                <div dangerouslySetInnerHTML={{ __html: material.description}}></div>
                              </Fragment>
                      })}
                    </div>
                  : <p>
                      <Link to={'#'} onClick={popupLog}>Увійдіть</Link> щоб переглянути матеріали.
                    </p> }
              </div>}
              {eventLocations &&
              <div className='event-grafics'>
                <h3>Графік проведення навчань:</h3>
                  <Tabs
                    selectedIndex={tabIndex} 
                    onSelect={(index) => setTabIndex(index)}
                  >
                    <TabList>
                      {eventLocations.map(eventLoc => {
                        return <Tab key={eventLoc.id}>{eventLoc.location.name}</Tab>
                      })}
                    </TabList>

                    {eventLocations.map(eventLoc => {
                        return <TabPanel key={eventLoc.id}>
                                    <div dangerouslySetInnerHTML={{ __html: eventLoc.description}}></div>
                                </TabPanel>
                      })}
                  </Tabs>
              </div>}
            </div>
        </div>
    </section>
      <CustomPopupRegister 
        open={isOpenRegister} 
        setIsOpen= {setIsOpenRegister} 
        setIsOpenLogin = {setIsOpenLogin}  
        eventInfo = {eventInfo} 
        eventLocations = {eventLocations} 
      />
      <CustomPopupLogin open={isOpenLogin} setIsOpen= {setIsOpenLogin} сurrentEvent = {eventInfo.id} />
    </>
  )
}

export default StudyEvent