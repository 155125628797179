import React from "react";
import { Link } from "react-router-dom";

export const EnergyList = ({list}) => {
    return (
        <ol>
            {list.map((item, index)=> {
                return  <li key={index}><Link target="_blank" to={item.value}>
                            <span>{index + 1}</span> {item.key}
                            <img src="/media/images/energy/list-arrow.svg" alt="list" />
                        </Link></li>
            })}
        </ol>
    );
}