import React, { useEffect, useRef, useState } from "react";
import "./ProductList.scss";
import { Link } from "react-router-dom";
import logoDiamant from "./../../media/images/icons/LogoDiamant.png";
// import compost from "./../../media/images/icons/compost.png";
// import molasses from "./../../media/images/icons/molasses.png";
import logoRadokorm from "./../../media/images/icons/LogoRadocorm.png";
import { VscLinkExternal } from "react-icons/vsc";

const ProductList = ({ translate }) => {
  // const [productList, setProductList] = useState(productArr || []);
  // const [productsShow, setProductsShow] = useState(false);
  // const menuref = useRef(null);

  return (
    <div className="header">
      {/* {productArr.length > 0 && ( */}
        <div className="header__wrapper">
          <div className="header__title">
            <Link
              to={"https://diamant-sugar.com.ua/"}
              className="header__wrapperText2"
              target="_blank"
              rel="noopener noreferrer"
            >
            <img src={logoDiamant} alt="1" width={50} height={40}/>
              <p>{translate("header.title1.sub0")}</p>
              <div className="header__icon">
                <VscLinkExternal />
              </div>
            </Link>
          </div>
          {/* <ul className="header__list">
            {productArr.map((product) => {
              return (
                <li className="header__list-first" key={product.id}>
                  <p className="header__list-first-title">
                    {product.title}
                    <span> {product.submenu.length || 0}</span>
                    <img src="/img/arrowmenu.svg" alt="arrow" />
                  </p>
                  <div className="header__list-second-outer">
                    <ul className="header__list-second-inner">
                      {product.submenu.map((sub) => {
                        return (
                          <li key={sub.path}>
                            <Link
                              to={"https://diamantsugar.inneti.net" + sub.path}
                            >
                              <img
                                src={sub.img}
                                alt="prod"
                                width={64}
                                height={78}
                              />
                              <p className="sub-title">{sub.title}</p>
                              <p className="sub-weight">{sub.weight}</p>
                            </Link>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                </li>
              );
            })}
          </ul> */}
          <div className="header__title">
            <Link
              to={"https://radocorm.com.ua/"}
              className="header__wrapperText2"
              target="_blank"
              rel="noopener noreferrer"
            >
            <img src={logoRadokorm} width={50} alt="1" height={30} />
              <p>
                {translate("header.title1.sub1") +
                  ", " +
                  translate("header.title1.sub2").toLowerCase()}
              </p>
              <div className="header__icon">
                <VscLinkExternal />
              </div>
            </Link>
          </div>
          {/* <ul className="header__list2">
            <li className="header__list2-first2">
              <div className="header__list2-first2-title2">
                <Link
                  to={"https://radocorm-diamant.inneti.net/compost"}
                  className="header__wrapperTextLink2"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={compost} width={40} alt="1" />
                  <p>Жом</p>
                  <VscLinkExternal style={{ marginRight: "10px" }} />
                </Link>
              </div>
            </li>
            <li className="header__list2-first2">
              <div className="header__list2-first2-title2">
                <Link
                  to={"https://radocorm-diamant.inneti.net/molasses"}
                  className="header__wrapperTextLink2"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={molasses} width={40} alt="1" />
                  <p>Меляса</p>
                  <VscLinkExternal style={{ marginRight: "10px" }} />
                </Link>
              </div>
            </li>
          </ul> */}
        </div>
      {/* )} */}
    </div>
  );
};

export default ProductList;
