import React from "react";
import css from "./SocialList.module.scss";
import { ReactComponent as MessageIcon } from "../../../../../media/images/icons/message.svg";
import { ReactComponent as FaceBookIcon } from "../../../../../media/images/icons/faceBook.svg";
import { ReactComponent as TikTokIcon } from "../../../../../media/images/icons/tikTok.svg";
import { ReactComponent as InstagramIcon } from "../../../../../media/images/icons/instagram.svg";

import { useTranslation } from 'react-multi-lang'


const SocialList = () => {
  const t = useTranslation();
  return (
    <section className={css["contacts_social__wrapper"]}>
      <MessageIcon className={css["messageIcon"]}/>

      {/* <p>Залишайтеся на зв’язку</p> */}
      <p>{t('socialList')}</p>
      <ul className={css["social__list"]}>
        <li>
          <a
            href="https://www.facebook.com/Diamant.tsukor"
            target="_blank"
            rel="noreferrer"
          >
            <FaceBookIcon className={css["icons"]}/>
          </a>
        </li>
        <li>
          <a href="https://www.instagram.com/sugar_diamant/" target="_blank" rel="noreferrer">
            <InstagramIcon className={css["icons"]}/>
          </a>
        </li>
        <li>
          <a
            href="https://www.tiktok.com/@sugar.diamant"
            target="_blank"
            rel="noreferrer"
          >
            <TikTokIcon className={css["icons"]}/>
          </a>
        </li>
      </ul>
    </section>
  );
};

export default SocialList;
