import React, { useEffect, useState } from "react";
import Dropdown_2 from "./Dropdown_2";
import { Link } from "react-router-dom";

// Пункти навігації першого рівня
const DropdownItem_1 = (props) => {
  // console.log('props DropdownItem_2', props);

  // item:hover - при наведені курсора на пункт меню першого рівня
  const [itemHover_1, setItemHover_1] = useState(false);

  // При переході на іншу вкладку навігації - закрити попередню активну вкладку (свого рівня)
  useEffect(() => {
    // console.log('props.activeItem', props.activeItem);
    if (
      itemHover_1 !== false &&
      props.activeItem_1.title !== props.item.title
    ) {
      props.setActiveItem_1(false);
    }
  }, [props.activeItem_1, itemHover_1]);

  return (
    <li
      className={`menu__item-second ${
        props.activeItem_1.title === props.item.title ? "active" : ""
      }`}
      onClick={() => {
        props.setActiveItem_1(props.item);
        props.item.submenu === undefined && props.closeMobile();
      }}
      onMouseEnter={() => setItemHover_1(props.item)}
      onMouseLeave={() => setItemHover_1(false)}
    >
      {props.item.pages && (
        <div className="menu__link-second">
          <div className="menu__link-second--text">{props.item.title}</div>
          <div className="menu__link-second--count">
            {props.item.pages !== undefined &&
              props.item.pages.length > 0 &&
              props.item.pages.length}
          </div>
        </div>
      )}

      {props.item.submenu && (
        <div className="menu__link-second">
          <div className="menu__link-second--text">
            {props.translate(
              "header.title" + props.parentIndex + ".sub" + props.index
            )}
          </div>
          {/* <div className="menu__link-second--count">
                        {(props.item.submenu !== undefined && props.item.submenu.length > 0) && (props.item.submenu.length)}
                    </div> */}
          <div className="menu__link-second--arr">
            {props.item.submenu !== undefined &&
              props.item.submenu.length > 0 && (
                <>
                  <div className="arrow-active">
                    <svg
                      width="33"
                      height="16"
                      viewBox="0 0 33 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M26.6194 12.5467C26.4928 12.5467 26.3661 12.5001 26.2661 12.4001C26.0728 12.2067 26.0728 11.8867 26.2661 11.6934L29.9594 8.00008L26.2661 4.30674C26.0728 4.11341 26.0728 3.79341 26.2661 3.60008C26.4594 3.40674 26.7794 3.40674 26.9728 3.60008L31.0194 7.64674C31.2128 7.84008 31.2128 8.16008 31.0194 8.35341L26.9728 12.4001C26.8728 12.5001 26.7461 12.5467 26.6194 12.5467Z"
                        fill="#676D73"
                      />
                      <path
                        d="M30.552 8.5H0.5C0.226667 8.5 0 8.27333 0 8C0 7.72667 0.226667 7.5 0.5 7.5H24.942H30.552C30.8254 7.5 31.052 7.72667 31.052 8C31.052 8.27333 30.8254 8.5 30.552 8.5Z"
                        fill="#676D73"
                      />
                    </svg>
                  </div>
                  <div className="arrow">
                    <svg
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M9.61943 12.5467C9.49276 12.5467 9.36609 12.5001 9.26609 12.4001C9.07276 12.2067 9.07276 11.8867 9.26609 11.6934L12.9594 8.00008L9.26609 4.30674C9.07276 4.11341 9.07276 3.79341 9.26609 3.60008C9.45943 3.40674 9.77943 3.40674 9.97276 3.60008L14.0194 7.64674C14.2128 7.84008 14.2128 8.16008 14.0194 8.35341L9.97276 12.4001C9.87276 12.5001 9.74609 12.5467 9.61943 12.5467Z"
                        fill="#C2C7CC"
                      />
                      <path
                        d="M13.554 8.5H2.33398C2.06065 8.5 1.83398 8.27333 1.83398 8C1.83398 7.72667 2.06065 7.5 2.33398 7.5H13.554C13.8273 7.5 14.054 7.72667 14.054 8C14.054 8.27333 13.8273 8.5 13.554 8.5Z"
                        fill="#C2C7CC"
                      />
                    </svg>
                  </div>
                </>
              )}
          </div>
        </div>
      )}

      {!props.item.submenu && !props.item.pages && (
        <Link to={props.item.href} className="menu__link-second">
          {props.translate(
            "header.title" + props.parentIndex + ".sub" + props.index
          )}
        </Link>
      )}

      {/* Випадаючий список */}
      {props.item !== undefined && props.item.submenu !== undefined && (
        <Dropdown_2
          closeMobile={props.closeMobile}
          item={props.item}
          activeItem_1={props.activeItem_1}
        />
      )}
    </li>
    // <p>asdasdsd</p>
  );
};

export default DropdownItem_1;
