import React from "react";
import css from "./ServicesList.module.scss";
import { ReactComponent as LongArrow } from "../../../../media/images/icons/longArrow.svg";
import { NavLink } from "react-router-dom";
import { useTranslation } from 'react-multi-lang'

const ServicesList = ({ services }) => {
  const t = useTranslation();

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  return (
    <section className={css["servicesListWrapper"]}>
      {services.map((service) => (
        <div className={css["servicesCard"]} key={service.number}>
          <h2>
            {/* <span>{service.number}</span> {service.title} */}
            <span>{service.number}</span> {t(service.title)}
          </h2>
          <ul>
            {service.arrServices.map((item, index) => (
              <li key={index}>
                <NavLink to={item.path} target={item.target || '_self'} onClick={scrollToTop}>{t(item.name)}</NavLink>
                <LongArrow />
              </li>
            ))}
          </ul>
        </div>
      ))}
    </section>
  );
};

export default ServicesList;
