import React from "react";
import css from "./Footer.module.scss";
import ServicesList from "./componentsFooter/ServicesList";
import allDataFooter from "./componentsFooter/dataFooter";
import AboutUs from "./componentsFooter/AboutUs/AboutUs";
import Contacts from "./componentsFooter/Contacts/Contacts";
import Slider from "./componentsFooter/Slider/Slider";
import LogoInfo from "./componentsFooter/LogoInfo/LogoInfo";
import Container from "../Container/Container";

const { ourServices, infoAboutUs, contacts, partners, communication } =
  allDataFooter;

const Footer = () => {
  return (
    <div className={css["footerWrapper"]}>
      <Container>
        <ServicesList services={ourServices} />
        <AboutUs infoAboutUs={infoAboutUs} communication={communication} />
        <Contacts contacts={contacts} />
        <Slider slides={partners} />
        <LogoInfo />
      </Container>
    </div>
  );
};

export default Footer;
