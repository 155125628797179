import React from "react";
import css from "./ContactsList.module.scss";
import { ReactComponent as LocationIcon } from "../../../../../media/images/icons/location.svg";
import { ReactComponent as EmailIcon } from "../../../../../media/images/icons/email.svg";
import { ReactComponent as PhoneIcon } from "../../../../../media/images/icons/phone.svg";
import { ReactComponent as CopyIcon } from "../../../../../media/images/icons/copy.svg";
import SocialList from "../SocialList";

import { useTranslation } from 'react-multi-lang'


const ContactsList = ({ contacts }) => {
  const t = useTranslation();
  const handleCopy = (e, text) => {
    e.preventDefault();

    navigator.clipboard.writeText(text);
    e.stopPropagation();
  };

  return (
    <div className={css["contactsWrapper"]}>
      {/* location */}

      <div className={css["wrapper__location"]}>

        <div className={css["wrapper__iconLocation"]}>

  
          <LocationIcon className={css["iconTitle"]} />
          <EmailIcon className={css["iconTitle"]} />
        </div>
       

        <ul className={css["location__list"]}>
          {contacts.map((contact, index) => (
            <li className={css["location__element"]} key={index}>
              <a
                className={css["location__link"]}
                href={contact.location}
                target="_blank"
                rel="noopener noreferrer"
              >
                <span className={css["textDecorLocation"]}>
                  <LocationIcon className={css["decor__mobile"]} /> &nbsp;
                  {t(contact.title)}
                </span>
                &nbsp;
                {/* <span className={css["textDecorWord"]}>підрозділ</span> */}
                <span className={css["textDecorWord"]}>{t('footer.contacts.department')}</span>
              </a>

              <div className={css["wrapper__email"]}>
                <a 
                className={css["email__link"]} href={contact.email} target="_blank" rel="noreferrer">
                  <span className={css["email_decor"]}>
                    <EmailIcon className={css["decor__mobile"]} /> &nbsp;
                    {contact.emailDecor[0]}
                  </span>
                  <span className={css["email_decorAddress"]}>
                    {contact.emailDecor[1]}
                  </span>
                </a>

                {contact.email && (
                  <button>
                    <CopyIcon
                      className={css["iconCopy"]}
                      onClick={(e) => handleCopy(e, contact.email)}
                    />
                  </button>
                )}
              </div>
            </li>
          ))}
        </ul>
      </div>

      {/* phone */}
      <div className={css["wrapper__phone"]}>
        <PhoneIcon className={css["iconTitle"]} />
        {/* <PhoneIcon className={css["decor__mobile"]} /> &nbsp; */}
        <ul className={css["phone__list"]}>
          {contacts.map((contact, index) => (
            <li className={css["phone__el"]} key={index}>

              <a className={css["number"]} href={'tel:' + contact.phone}>

                <span className={css["contacts_phone__decor0"]}>
                  {contact.phoneDecor[0]} &nbsp;
                </span>
                <span className={css["contacts_phone__decor1"]}>
                  {contact.phoneDecor[1]} &nbsp;
                </span>
                <span className={css["contacts_phone__decor2"]}>
                  {contact.phoneDecor[2]}
                </span>


              </a>

              {contact.phone && (
                <button>
                  <CopyIcon
                    className={css["iconCopy"]}
                    onClick={(e) => handleCopy(e, contact.phone)}
                  />
                </button>
              )}
            </li>
          ))}
        </ul>

      </div>
        <SocialList  className={css["wrapper__social"]}/>
    </div>
  );
};

export default ContactsList;
