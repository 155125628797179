import React from "react";
import { useState } from "react";
import { ClientPopUp } from "./ClientPopUp";

export const ClientRoom = ({ translate, type }) => {
  const [showPop, setShowPop] = useState(false);

  const dataCabinet = {
    "/clients": {
      url1: "http://opt.diamantsugar.com.ua/en-us/site/login",
      url2: "http://opt.diamantsugar.com.ua/uk-ua/site/register",
      name: "header.cabinets.sub0",
    },
    "/workers": {
      url1: "https://www.diamantsugar.com.ua/ua/event-members/authentication/8",
      url2: "https://www.diamantsugar.com.ua/ua/event-members/registration/8",
      name: "header.cabinets.sub1",
    },
    "/fermers": {
      url1: "https://diamantsugar.com.ua/ua/user/login",
      url2: "https://diamantsugar.com.ua/ua/user/login",
      name: "header.cabinets.sub2",
    },
    "/drivers": {
      url1: "https://diamantsugar.com.ua/itender/login",
      url2: "https://diamantsugar.com.ua/itender/registration",
      name: "header.cabinets.sub3",
    },
    "/partners": {
      url1: "https://pidriadnyk.diamantsugar.com.ua/",
      url2: "https://pidriadnyk.diamantsugar.com.ua/",
      name: "header.cabinets.sub4",
    },
  };
  // dadsd

  return type !== undefined && dataCabinet[type.path] != undefined ? (
    <>
      <div className="room">
        <div
          className="room__title"
          onClick={() => {
            setShowPop(!showPop);
          }}
        >
          <div className="room__title--img"></div>
          <div className="room__title--text">
            {translate(dataCabinet[type.path].name).split(" ")[0]}
            &nbsp;
            <span style={{ fontWeight: "700" }}>
              {translate(dataCabinet[type.path].name).split(" ")[1]}
            </span>
          </div>
        </div>
      </div>
      {showPop && type ? (
        <ClientPopUp
          type={type}
          dataCabinet={dataCabinet}
          cabinetName={translate(dataCabinet[type.path].name)}
          translate={translate}
        />
      ) : (
        ""
      )}
    </>
  ) : (
    ""
  );
};
