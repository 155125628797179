import React, { useEffect, useRef, useState } from "react";
import css from "./Page.module.scss";
import Container from "../../components/Container/Container";
import { getNewsItem } from "../../axios/news";
import * as dayjs from "dayjs";
import { useParams } from "react-router-dom";
import { Breadcrumbs } from "../../components/Breadcrumps";
import routes from "../../routes";
import * as DOMPurify from "dompurify";
import { getPageItem } from "../../axios/pages";
import styles from "./Page.module.scss";

const Pages = () => {
  // console.log(111111);
  const param = useParams();
  const [newsItem, SetNewsItem] = useState({});
  const [loading, SetLoading] = useState(true);
  // console.log(param.id);
  useEffect(() => {
    let active = false;
    window.scrollTo({
      top: 500,
      behavior: "smooth",
    });

    getPageItem(SetLoading, param.id).then((response) => {
      const newsItem = {
        id: response.id,
        title: response.name,
        date: dayjs(response.date).format("DD.MM.YYYY"),
        content: response.content,
        imgURL: response.images,
        active: response.active,
      };

      if (!active) {
        SetNewsItem(newsItem);
      }
    });

    return () => {
      active = true;
    };
  }, [param.id]);

  return (
    <div className={css["NewsWrapper"]}>
      <Container>
        <div className={css["news-page"]}>
          <div className={css["wrapper"]}>
            <div className={css["news-page__inner"]}>
              {newsItem.id === 1 ? (
                <>
                  <div className={styles.container}>
                    <div className={styles.container__a1}>
                      <h3>Шановні партнери!</h3>
                      <p>
                        Компанія тзов "Радехівський цукор", запрошує вас на
                        семінар навчання
                      </p>

                      <p className={styles.container__span1}>
                        Адреса та час проведення зустрічі
                      </p>

                      <p>
                        <strong>22.02.24:</strong> Тернопільська обл., м.
                        Хоростків, вул. Музейна, 1а,
                        <br /> у закладі «Graf palace»
                      </p>
                      <p>
                        <strong>23.02.24:</strong> Львівська обл., м. Радехів,
                        вул. Стоянівська, 31, в готелі «Ліжниця»
                      </p>
                    </div>

                    <div className={styles.container__a2}>
                      <div className={styles.container__a2__b1}>
                        <h2>
                          Обговореняя економічних та технологічних <br />
                          процесів у вирощуванні цукрових буряків <br />
                          2024 року
                        </h2>
                      </div>
                      <div className={styles.container__a2__b2}>
                        <p>
                          Тернопільська обл., м. Хоростків,
                          <br /> вул. Музейна, 1а, у закладі «Graf palace»
                        </p>
                        <p>
                          <strong>22.02.24</strong>
                        </p>
                      </div>
                    </div>
                    {/*================================a3========================================= */}
                    <div className={styles.container__a3}>
                      <div className={styles.container__a3__b1}>
                        <div className={styles.container__a3__b1__c1}>
                          <div className={styles.container__a3__b1__c1__d1}>
                            <p>
                              <strong>09.00-09.30</strong>
                            </p>
                          </div>
                          <div className={styles.container__a3__b1__c1__d2}>
                            <p> Збір учасників.Ранкова кава.</p>
                          </div>
                        </div>
                      </div>

                      {/*===================================b2==============================*/}
                      <div className={styles.container__a3__b2}>
                        <div className={styles.container__a3__b2__c1}>
                          <div className={styles.container__a3__b2__c1__d1}>
                            <p>
                              <strong>12.30-13.00</strong>
                            </p>
                          </div>
                          <div className={styles.container__a3__b2__c1__d2}>
                            <p> Вступне слово.</p>
                          </div>
                        </div>

                        <div className={styles.container__a3__b2__c2}>
                          <div className={styles.container__a3__b2__c2__d1}>
                            <p>
                              <strong>Євген Марцінко</strong>
                              -заступник головного директора <br /> по сировині
                              тзов "Радехівський цукор"
                            </p>
                          </div>
                          {/* <div className={styles.container__a3__b2__c2__d2}>
              Збір учасників.Ранкова кава.
            </div> */}
                        </div>
                      </div>

                      {/*==========================b3================================*/}

                      <div className={styles.container__a3__b2}>
                        <div className={styles.container__a3__b2__c1}>
                          <div className={styles.container__a3__b2__c1__d1}>
                            <p>
                              <strong>13.00-13.30</strong>
                            </p>
                          </div>
                          <div className={styles.container__a3__b2__c1__d2}>
                            <p>
                              Вплив природних факторів та агротехнічних заходів
                              на
                              <br /> врожайність цукрових буряків у 2023 р
                            </p>
                          </div>
                        </div>

                        <div className={styles.container__a3__b2__c2}>
                          <div className={styles.container__a3__b2__c2__d1}>
                            <p>
                              <strong>Дмитро Костарєв</strong>— керівник
                              аналітичного відділу пп <br /> «західний буг"
                            </p>
                          </div>
                          {/* <div className={styles.container__a3__b2__c2__d2}>
              Збір учасників.Ранкова кава.
            </div> */}
                        </div>
                      </div>

                      {/*=============================b4===========================*/}
                      <div className={styles.container__a3__b2}>
                        <div className={styles.container__a3__b2__c1}>
                          <div className={styles.container__a3__b2__c1__d1}>
                            <p>
                              <strong>13.40-14.30</strong>
                            </p>
                          </div>
                          <div className={styles.container__a3__b2__c1__d2}>
                            <p>
                              Економічні умови виробництва основних <br />
                              сільськогосподарських культур у польщі.
                            </p>
                          </div>
                        </div>

                        <div className={styles.container__a3__b2__c2}>
                          <div className={styles.container__a3__b2__c2__d1}>
                            <p>
                              <strong>Мацей Грубельний</strong>— директор по
                              сировині компанії
                              <br />
                              pfeifer & langen polska sa
                            </p>
                          </div>
                          {/* <div className={styles.container__a3__b2__c2__d2}>
              Збір учасників.Ранкова кава.
            </div> */}
                        </div>
                      </div>

                      {/*====================================b5================================*/}
                      <div className={styles.container__a3__b2}>
                        <div className={styles.container__a3__b2__c1}>
                          <div className={styles.container__a3__b2__c1__d1}>
                            <p>
                              <strong>14.30-15.00</strong>
                            </p>
                          </div>
                          <div className={styles.container__a3__b2__c1__d2}>
                            <p>
                              Важливі аспекти передпосівного обробітку ґрунту.
                            </p>
                          </div>
                        </div>

                        <div className={styles.container__a3__b2__c2}>
                          <div className={styles.container__a3__b2__c2__d1}>
                            <p>
                              <strong>Вільховий Петро</strong> — начальник
                              агрономічного відділу <br /> тзов «радехівський
                              цукор»
                            </p>
                          </div>
                          {/* <div className={styles.container__a3__b2__c2__d2}>
              Збір учасників.Ранкова кава.
            </div> */}
                        </div>
                      </div>

                      {/*=====================================b6=======================*/}
                      <div className={styles.container__a3__b2}>
                        <div className={styles.container__a3__b2__c1}>
                          <div className={styles.container__a3__b2__c1__d1}>
                            <p>
                              <strong>15.00-16.30</strong>
                            </p>
                          </div>
                          <div className={styles.container__a3__b2__c1__d2}>
                            <p>
                              Практичні поради щодо налаштування бурякових{" "}
                              <br /> сівалок kverneland
                            </p>
                          </div>
                        </div>

                        <div className={styles.container__a3__b2__c2}>
                          <div className={styles.container__a3__b2__c2__d1}>
                            <p>
                              <strong>Бондар Володимир</strong> — регіональний
                              керівник сервісної <br />
                              служби нфм агро
                              <br />
                              <strong>Марковський Максим</strong> — регіональний
                              керівник з
                              <br />
                              продажів с/г техінки нфм агр
                            </p>
                          </div>
                          {/* <div className={styles.container__a3__b2__c2__d2}>
              Збір учасників.Ранкова кава.
            </div> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                <div
                  className={css["news-page__content"]}
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(newsItem.content),
                  }}
                ></div>
              )}
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default Pages;
