import { AxiosInstance } from "./axios";

export const getProductList = async ()=>{
    const productList = await AxiosInstance.get(`/categories?page=1&type=category`);
    return productList.data;
}

export const getProductItem= async (isLoading, param)=>{
    isLoading(true);
    const productItem = await AxiosInstance.get(`/products/${param}`);
    isLoading(false);
    return productItem.data;
}


export const getProductByName= async (isLoading, param)=>{
    isLoading(true);
    const productItem = await AxiosInstance.get(`/products?page=1&name=${param}`);
    isLoading(false);
    return productItem.data;
}
