import React from 'react'
import css from "./history.module.scss";
import HistoryElement from '../../components/History/HistoryElement/HistoryElement';
import { useTranslation } from "react-multi-lang";

const History = () => {
  const translate = useTranslation();

  const period = {
   before: [1867, 1873],
   after: [1952, 2023],
   show: [
    {num: 1870, title: translate('history.1870.title'), content: translate('history.1870.content'), type: 'main'},
    {num: 1954, title: translate('history.1954.title'), content: translate('history.1954.content'), type: 'main'},
    {num: 1955, title: translate('history.1955.title'), content: translate('history.1955.content'), type: 'main' },
    {num: 1958, title: translate('history.1958h.title'), content: translate('history.1958h.content'), type: 'main' },
    {num: 1958, title: translate('history.1958g.title'), content: translate('history.1958g.content'), type: 'main', component: true  },
    {num: 1959, content: translate('history.1959.content'), type: 'low'},
    {num: 1963, title: translate('history.1963.title'), content: translate('history.1963.content'), type: 'main'},
    {num: 1968, content: translate('history.1968.content'),  type: 'low'}, 
    {num: 1969, content: translate('history.1969.content'),  type: 'low'},
    {num: 1971, content: translate('history.1971.content'),  type: 'low'},
    {num: 1974, title: translate('history.1974.title'), content: translate('history.1974.content'), type: 'main', component: true },
    {num: 1975, content: translate('history.1975.content'),type: 'low'}, 
    {num: 1976, content: translate('history.1976.content'), type: 'low'},
    {num: 1977, title: translate('history.1977.title'), content: translate('history.1977.content'), type: 'main'},
    {num: 1980, content: translate('history.1980.content'), type: 'low'}, 
    {num: 1982, title: translate('history.1982.title'), content: translate('history.1982.content'), type: 'main' },
    {num: 1996, content: translate('history.1996.content'), type: 'low'},
    {num: 2003, content: translate('history.2003.content'), type: 'low'},
    {num: 2007, content: translate('history.2007.content'), type: 'low'},
    {num: 2010, title: translate('history.2010.title'), content: translate('history.2010.content'), type: 'main'},
    {num: 2011, content: translate('history.2011.content'), type: 'low'},
    {num: 2013, content: translate('history.2013.content'), type: 'low'},
    {num: 2014, content: translate('history.2014.content'), type: 'low'},  
    {num: 2023, title: translate('history.2023.title'), content: translate('history.2023.content'), type: 'main' }, 
  ],
  }

  const beforeArr = [];
  const afterArr = [];

  for(let i = period.before[0] + 1; i <= period.before[1]; i++){
   const show = period.show.filter(item => {
      return item.num === i;
    })
    if(show.length) {
      beforeArr.push({num: show[0].num, type: show[0].type, component:show[0].component, content: show[0].content, title: show[0].title || ''})
    }
    else {
      beforeArr.push({num: i, type: 'default'})
    }
  }

  // ============================

  for(let i = period.after[0]; i <= period.after[1]; i++){

    // повертаємо масив для рендру який проходить умову 
    const show = period.show.filter(item => {
      return item.num === i ;
    })
  
    if(show.length === 1) {
      afterArr.push({num: show[0].num, type: show[0].type, component:show[0].component, content: show[0].content, title: show[0].title || ''})
    }
    
    else if (show.length > 1) {
      afterArr.push({num: show[0].num, type: show[0].type, component:show[0].component, content: show[0].content, title: show[0].title || ''})
      afterArr.push({num: show[1].num, type: show[1].type, component:show[1].component, content: show[1].content, title: show[1].title || ''})
    }

    else {
      afterArr.push({num: i, type: 'default'})
    }
  }


  return (
    <section className={css["history"]}>
        <div className="container">

        <div className={css["period"]}>
          <svg xmlns="http://www.w3.org/2000/svg" width="2" height="82" viewBox="0 0 2 82" fill="none">
            <path d="M1 1L0.999994 82" stroke="url(#paint0_linear_460_8260)" strokeWidth="2" strokeLinecap="round"/>
            <defs>
            <linearGradient id="paint0_linear_460_8260" x1="0.99999" y1="82.074" x2="1" y2="1" gradientUnits="userSpaceOnUse">
            <stop stopColor="#B8C2CC"/>
            <stop offset="1" stopColor="#B8C2CC" stopOpacity="0"/>
            </linearGradient>
            </defs>
          </svg>
            <div className={css["period__number"]}>
            <svg xmlns="http://www.w3.org/2000/svg" width="2" height="12" viewBox="0 0 2 12" fill="none">
              <path d="M1 1L1.00019 00012" stroke="#A1AAB3" strokeWidth="2" strokeLinecap="round"/>
            </svg>
              <svg xmlns="http://www.w3.org/2000/svg" width="7" height="2" viewBox="0 0 7 2" fill="none">
                <path d="M1 1H6" stroke="#C2C7CC" strokeLinecap="round"/>
              </svg>
              {period.before[0]}
            </div>
        </div>
        {beforeArr.map((period, index)=>{
          return <HistoryElement key= {index} data={period}/>
        })}
        <div className={css["period"]}>
              <svg xmlns="http://www.w3.org/2000/svg" width="2" height="42" viewBox="0 0 2 42" fill="none">
                  <path d="M1 1L1.00019 00042" stroke="#A1AAB3" strokeWidth="2" strokeLinecap="round"/>
                  </svg>
                    <div className={css["period__number"]}>
                      <svg xmlns="http://www.w3.org/2000/svg" width="2" height="12" viewBox="0 0 2 12" fill="none">
                      <path d="M1 1L1.00019 00012" stroke="#A1AAB3" strokeWidth="2" strokeLinecap="round"/>
                    </svg>
                      <svg xmlns="http://www.w3.org/2000/svg" width="7" height="2" viewBox="0 0 7 2" fill="none">
                        <path d="M1 1H6" stroke="#C2C7CC" strokeLinecap="round"/>
                      </svg>
                       <p style={{position: 'relative', bottom: '5px', left: '5px', color: '#C2C7CC'}}>...</p>
              </div>
        </div>
        {afterArr.map((period, index)=>{
          return <HistoryElement key={index} data={period}/>
        })}
        <div className={css["period"]}>
          <svg xmlns="http://www.w3.org/2000/svg" width="2" height="82" viewBox="0 0 2 82" fill="none">
              <path d="M1 137L1.00001 1" stroke="url(#paint0_linear_453_7661)" strokeWidth="2" strokeLinecap="round"/>
              <defs>
              <linearGradient id="paint0_linear_453_7661" x1="1.00001" y1="7.92592" x2="0.999997" y2="82" gradientUnits="userSpaceOnUse">
              <stop stopColor="#B8C2CC"/>
              <stop offset="1" stopColor="#B8C2CC" stopOpacity="0"/>
              </linearGradient>
              </defs>
            </svg>
        </div>
        </div>
    </section>
  )
}

export default History