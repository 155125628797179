import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { getPageItem } from "../../axios/pages";
import Container from "../../components/Container/Container";
import css from "./Page.module.scss";

const PagesHead = () => {

  const param = useParams();
  const [newsItem, SetNewsItem] = useState({});
  const [loading, SetLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    let active = false;
    
    getPageItem(SetLoading, param.id).then((response) => {
      const newsItem = {
        title: response.name,
        imgURL: response.images,
      };

      if (!active) {
        SetNewsItem(newsItem);
      }
    }).catch(()=>{
      navigate('/');
    })

    return () => {
      active = true;
    };
  }, [param.id]);

  return (
    <div className={css["pages-head"]}>
      <Container>
        <div className={css["wrapper"]}>
          <h1>
            <span>{newsItem.title}</span>
          </h1>
          <img src={newsItem.imgURL} alt="page" />
        </div>
      </Container>
    </div>
  );
};

export default PagesHead;
