import { AxiosInstance } from "./axios";

export const getSocialList = async (isLoading, page)=>{
    isLoading(true);
    const newsList = await AxiosInstance.get(`/news?page=${page}&categories.id=7`);
    isLoading(false);
    return newsList.data;
}
// export const getNewsList = async (isLoading, page)=>{
//     isLoading(true);
//     const newsList = await AxiosInstance.get(`/news?page=${page}`);
//     isLoading(false);
//     return newsList.data;
// }
export const getNewsList = async (
  isLoading,
  page,
  itemsPerPage,
  categories
) => {
  isLoading(true);
  const newsList = await AxiosInstance.get(
    `/news?page=${page}&itemsPerPage=${itemsPerPage}${categories !== undefined ? `categories.id=${categories}` : ''}`
  );
  isLoading(false);
  return newsList.data;
};

export const getNewsItem = async (isLoading, param) => {
  isLoading(true);
  const newsItem = await AxiosInstance.get(`/news/${param}`);
  isLoading(false);
  return newsItem.data;
};
