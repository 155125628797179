import React, { useState } from 'react'
import css from "./historyelement.module.scss";
import HistoryModal from '../HistoryModal/HistoryModal';

const HistoryElement = ({data}) => {

  const [show, setShow] = useState(false);


  return (
    <div className={css["period"]}>
        <svg xmlns="http://www.w3.org/2000/svg" width="2" height="42" viewBox="0 0 2 42" fill="none">
        <path d="M1 1L1.00019 00042" stroke="#A1AAB3" strokeWidth="2" strokeLinecap="round"/>
        </svg>
        {data.type === 'default' &&
        <div className={css["period__number"]}>
          <svg xmlns="http://www.w3.org/2000/svg" width="2" height="12" viewBox="0 0 2 12" fill="none">
                <path d="M1 1L1.00019 00012" stroke="#A1AAB3" strokeWidth="2" strokeLinecap="round"/>
          </svg>
          <svg xmlns="http://www.w3.org/2000/svg" width="7" height="2" viewBox="0 0 7 2" fill="none">
              <path d="M1 1H6" stroke="#C2C7CC" strokeLinecap="round"/>
            </svg>
            <p className={css['default']}>{data.num}</p>
        </div>
        }
        {data.type === 'low' && 
        <div className={`${css["period__number"]} ${css['low']}`}>
            <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10" fill="none">
                <circle cx="5" cy="5" r="4" fill="#676D73" stroke="#F2F2F3" strokeWidth="2"/>
            </svg>
            <svg xmlns="http://www.w3.org/2000/svg" width="7" height="2" viewBox="0 0 7 2" fill="none">
            <path d="M1 1H6" stroke="#C2C7CC" strokeLinecap="round"/>
          </svg>
          <p className={css['low']}>{data.num}</p>
          <svg xmlns="http://www.w3.org/2000/svg" width="130" height="2" viewBox="0 0 130 2" fill="none">
            <path opacity="0.3" d="M1 1L129 1.00001" stroke="#C2C7CC" strokeLinecap="round"/>
          </svg>
          <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
           <circle cx="6" cy="6" r="4.5" fill="#676D73" stroke="#F2F2F3" strokeWidth="3"/>
          </svg>
          <p>{data.content}</p>
        </div>
        }
        {data.type === 'main' && 
        <div className={`${css["period__number"]} ${css['main']}`}>
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                <circle cx="8" cy="8" r="6.5" fill="#292E33" stroke="#F2F2F3" strokeWidth="3"/>
            </svg>
            <svg xmlns="http://www.w3.org/2000/svg" width="7" height="2" viewBox="0 0 7 2" fill="none">
            <path d="M1 1H6" stroke="#C2C7CC" strokeLinecap="round"/>
          </svg>
          <p className={css['main']}>{data.num}</p>
          <svg xmlns="http://www.w3.org/2000/svg" width="514" height="2" viewBox="0 0 514 2" fill="none">
            <path d="M1 1L513 1.00004" stroke="#292E33" strokeLinecap="round"/>
          </svg>
          <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="11" cy="11" r="7.5" fill="#292E33" stroke="#F2F2F3" strokeWidth="7"/>
            <circle cx="11" cy="11" r="7.5" stroke="#292E33"/>
          </svg>
          <div className={css["period__content"]}>
            <h3>{data.title}
              {data.component && <svg onClick={()=>{setShow(!show);}} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
              <path d="M19.59 5.85L13.65 2.42C12.68 1.86 11.48 1.86 10.5 2.42L4.57 5.85C3.6 6.41 3 7.45 3 8.58V15.42C3 16.54 3.6 17.58 4.57 18.15L10.51 21.58C11.48 22.14 12.68 22.14 13.66 21.58L19.6 18.15C20.57 17.59 21.17 16.55 21.17 15.42V8.58C21.17 7.45 20.56 6.42 19.59 5.85ZM13 16.63C12.95 16.75 12.88 16.86 12.79 16.96C12.6 17.15 12.35 17.25 12.08 17.25C11.95 17.25 11.82 17.22 11.7 17.17C11.57 17.12 11.47 17.05 11.37 16.96C11.28 16.86 11.21 16.75 11.15 16.63C11.1 16.51 11.08 16.38 11.08 16.25C11.08 15.99 11.18 15.73 11.37 15.54C11.47 15.45 11.57 15.38 11.7 15.33C12.07 15.17 12.51 15.26 12.79 15.54C12.88 15.64 12.95 15.74 13 15.87C13.05 15.99 13.08 16.12 13.08 16.25C13.08 16.38 13.05 16.51 13 16.63ZM14.54 10.78C14.22 11.26 13.9 11.56 13.63 11.83C13.37 12.08 13.15 12.3 12.98 12.59C12.9 12.73 12.85 12.86 12.82 13.09C12.77 13.47 12.47 13.74 12.1 13.74H12.07C11.87 13.74 11.67 13.65 11.54 13.5C11.4 13.35 11.34 13.15 11.35 12.95C11.37 12.73 11.43 12.41 11.6 12.12C11.83 11.72 12.17 11.4 12.49 11.1C12.75 10.86 13.02 10.61 13.22 10.32C13.49 9.94 13.52 9.41 13.31 9C13.09 8.57 12.64 8.34 12.06 8.34C11.39 8.34 10.99 8.73 10.78 9.05C10.66 9.24 10.45 9.35 10.21 9.35C10.11 9.35 10.01 9.33 9.92 9.29C9.72 9.2 9.56 9.03 9.5 8.82C9.44 8.62 9.48 8.43 9.59 8.27C10.16 7.45 11.03 6.99 12.04 6.99C13.13 6.99 14.04 7.44 14.52 8.24C14.84 8.79 15.11 9.92 14.53 10.78H14.54Z" fill="#C2C7CC"/>
              </svg> }
            </h3>
            <p>{data.content}</p>
      
          </div>
          {show && <HistoryModal id = {data.num} show = {setShow}/>}
        </div>
        
        }
    </div>
  )
}

export default HistoryElement