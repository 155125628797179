import React from "react";
import { useTranslation } from "react-multi-lang";
import { NavLink } from "react-router-dom";
import useReactRouterBreadcrumbs from "use-react-router-breadcrumbs";

export const Breadcrumbs = ({routes}) => {
    
  const breadcrumbs = useReactRouterBreadcrumbs(routes);
  const translate = useTranslation();
  // console.log(breadcrumbs);
  
  return (
    <React.Fragment>
    {breadcrumbs.map(({ match }) => (
        <React.Fragment key={match.pathname}>
        <NavLink to={match.pathname}>
            {(match != undefined && match.route != undefined && match.route.name !== undefined && match.route.name !== '') && translate(match.route.name)}
        </NavLink>
        {match.route.name.length > 0 &&
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none"><g opacity="0.5">
            <path d="M6.73969 12.2802C6.54969 12.2802 6.35969 12.2102 6.20969 12.0602C5.91969 11.7702 5.91969 11.2902 6.20969 11.0002L9.20969 8.00016L6.20969 5.00016C5.91969 
                4.71016 5.91969 4.23016 6.20969 3.94016C6.49969 3.65016 6.97969 3.65016 7.26969 3.94016L10.7997 7.47016C11.0897 7.76016 11.0897 8.24016 10.7997 8.53016L7.26969 
                12.0602C7.11969 12.2102 6.92969 12.2802 6.73969 12.2802Z" fill="white">
        </path></g></svg>}
        </React.Fragment>
      ))}
    </React.Fragment>
  );
};