import css from "./SocialActivity.module.scss";
import Container from "../../components/Container/Container";
// import allDataNews from "./dataNews/dataNews";
import SliderCard from "../../components/SliderCard";
import { useEffect, useState } from "react";
import { getNewsList, getSocialList } from "../../axios/news";
import * as dayjs from "dayjs";
// const { dataNews } = allDataNews;

const SocialActivity = () => {
  const [socialList, SetSocialList] = useState([]);
  const [loading, SetLoading] = useState(true);
  const [total, SetTotal] = useState(0);
  // індекс
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    let active = false;
    window.scrollTo({
      top: 800,
      behavior: "smooth",
    });

    // getNewsList(SetLoading, currentIndex + 1).then((response) => {
    getNewsList(SetLoading, 1, 300, 7).then((response) => {
      // console.log(response);
      const data = response["hydra:member"];
      const filtredData = data.filter((item) => {
        return item.categories.find((element) => element.slug === "social") !== undefined;
      });
      // console.log(filtredData);
      const socialData = filtredData.map((item) => {
        return {
          id: item.id,
          title: item.name,
          date: dayjs(item.date).format("DD.MM.YYYY"),
          description: item.newsShot,
          imgURL: item.video_img,
          active: item.active,
        };
      });
      if (!active) {
        SetSocialList(socialData);
        SetTotal(socialData.length);
      }
    });

    return () => {
      active = true;
    };
  }, []);

  return (
    <div className={css["NewsWrapper"]}>
      <Container>
        <SliderCard
          allArrCard={socialList}
          total={total}
          setCurrentIndex={setCurrentIndex}
          currentIndex={currentIndex}
          type={"social-activity"}
        />
      </Container>
    </div>
  );
};

export default SocialActivity;
