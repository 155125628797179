import { useState } from "react";
import css from "./Schedule.module.scss";
import { BsArrowLeft } from "react-icons/bs";
import { BsArrowRight } from "react-icons/bs";

const Schedule = ({ objInfo }) => {
  const [stateDate, setStateDate] = useState([]);
  // console.log(stateDate);

  const handleDateClick = (date) => {
    setStateDate((prevState) => {
      // Перевірка чи дата вже існує в стейті
      const isDateExists = prevState.includes(date);

      if (isDateExists) {
        // Якщо дата існує, повертаємо стейт без цієї дати
        return prevState.filter((item) => item !== date);
      } else {
        // Якщо дати немає, додаємо її до стейту
        return [...prevState, date];
      }
    });
  };

  return (
    <div className={css["wrrapperInfo"]}>
      <div className={css["wrappertitle"]}>
        <h2>{objInfo.title}</h2>
        <div className={css["decor__info"]}>
          <p className={css["decor__date"]}>Дата</p>
          <p className={css["decor__time"]}>Час</p>
          <p className={css["decor__theme"]}>Тема</p>
          <p className={css["decor__lecture"]}>Лектор</p>
        </div>
      </div>

      <div className={css["wrrapperInfo__byDate"]}>
        <ul className={css["list__byDate"]}>
          {objInfo.byDate.map((item, index) => (
            <li key={index} className={css["item__byDate"]}>
              {/* <span className={css["decor__text"]}>Дата</span> */}

              <p
                onClick={() => handleDateClick(item.date)}
                // className={css["text__byDate"]}
                className={`${
                  stateDate.includes(item.date)
                    ? css["text__byDate--active"]
                    : css["text__byDate"]
                }`}
              >
                {item.date}

                {stateDate.includes(item.date) ? (
                  <BsArrowLeft className={css["decor__arrowLeft"]}/>
                ) : (
                  <BsArrowRight className={css["decor__arrowRight"]}/>
                )}
              </p>

              {stateDate.includes(item.date) ? (
                <ul className={css["list__byTime"]}>
                  {item.byTime.map((el, index) => (
                    <li key={index} className={css["item__byTime"]}>
                      <span className={css["decor__text"]}>Час</span>
                      <p className={css["time__byTime"]}>{el.time}</p>

                      <span className={css["decor__text"]}>Тема лекції</span>

                      <p className={css["description__byTime"]}>
                        {el.description}
                      </p>
                      <span className={css["decor__text"]}>Лектор</span>
                      <p className={css["lecture__byTime"]}>{el.lecturer}</p>
                    </li>
                  ))}
                </ul>
              ) : (
                ""
              )}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default Schedule;
