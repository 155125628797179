import { nanoid } from "nanoid";

const lectureSchedule = [
  {
    title: "Радехівський підрозділ",
    titleDirections: "Напрямки",

    directions: [
      {
        name: "Слюсар-ремонтник",

        lectureSchedule: {
          title: "Графік проведення лекцій для слюсаря-ремонтника",
          byDate: [
            {
              date: "13.02.2023",
              byTime: [
                {
                  time: "08:00 — 10:00",
                  description:
                    "Будова, принцип роботи та різновиди насосів, обслуговування, експлуатація, ремонт. Види відцентрових насосів, класифікація відцентрових насосів. Принцип роботи, ремонт відцентрових насосів.",
                  lecturer: "Шайнога М.В.",
                },

                {
                  time: "12:30 — 13:00",
                  description: "Будова, принцип роботи, ремонт ВВН та ВГК.",
                  lecturer: "Ейсмонт О.Ю.",
                },

                {
                  time: "13:00 — 13:30",
                  description:
                    "Види ущільнення та сальникової набивки, застосування при проведенні ремонтних робіт.",
                  lecturer: "Яремчук М.В.",
                },

                {
                  time: "13:30 — 14:30",
                  description:
                    "Види редукторів, класифікація редукторів (шестеренчасті, черв′ячні). Призначення, принцип роботи, ремонт редукторів.",
                  lecturer: "Яремчук М.В.",
                },

                {
                  time: "14:30 — 15:00",
                  description:
                    "Муфти та механізми з’єднання валів. Вивірка співвісності валів (центровка півмуфт).",
                  lecturer: "Лоїк В.І.",
                },

                {
                  time: "15:00 — 16:30",
                  description:
                    "Призначення та класифікація підшипників. Приклади застосування підшипників в механізмах, вибір підшипників по навантаженню. Маркування та класи точності підшипників. Підшипники кочення. Загальні відомості, застосування, конструкція та матеріал підшипників ковзання.",
                  lecturer: "Шайнога М.В.",
                },
              ],
            },

            {
              date: "14.02.2023",
              byTime: [
                {
                  time: "08:00 — 10:00",
                  description:
                    "Будова, принцип роботи та різновиди насосів, обслуговування, експлуатація, ремонт. Види відцентрових насосів, класифікація відцентрових насосів. Принцип роботи, ремонт відцентрових насосів.",
                  lecturer: "Шайнога М.В.",
                },

                {
                  time: "12:30 — 13:00",
                  description: "Будова, принцип роботи, ремонт ВВН та ВГК.",
                  lecturer: "Ейсмонт О.Ю.",
                },

                {
                  time: "14:30 — 15:00",
                  description:
                    "Муфти та механізми з’єднання валів. Вивірка співвісності валів (центровка півмуфт).",
                  lecturer: "Лоїк В.І.",
                },

                {
                  time: "15:00 — 16:30",
                  description:
                    "Призначення та класифікація підшипників. Приклади застосування підшипників в механізмах, вибір підшипників по навантаженню. Маркування та класи точності підшипників. Підшипники кочення. Загальні відомості, застосування, конструкція та матеріал підшипників ковзання.",
                  lecturer: "Шайнога М.В.",
                },
              ],
            },
          ],
        },
      },

      {
        name: "Слюсар електромонтер",
        lectureSchedule: {
          title: "Графік проведення лекцій для слюсаря-ремонтника",
          byDate: [
            {
              date: "13.02.2023",
              byTime: [
                {
                  time: "08:00 — 10:00",
                  description:
                    "Будова, принцип роботи та різновиди насосів, обслуговування, експлуатація, ремонт. Види відцентрових насосів, класифікація відцентрових насосів. Принцип роботи, ремонт відцентрових насосів.",
                  lecturer: "Шайнога М.В.",
                },

                {
                  time: "12:30 — 13:00",
                  description: "Будова, принцип роботи, ремонт ВВН та ВГК.",
                  lecturer: "Ейсмонт О.Ю.",
                },

                {
                  time: "13:00 — 13:30",
                  description:
                    "Види ущільнення та сальникової набивки, застосування при проведенні ремонтних робіт.",
                  lecturer: "Яремчук М.В.",
                },

                {
                  time: "13:30 — 14:30",
                  description:
                    "Види редукторів, класифікація редукторів (шестеренчасті, черв′ячні). Призначення, принцип роботи, ремонт редукторів.",
                  lecturer: "Яремчук М.В.",
                },

                {
                  time: "14:30 — 15:00",
                  description:
                    "Муфти та механізми з’єднання валів. Вивірка співвісності валів (центровка півмуфт).",
                  lecturer: "Лоїк В.І.",
                },

                {
                  time: "15:00 — 16:30",
                  description:
                    "Призначення та класифікація підшипників. Приклади застосування підшипників в механізмах, вибір підшипників по навантаженню. Маркування та класи точності підшипників. Підшипники кочення. Загальні відомості, застосування, конструкція та матеріал підшипників ковзання.",
                  lecturer: "Шайнога М.В.",
                },
              ],
            },

            {
              date: "14.02.2023",
              byTime: [
                {
                  time: "08:00 — 10:00",
                  description:
                    "Будова, принцип роботи та різновиди насосів, обслуговування, експлуатація, ремонт. Види відцентрових насосів, класифікація відцентрових насосів. Принцип роботи, ремонт відцентрових насосів.",
                  lecturer: "Шайнога М.В.",
                },

                {
                  time: "12:30 — 13:00",
                  description: "Будова, принцип роботи, ремонт ВВН та ВГК.",
                  lecturer: "Ейсмонт О.Ю.",
                },

                {
                  time: "14:30 — 15:00",
                  description:
                    "Муфти та механізми з’єднання валів. Вивірка співвісності валів (центровка півмуфт).",
                  lecturer: "Лоїк В.І.",
                },

                {
                  time: "15:00 — 16:30",
                  description:
                    "Призначення та класифікація підшипників. Приклади застосування підшипників в механізмах, вибір підшипників по навантаженню. Маркування та класи точності підшипників. Підшипники кочення. Загальні відомості, застосування, конструкція та матеріал підшипників ковзання.",
                  lecturer: "Шайнога М.В.",
                },
              ],
            },
          ],
        },
      },
      {
        name: "Слюсар КВПіА",
        lectureSchedule: {
          title: "Графік проведення лекцій для слюсаря КВПіА",
          byDate: [
            {
              date: "14.08.2023",
              byTime: [
                {
                  time: "10:00 — 14:00",
                  description:
                    "Робота сировинної лабораторії. Охорона праці для лаборанта бурякопункту та лаборанта пробовідбірника.",
                  lecturer: "Фалендиш І.А.",
                },

                {
                  time: "14:00 — 17:00",
                  description:
                    "Забезпечення спецодягом та ЗІЗ. Види інструктажів з ОП. Картки ризиків. Правила надання домедичної допомоги. Правила пожежної безпеки. Техногенна безпека.",
                  lecturer: "Домитраш П.В.",
                },
              ],
            },
          ],
        },
      },
      {
        name: "Електрогазозварювальник",
        lectureSchedule: {
          title: "Графік проведення лекцій для Електрогазозварювальник",
          byDate: [
            {
              date: "14.08.2023",
              byTime: [
                {
                  time: "10:00 — 14:00",
                  description:
                    "Робота сировинної лабораторії. Охорона праці для лаборанта бурякопункту та лаборанта пробовідбірника.",
                  lecturer: "Фалендиш І.А.",
                },

                {
                  time: "14:00 — 17:00",
                  description:
                    "Забезпечення спецодягом та ЗІЗ. Види інструктажів з ОП. Картки ризиків. Правила надання домедичної допомоги. Правила пожежної безпеки. Техногенна безпека.",
                  lecturer: "Домитраш П.В.",
                },
              ],
            },
          ],
        },
      },
      {
        name: "Токар",
        lectureSchedule: {
          title: "Графік проведення лекцій для токаря",
          byDate: [
            {
              date: "14.08.2023",
              byTime: [
                {
                  time: "10:00 — 14:00",
                  description:
                    "Робота сировинної лабораторії. Охорона праці для лаборанта бурякопункту та лаборанта пробовідбірника.",
                  lecturer: "Фалендиш І.А.",
                },

                {
                  time: "14:00 — 17:00",
                  description:
                    "Забезпечення спецодягом та ЗІЗ. Види інструктажів з ОП. Картки ризиків. Правила надання домедичної допомоги. Правила пожежної безпеки. Техногенна безпека.",
                  lecturer: "Домитраш П.В.",
                },
              ],
            },
          ],
        },
      },
      {
        name: "Оператор транспортного засобу",
        lectureSchedule: {
          title: "Графік проведення лекцій для оператор транспортного засобу",
          byDate: [
            {
              date: "14.08.2023",
              byTime: [
                {
                  time: "10:00 — 14:00",
                  description:
                    "Робота сировинної лабораторії. Охорона праці для лаборанта бурякопункту та лаборанта пробовідбірника.",
                  lecturer: "Фалендиш І.А.",
                },

                {
                  time: "14:00 — 17:00",
                  description:
                    "Забезпечення спецодягом та ЗІЗ. Види інструктажів з ОП. Картки ризиків. Правила надання домедичної допомоги. Правила пожежної безпеки. Техногенна безпека.",
                  lecturer: "Домитраш П.В.",
                },
              ],
            },
          ],
        },
      },
      {
        name: "Машиніст тепловоза",
        lectureSchedule: {
          title: "Графік проведення лекцій для машиніст тепловоза",
          byDate: [
            {
              date: "14.08.2023",
              byTime: [
                {
                  time: "10:00 — 14:00",
                  description:
                    "Робота сировинної лабораторії. Охорона праці для лаборанта бурякопункту та лаборанта пробовідбірника.",
                  lecturer: "Фалендиш І.А.",
                },

                {
                  time: "14:00 — 17:00",
                  description:
                    "Забезпечення спецодягом та ЗІЗ. Види інструктажів з ОП. Картки ризиків. Правила надання домедичної допомоги. Правила пожежної безпеки. Техногенна безпека.",
                  lecturer: "Домитраш П.В.",
                },
              ],
            },
          ],
        },
      },
      {
        name: "Охорона праці",
        lectureSchedule: {
          title: "Графік проведення лекцій з охорони праці",
          byDate: [
            {
              date: "14.08.2023",
              byTime: [
                {
                  time: "10:00 — 14:00",
                  description:
                    "Робота сировинної лабораторії. Охорона праці для лаборанта бурякопункту та лаборанта пробовідбірника.",
                  lecturer: "Фалендиш І.А.",
                },

                {
                  time: "14:00 — 17:00",
                  description:
                    "Забезпечення спецодягом та ЗІЗ. Види інструктажів з ОП. Картки ризиків. Правила надання домедичної допомоги. Правила пожежної безпеки. Техногенна безпека.",
                  lecturer: "Домитраш П.В.",
                },
              ],
            },
          ],
        },
      },
    ],
  },

  {
    title: "Чортківський підрозділ",
    titleDirections: "Напрямки",
    directions: [
      {
        name: "Слюсар-ремонтник",
        lectureSchedule: {
          title: "Графік проведення лекцій",
          byDate: [
            {
              date: "14.08.2023",
              byTime: [
                {
                  time: "10:00 — 14:00",
                  description:
                    "Робота сировинної лабораторії. Охорона праці для лаборанта бурякопункту та лаборанта пробовідбірника.",
                  lecturer: "Фалендиш І.А.",
                },

                {
                  time: "14:00 — 17:00",
                  description:
                    "Забезпечення спецодягом та ЗІЗ. Види інструктажів з ОП. Картки ризиків. Правила надання домедичної допомоги. Правила пожежної безпеки. Техногенна безпека.",
                  lecturer: "Домитраш П.В.",
                },
              ],
            },
          ],
        },
      },
      {
        name: "Слюсар електромонтер",
        lectureSchedule: {
          title: "Графік проведення лекцій",
          byDate: [
            {
              date: "14.08.2023",
              byTime: [
                {
                  time: "10:00 — 14:00",
                  description:
                    "Робота сировинної лабораторії. Охорона праці для лаборанта бурякопункту та лаборанта пробовідбірника.",
                  lecturer: "Фалендиш І.А.",
                },

                {
                  time: "14:00 — 17:00",
                  description:
                    "Забезпечення спецодягом та ЗІЗ. Види інструктажів з ОП. Картки ризиків. Правила надання домедичної допомоги. Правила пожежної безпеки. Техногенна безпека.",
                  lecturer: "Домитраш П.В.",
                },
              ],
            },
          ],
        },
      },
      {
        name: "Слюсар КВПіА",
        lectureSchedule: {
          title: "Графік проведення лекцій",
          byDate: [
            {
              date: "14.08.2023",
              byTime: [
                {
                  time: "10:00 — 14:00",
                  description:
                    "Робота сировинної лабораторії. Охорона праці для лаборанта бурякопункту та лаборанта пробовідбірника.",
                  lecturer: "Фалендиш І.А.",
                },

                {
                  time: "14:00 — 17:00",
                  description:
                    "Забезпечення спецодягом та ЗІЗ. Види інструктажів з ОП. Картки ризиків. Правила надання домедичної допомоги. Правила пожежної безпеки. Техногенна безпека.",
                  lecturer: "Домитраш П.В.",
                },
              ],
            },
          ],
        },
      },
      {
        name: "Електрогазозварювальник",
        lectureSchedule: {
          title: "Графік проведення лекцій",
          byDate: [
            {
              date: "14.08.2023",
              byTime: [
                {
                  time: "10:00 — 14:00",
                  description:
                    "Робота сировинної лабораторії. Охорона праці для лаборанта бурякопункту та лаборанта пробовідбірника.",
                  lecturer: "Фалендиш І.А.",
                },

                {
                  time: "14:00 — 17:00",
                  description:
                    "Забезпечення спецодягом та ЗІЗ. Види інструктажів з ОП. Картки ризиків. Правила надання домедичної допомоги. Правила пожежної безпеки. Техногенна безпека.",
                  lecturer: "Домитраш П.В.",
                },
              ],
            },
          ],
        },
      },
      {
        name: "Токар",
        lectureSchedule: {
          title: "Графік проведення лекцій",
          byDate: [
            {
              date: "14.08.2023",
              byTime: [
                {
                  time: "10:00 — 14:00",
                  description:
                    "Робота сировинної лабораторії. Охорона праці для лаборанта бурякопункту та лаборанта пробовідбірника.",
                  lecturer: "Фалендиш І.А.",
                },

                {
                  time: "14:00 — 17:00",
                  description:
                    "Забезпечення спецодягом та ЗІЗ. Види інструктажів з ОП. Картки ризиків. Правила надання домедичної допомоги. Правила пожежної безпеки. Техногенна безпека.",
                  lecturer: "Домитраш П.В.",
                },
              ],
            },
          ],
        },
      },
      {
        name: "Оператор транспортного засобу ",
        lectureSchedule: {
          title: "Графік проведення лекцій",
          byDate: [
            {
              date: "14.08.2023",
              byTime: [
                {
                  time: "10:00 — 14:00",
                  description:
                    "Робота сировинної лабораторії. Охорона праці для лаборанта бурякопункту та лаборанта пробовідбірника.",
                  lecturer: "Фалендиш І.А.",
                },

                {
                  time: "14:00 — 17:00",
                  description:
                    "Забезпечення спецодягом та ЗІЗ. Види інструктажів з ОП. Картки ризиків. Правила надання домедичної допомоги. Правила пожежної безпеки. Техногенна безпека.",
                  lecturer: "Домитраш П.В.",
                },
              ],
            },
          ],
        },
      },
      {
        name: "Машиніст тепловоза",
        lectureSchedule: {
          title: "Графік проведення лекцій",
          byDate: [
            {
              date: "14.08.2023",
              byTime: [
                {
                  time: "10:00 — 14:00",
                  description:
                    "Робота сировинної лабораторії. Охорона праці для лаборанта бурякопункту та лаборанта пробовідбірника.",
                  lecturer: "Фалендиш І.А.",
                },

                {
                  time: "14:00 — 17:00",
                  description:
                    "Забезпечення спецодягом та ЗІЗ. Види інструктажів з ОП. Картки ризиків. Правила надання домедичної допомоги. Правила пожежної безпеки. Техногенна безпека.",
                  lecturer: "Домитраш П.В.",
                },
              ],
            },
          ],
        },
      },
      {
        name: "Охорона праці",
        lectureSchedule: {
          title: "Графік проведення лекцій",
          byDate: [
            {
              date: "14.08.2023",
              byTime: [
                {
                  time: "10:00 — 14:00",
                  description:
                    "Робота сировинної лабораторії. Охорона праці для лаборанта бурякопункту та лаборанта пробовідбірника.",
                  lecturer: "Фалендиш І.А.",
                },

                {
                  time: "14:00 — 17:00",
                  description:
                    "Забезпечення спецодягом та ЗІЗ. Види інструктажів з ОП. Картки ризиків. Правила надання домедичної допомоги. Правила пожежної безпеки. Техногенна безпека.",
                  lecturer: "Домитраш П.В.",
                },
              ],
            },
          ],
        },
      },
    ],
  },

  {
    title: "Хоростківський підрозділ",
    titleDirections: "Напрямки",
    directions: [
      {
        name: "Слюсар-ремонтник",
        lectureSchedule: {
          title: "Графік проведення лекцій",
          byDate: [
            {
              date: "14.08.2023",
              byTime: [
                {
                  time: "10:00 — 14:00",
                  description:
                    "Робота сировинної лабораторії. Охорона праці для лаборанта бурякопункту та лаборанта пробовідбірника.",
                  lecturer: "Фалендиш І.А.",
                },

                {
                  time: "14:00 — 17:00",
                  description:
                    "Забезпечення спецодягом та ЗІЗ. Види інструктажів з ОП. Картки ризиків. Правила надання домедичної допомоги. Правила пожежної безпеки. Техногенна безпека.",
                  lecturer: "Домитраш П.В.",
                },
              ],
            },
          ],
        },
      },
      {
        name: "Слюсар електромонтер",
        lectureSchedule: {
          title: "Графік проведення лекцій",
          byDate: [
            {
              date: "14.08.2023",
              byTime: [
                {
                  time: "10:00 — 14:00",
                  description:
                    "Робота сировинної лабораторії. Охорона праці для лаборанта бурякопункту та лаборанта пробовідбірника.",
                  lecturer: "Фалендиш І.А.",
                },

                {
                  time: "14:00 — 17:00",
                  description:
                    "Забезпечення спецодягом та ЗІЗ. Види інструктажів з ОП. Картки ризиків. Правила надання домедичної допомоги. Правила пожежної безпеки. Техногенна безпека.",
                  lecturer: "Домитраш П.В.",
                },
              ],
            },
          ],
        },
      },
      {
        name: "Слюсар КВПіА",
        lectureSchedule: {
          title: "Графік проведення лекцій",
          byDate: [
            {
              date: "14.08.2023",
              byTime: [
                {
                  time: "10:00 — 14:00",
                  description:
                    "Робота сировинної лабораторії. Охорона праці для лаборанта бурякопункту та лаборанта пробовідбірника.",
                  lecturer: "Фалендиш І.А.",
                },

                {
                  time: "14:00 — 17:00",
                  description:
                    "Забезпечення спецодягом та ЗІЗ. Види інструктажів з ОП. Картки ризиків. Правила надання домедичної допомоги. Правила пожежної безпеки. Техногенна безпека.",
                  lecturer: "Домитраш П.В.",
                },
              ],
            },
          ],
        },
      },
      {
        name: "Електрогазозварювальник",
        lectureSchedule: {
          title: "Графік проведення лекцій",
          byDate: [
            {
              date: "14.08.2023",
              byTime: [
                {
                  time: "10:00 — 14:00",
                  description:
                    "Робота сировинної лабораторії. Охорона праці для лаборанта бурякопункту та лаборанта пробовідбірника.",
                  lecturer: "Фалендиш І.А.",
                },

                {
                  time: "14:00 — 17:00",
                  description:
                    "Забезпечення спецодягом та ЗІЗ. Види інструктажів з ОП. Картки ризиків. Правила надання домедичної допомоги. Правила пожежної безпеки. Техногенна безпека.",
                  lecturer: "Домитраш П.В.",
                },
              ],
            },
          ],
        },
      },
      {
        name: "Токар",
        lectureSchedule: {
          title: "Графік проведення лекцій",
          byDate: [
            {
              date: "14.08.2023",
              byTime: [
                {
                  time: "10:00 — 14:00",
                  description:
                    "Робота сировинної лабораторії. Охорона праці для лаборанта бурякопункту та лаборанта пробовідбірника.",
                  lecturer: "Фалендиш І.А.",
                },

                {
                  time: "14:00 — 17:00",
                  description:
                    "Забезпечення спецодягом та ЗІЗ. Види інструктажів з ОП. Картки ризиків. Правила надання домедичної допомоги. Правила пожежної безпеки. Техногенна безпека.",
                  lecturer: "Домитраш П.В.",
                },
              ],
            },
          ],
        },
      },
      {
        name: "Оператор транспортного засобу ",
        lectureSchedule: {
          title: "Графік проведення лекцій",
          byDate: [
            {
              date: "14.08.2023",
              byTime: [
                {
                  time: "10:00 — 14:00",
                  description:
                    "Робота сировинної лабораторії. Охорона праці для лаборанта бурякопункту та лаборанта пробовідбірника.",
                  lecturer: "Фалендиш І.А.",
                },

                {
                  time: "14:00 — 17:00",
                  description:
                    "Забезпечення спецодягом та ЗІЗ. Види інструктажів з ОП. Картки ризиків. Правила надання домедичної допомоги. Правила пожежної безпеки. Техногенна безпека.",
                  lecturer: "Домитраш П.В.",
                },
              ],
            },
          ],
        },
      },
      {
        name: "Машиніст тепловоза",
        lectureSchedule: {
          title: "Графік проведення лекцій",
          byDate: [
            {
              date: "14.08.2023",
              byTime: [
                {
                  time: "10:00 — 14:00",
                  description:
                    "Робота сировинної лабораторії. Охорона праці для лаборанта бурякопункту та лаборанта пробовідбірника.",
                  lecturer: "Фалендиш І.А.",
                },

                {
                  time: "14:00 — 17:00",
                  description:
                    "Забезпечення спецодягом та ЗІЗ. Види інструктажів з ОП. Картки ризиків. Правила надання домедичної допомоги. Правила пожежної безпеки. Техногенна безпека.",
                  lecturer: "Домитраш П.В.",
                },
              ],
            },
          ],
        },
      },
      {
        name: "Охорона праці",
        lectureSchedule: {
          title: "Графік проведення лекцій",
          byDate: [
            {
              date: "14.08.2023",
              byTime: [
                {
                  time: "10:00 — 14:00",
                  description:
                    "Робота сировинної лабораторії. Охорона праці для лаборанта бурякопункту та лаборанта пробовідбірника.",
                  lecturer: "Фалендиш І.А.",
                },

                {
                  time: "14:00 — 17:00",
                  description:
                    "Забезпечення спецодягом та ЗІЗ. Види інструктажів з ОП. Картки ризиків. Правила надання домедичної допомоги. Правила пожежної безпеки. Техногенна безпека.",
                  lecturer: "Домитраш П.В.",
                },
              ],
            },
          ],
        },
      },
    ],
  },

  {
    title: "Козівський підрозділ",
    titleDirections: "Напрямки",
    directions: [
      {
        name: "Слюсар-ремонтник",
        lectureSchedule: {
          title: "Графік проведення лекцій",
          byDate: [
            {
              date: "14.08.2023",
              byTime: [
                {
                  time: "10:00 — 14:00",
                  description:
                    "Робота сировинної лабораторії. Охорона праці для лаборанта бурякопункту та лаборанта пробовідбірника.",
                  lecturer: "Фалендиш І.А.",
                },

                {
                  time: "14:00 — 17:00",
                  description:
                    "Забезпечення спецодягом та ЗІЗ. Види інструктажів з ОП. Картки ризиків. Правила надання домедичної допомоги. Правила пожежної безпеки. Техногенна безпека.",
                  lecturer: "Домитраш П.В.",
                },
              ],
            },
          ],
        },
      },
      {
        name: "Слюсар електромонтер",
        lectureSchedule: {
          title: "Графік проведення лекцій",
          byDate: [
            {
              date: "14.08.2023",
              byTime: [
                {
                  time: "10:00 — 14:00",
                  description:
                    "Робота сировинної лабораторії. Охорона праці для лаборанта бурякопункту та лаборанта пробовідбірника.",
                  lecturer: "Фалендиш І.А.",
                },

                {
                  time: "14:00 — 17:00",
                  description:
                    "Забезпечення спецодягом та ЗІЗ. Види інструктажів з ОП. Картки ризиків. Правила надання домедичної допомоги. Правила пожежної безпеки. Техногенна безпека.",
                  lecturer: "Домитраш П.В.",
                },
              ],
            },
          ],
        },
      },
      {
        name: "Слюсар КВПіА",
        lectureSchedule: {
          title: "Графік проведення лекцій",
          byDate: [
            {
              date: "14.08.2023",
              byTime: [
                {
                  time: "10:00 — 14:00",
                  description:
                    "Робота сировинної лабораторії. Охорона праці для лаборанта бурякопункту та лаборанта пробовідбірника.",
                  lecturer: "Фалендиш І.А.",
                },

                {
                  time: "14:00 — 17:00",
                  description:
                    "Забезпечення спецодягом та ЗІЗ. Види інструктажів з ОП. Картки ризиків. Правила надання домедичної допомоги. Правила пожежної безпеки. Техногенна безпека.",
                  lecturer: "Домитраш П.В.",
                },
              ],
            },
          ],
        },
      },
      {
        name: "Електрогазозварювальник",
        lectureSchedule: {
          title: "Графік проведення лекцій",
          byDate: [
            {
              date: "14.08.2023",
              byTime: [
                {
                  time: "10:00 — 14:00",
                  description:
                    "Робота сировинної лабораторії. Охорона праці для лаборанта бурякопункту та лаборанта пробовідбірника.",
                  lecturer: "Фалендиш І.А.",
                },

                {
                  time: "14:00 — 17:00",
                  description:
                    "Забезпечення спецодягом та ЗІЗ. Види інструктажів з ОП. Картки ризиків. Правила надання домедичної допомоги. Правила пожежної безпеки. Техногенна безпека.",
                  lecturer: "Домитраш П.В.",
                },
              ],
            },
          ],
        },
      },
      {
        name: "Токар",
        lectureSchedule: {
          title: "Графік проведення лекцій",
          byDate: [
            {
              date: "14.08.2023",
              byTime: [
                {
                  time: "10:00 — 14:00",
                  description:
                    "Робота сировинної лабораторії. Охорона праці для лаборанта бурякопункту та лаборанта пробовідбірника.",
                  lecturer: "Фалендиш І.А.",
                },

                {
                  time: "14:00 — 17:00",
                  description:
                    "Забезпечення спецодягом та ЗІЗ. Види інструктажів з ОП. Картки ризиків. Правила надання домедичної допомоги. Правила пожежної безпеки. Техногенна безпека.",
                  lecturer: "Домитраш П.В.",
                },
              ],
            },
          ],
        },
      },
      {
        name: "Оператор транспортного засобу ",
        lectureSchedule: {
          title: "Графік проведення лекцій",
          byDate: [
            {
              date: "14.08.2023",
              byTime: [
                {
                  time: "10:00 — 14:00",
                  description:
                    "Робота сировинної лабораторії. Охорона праці для лаборанта бурякопункту та лаборанта пробовідбірника.",
                  lecturer: "Фалендиш І.А.",
                },

                {
                  time: "14:00 — 17:00",
                  description:
                    "Забезпечення спецодягом та ЗІЗ. Види інструктажів з ОП. Картки ризиків. Правила надання домедичної допомоги. Правила пожежної безпеки. Техногенна безпека.",
                  lecturer: "Домитраш П.В.",
                },
              ],
            },
          ],
        },
      },
      {
        name: "Машиніст тепловоза",
        lectureSchedule: {
          title: "Графік проведення лекцій",
          byDate: [
            {
              date: "14.08.2023",
              byTime: [
                {
                  time: "10:00 — 14:00",
                  description:
                    "Робота сировинної лабораторії. Охорона праці для лаборанта бурякопункту та лаборанта пробовідбірника.",
                  lecturer: "Фалендиш І.А.",
                },

                {
                  time: "14:00 — 17:00",
                  description:
                    "Забезпечення спецодягом та ЗІЗ. Види інструктажів з ОП. Картки ризиків. Правила надання домедичної допомоги. Правила пожежної безпеки. Техногенна безпека.",
                  lecturer: "Домитраш П.В.",
                },
              ],
            },
          ],
        },
      },
      {
        name: "Охорона праці",
        lectureSchedule: {
          title: "Графік проведення лекцій",
          byDate: [
            {
              date: "14.08.2023",
              byTime: [
                {
                  time: "10:00 — 14:00",
                  description:
                    "Робота сировинної лабораторії. Охорона праці для лаборанта бурякопункту та лаборанта пробовідбірника.",
                  lecturer: "Фалендиш І.А.",
                },

                {
                  time: "14:00 — 17:00",
                  description:
                    "Забезпечення спецодягом та ЗІЗ. Види інструктажів з ОП. Картки ризиків. Правила надання домедичної допомоги. Правила пожежної безпеки. Техногенна безпека.",
                  lecturer: "Домитраш П.В.",
                },
              ],
            },
          ],
        },
      },
    ],
  },

  {
    title: "Збаразький підрозділ",
    titleDirections: "Напрямки",
    directions: [
      {
        name: "Слюсар-ремонтник",
        lectureSchedule: {
          title: "Графік проведення лекцій",
          byDate: [
            {
              date: "14.08.2023",
              byTime: [
                {
                  time: "10:00 — 14:00",
                  description:
                    "Робота сировинної лабораторії. Охорона праці для лаборанта бурякопункту та лаборанта пробовідбірника.",
                  lecturer: "Фалендиш І.А.",
                },

                {
                  time: "14:00 — 17:00",
                  description:
                    "Забезпечення спецодягом та ЗІЗ. Види інструктажів з ОП. Картки ризиків. Правила надання домедичної допомоги. Правила пожежної безпеки. Техногенна безпека.",
                  lecturer: "Домитраш П.В.",
                },
              ],
            },
          ],
        },
      },
      {
        name: "Слюсар електромонтер",
        lectureSchedule: {
          title: "Графік проведення лекцій",
          byDate: [
            {
              date: "14.08.2023",
              byTime: [
                {
                  time: "10:00 — 14:00",
                  description:
                    "Робота сировинної лабораторії. Охорона праці для лаборанта бурякопункту та лаборанта пробовідбірника.",
                  lecturer: "Фалендиш І.А.",
                },

                {
                  time: "14:00 — 17:00",
                  description:
                    "Забезпечення спецодягом та ЗІЗ. Види інструктажів з ОП. Картки ризиків. Правила надання домедичної допомоги. Правила пожежної безпеки. Техногенна безпека.",
                  lecturer: "Домитраш П.В.",
                },
              ],
            },
          ],
        },
      },
      {
        name: "Слюсар КВПіА",
        lectureSchedule: {
          title: "Графік проведення лекцій",
          byDate: [
            {
              date: "14.08.2023",
              byTime: [
                {
                  time: "10:00 — 14:00",
                  description:
                    "Робота сировинної лабораторії. Охорона праці для лаборанта бурякопункту та лаборанта пробовідбірника.",
                  lecturer: "Фалендиш І.А.",
                },

                {
                  time: "14:00 — 17:00",
                  description:
                    "Забезпечення спецодягом та ЗІЗ. Види інструктажів з ОП. Картки ризиків. Правила надання домедичної допомоги. Правила пожежної безпеки. Техногенна безпека.",
                  lecturer: "Домитраш П.В.",
                },
              ],
            },
          ],
        },
      },
      {
        name: "Електрогазозварювальник",
        lectureSchedule: {
          title: "Графік проведення лекцій",
          byDate: [
            {
              date: "14.08.2023",
              byTime: [
                {
                  time: "10:00 — 14:00",
                  description:
                    "Робота сировинної лабораторії. Охорона праці для лаборанта бурякопункту та лаборанта пробовідбірника.",
                  lecturer: "Фалендиш І.А.",
                },

                {
                  time: "14:00 — 17:00",
                  description:
                    "Забезпечення спецодягом та ЗІЗ. Види інструктажів з ОП. Картки ризиків. Правила надання домедичної допомоги. Правила пожежної безпеки. Техногенна безпека.",
                  lecturer: "Домитраш П.В.",
                },
              ],
            },
          ],
        },
      },
      {
        name: "Токар",
        lectureSchedule: {
          title: "Графік проведення лекцій",
          byDate: [
            {
              date: "14.08.2023",
              byTime: [
                {
                  time: "10:00 — 14:00",
                  description:
                    "Робота сировинної лабораторії. Охорона праці для лаборанта бурякопункту та лаборанта пробовідбірника.",
                  lecturer: "Фалендиш І.А.",
                },

                {
                  time: "14:00 — 17:00",
                  description:
                    "Забезпечення спецодягом та ЗІЗ. Види інструктажів з ОП. Картки ризиків. Правила надання домедичної допомоги. Правила пожежної безпеки. Техногенна безпека.",
                  lecturer: "Домитраш П.В.",
                },
              ],
            },
          ],
        },
      },
      {
        name: "Оператор транспортного засобу ",
        lectureSchedule: {
          title: "Графік проведення лекцій",
          byDate: [
            {
              date: "14.08.2023",
              byTime: [
                {
                  time: "10:00 — 14:00",
                  description:
                    "Робота сировинної лабораторії. Охорона праці для лаборанта бурякопункту та лаборанта пробовідбірника.",
                  lecturer: "Фалендиш І.А.",
                },

                {
                  time: "14:00 — 17:00",
                  description:
                    "Забезпечення спецодягом та ЗІЗ. Види інструктажів з ОП. Картки ризиків. Правила надання домедичної допомоги. Правила пожежної безпеки. Техногенна безпека.",
                  lecturer: "Домитраш П.В.",
                },
              ],
            },
          ],
        },
      },
      {
        name: "Машиніст тепловоза",
        lectureSchedule: {
          title: "Графік проведення лекцій",
          byDate: [
            {
              date: "14.08.2023",
              byTime: [
                {
                  time: "10:00 — 14:00",
                  description:
                    "Робота сировинної лабораторії. Охорона праці для лаборанта бурякопункту та лаборанта пробовідбірника.",
                  lecturer: "Фалендиш І.А.",
                },

                {
                  time: "14:00 — 17:00",
                  description:
                    "Забезпечення спецодягом та ЗІЗ. Види інструктажів з ОП. Картки ризиків. Правила надання домедичної допомоги. Правила пожежної безпеки. Техногенна безпека.",
                  lecturer: "Домитраш П.В.",
                },
              ],
            },
          ],
        },
      },
      {
        name: "Охорона праці",
        lectureSchedule: {
          title: "Графік проведення лекцій",
          byDate: [
            {
              date: "14.08.2023",
              byTime: [
                {
                  time: "10:00 — 14:00",
                  description:
                    "Робота сировинної лабораторії. Охорона праці для лаборанта бурякопункту та лаборанта пробовідбірника.",
                  lecturer: "Фалендиш І.А.",
                },

                {
                  time: "14:00 — 17:00",
                  description:
                    "Забезпечення спецодягом та ЗІЗ. Види інструктажів з ОП. Картки ризиків. Правила надання домедичної допомоги. Правила пожежної безпеки. Техногенна безпека.",
                  lecturer: "Домитраш П.В.",
                },
              ],
            },
          ],
        },
      },
    ],
  },
];

const lectureScheduleWithIds = lectureSchedule.map((el) => ({
  ...el,
  directions: el.directions.map((item) => ({
    ...item,
    id: nanoid(5), // id списку
  })),
  id: nanoid(5), // id підрозділу
}));

const allLectureSchedule = {
  lectureScheduleWithIds,
};

export default allLectureSchedule;
