import css from "./Shipment.module.scss";
import Container from "../../components/Container/Container";
import allShipment from "./dataShipment/dataShipment";
import TitleEl from "../../components/TitleEl/TitleEl";
import { useTranslation } from 'react-multi-lang'
import { useRef } from "react";
import { Link } from "react-router-dom";


const { dataShipment } = allShipment;

const Shipment = () => {
  const t = useTranslation();
  const docsMob = useRef(null);

  function showDocs(e){
    const btn = e.target.closest('button');
    btn.nextElementSibling.classList.toggle('active');
  }
  
  return (
    <section className={css["ShipmentWrapper"]}>
      <Container>
        <ul>
          {dataShipment.map((item, index) => (
            <li key={index}>
              <TitleEl
                className={css["title"]}
                data1={index + 1}
                data2={dataShipment.length}
                data3={t(item.title)}
                boolean="1"
              />

              <div className={css["descriptionWrapper"]}>
                {item.description.map((el, index) => (
                  <p className={css["description"]} key={index}>
                    <span>0{index + 1}</span>
                    {t(el)}
                  </p>
                ))}
                <button onClick={showDocs}>
                      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M13.7146 4.95931C14.184 5.46826 13.7178 6.16683 13.0255 6.16683H2.33398C1.7817 6.16683 1.33398 5.71911 1.33398 5.16683V4.28016C1.33398 2.6535 2.65398 1.3335 4.28065 1.3335H5.82732C6.91398 1.3335 7.25398 1.68683 7.68732 2.26683L8.62065 3.50683C8.82732 3.78016 8.85398 3.8135 9.24065 3.8135H11.1007C12.1337 3.8135 13.0647 4.25446 13.7146 4.95931Z" fill="#292D32"/>
                      <path d="M14.6574 8.16313C14.6555 7.61217 14.2083 7.16651 13.6574 7.16652L2.33397 7.16672C1.78169 7.16673 1.33398 7.61444 1.33398 8.16672V11.1001C1.33398 13.0667 2.93398 14.6667 4.90065 14.6667H11.1007C13.0673 14.6667 14.6673 13.0667 14.6673 11.1001L14.6574 8.16313ZM9.66732 11.1667H8.54065V12.3334C8.54065 12.6067 8.31398 12.8334 8.04065 12.8334C7.76065 12.8334 7.54065 12.6067 7.54065 12.3334V11.1667H6.33398C6.06065 11.1667 5.83398 10.9401 5.83398 10.6667C5.83398 10.3934 6.06065 10.1667 6.33398 10.1667H7.54065V9.00007C7.54065 8.72674 7.76065 8.50007 8.04065 8.50007C8.31398 8.50007 8.54065 8.72674 8.54065 9.00007V10.1667H9.66732C9.94065 10.1667 10.1673 10.3934 10.1673 10.6667C10.1673 10.9401 9.94065 11.1667 9.66732 11.1667Z" fill="#292D32"/>
                      </svg>{t('beetTech.instructions')}</button>
                <div className="accord-docs" ref={docsMob}>
                      {item.docs !== undefined && item.docs.map((doc,index)=>{
                        return <div key={index} className='acord__content--link' style={{ color: 'blue', textDecoration: 'underline' }}>
                                    <Link to={doc.url} target="_blank">
                                    {t(doc.title)}
                                    </Link>
                            </div>
                      })}
              </div>
              </div>
            </li>
          ))}
        </ul>
      </Container>
    </section>
  );
};

export default Shipment;
