import React from "react";
import css from "./LogoInfo.module.scss";
import { ReactComponent as Logo } from "../../../../media/images/icons/logo.svg";

import { useTranslation } from "react-multi-lang";

const currentDate = new Date();
const currentYear = currentDate.getFullYear();

// console.log(currentYear);

const LogoInfo = () => {
  const t = useTranslation();
  return (
    <div className={css["logoInfoWrapper"]}>
      <div className={css["logo_content"]}>
        <Logo className={css["logo"]} />
        {/* <p>© 1974 — 2023 ТзОВ «Радехівський цукор»</p> */}
        <p>
          © 1974 — {currentYear}&nbsp;
          {t("logoText")}
        </p>
      </div>
    </div>
  );
};

export default LogoInfo;
